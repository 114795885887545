import React from "react";
import Image from "next/image";
import { PlayPauseButton } from "components/videoEmbed";

const IndividualFarmerStory = ({ embedId, onClick, active }) => {
  return (
    <div
      className={`${
        active ? "border-thrive-lime-green/70" : "border-transparent"
      } relative cursor-pointer h-[140px] mx-4 overflow-hidden rounded-[4px] hover:border-thrive-lime-green border-2`}
      onClick={() => onClick(embedId)}
    >
      <Image
        src={`https://img.youtube.com/vi/${embedId}/hqdefault.jpg`}
        width={264}
        height={162}
        alt=""
        layout="responsive"
      />
      <PlayPauseButton
        className="absolute right-3 bottom-3 p-2"
        width={16}
        height={16}
      />
    </div>
  );
};

export default IndividualFarmerStory;
