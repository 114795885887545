// import { PLAY_BUTTON } from "assets/svg";
import useOnScreen from "hooks/useOnScreen";
import Image from "next/image";
import React, { useState, useRef, useEffect } from "react";
import { oneSpaceApart } from "utils/utils";

export const VideoPlaceholder = (props) => {
  const {
    onPlay = () => null,
    videoId,
    width = 398,
    height = 532,
    responsive = false,
  } = props;
  return (
    <div
      style={{
        width: !responsive ? `${width}px` : "100%",
        height: !responsive ? `${height}px` : "100%",
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) -33.03%, rgba(0, 0, 0, 0.5) 70.69%),
        url(https://img.youtube.com/vi/${videoId}/sddefault.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
      // className="relative border-4 border-purple-700"
      // className="relative pb-[56.25%] pt-[30px] overflow-hidden h-0 rounded-lg"
      className="mx-auto max-w-[1305px] max-h-[532px] w-full before:table rounded-lg after:table after:clear-both"
    >
      {/* <div> */}
      <div className="relative w-full h-full overflow-hidden">
        <Image
          src={`https://img.youtube.com/vi/${videoId}/default.jpg`}
          width={!responsive ? `${width}px` : "100%"}
          height={!responsive ? `${height}px` : "100%"}
          layout="responsive"
          alt=""
          // className="h-full w-full m-[-9.5%_0px_-12%]"
          className="invisible"
          // className="absolute top-0 left-0 w-full h-full"
        />
        {/* </div> */}
        <div>
          <PlayPauseButton onClick={onPlay} />
        </div>
      </div>
    </div>
  );
};

export const PlayPauseButton = (props) => {
  const {
    className = "absolute p-5 left-1/2 top-1/2",
    width = 27,
    height = 29,
    onClick = () => null,
  } = props;
  return (
    <button
      onClick={onClick}
      // className="flex flex-col items-center p-5 transition-colors duration-700 border-2 border-white rounded-full hover:ease-in-out bg-thrive-lime-green/70 hover:bg-thrive-lime-green/100"
      className={oneSpaceApart([
        className,
        "transition-colors duration-700 border-2 border-white rounded-full hover:ease-in-out bg-thrive-lime-green/70 hover:bg-thrive-lime-green/100",
      ])}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 27 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.9189 12.2617C26.6932 13.2906 26.6932 15.8526 24.9189 16.8814L4.99756 28.4333C3.21749 29.4655 0.988022 28.1811 0.988022 26.1234L0.988023 3.01974C0.988024 0.96204 3.21749 -0.322333 4.99756 0.709881L24.9189 12.2617Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

const VideoEmbed = (props) => {
  const { embedId, width = 532, height = 389, responsive = false, url } = props;
  // const [visible, setVisible] = useState(false);
  const [playing, setPlaying] = useState(true);

  const ref = useRef();
  const inView = useOnScreen(ref);

  const urlUpdate = url
    ? `https://www.youtube.com/embed/${url
        .split("/")
        .pop()}?html5=1&enablejsapi=1`
    : `https://www.youtube.com/embed/${embedId}?html5=1&enablejsapi=1`;

  useEffect(() => {
    if (!inView) {
      ref.current.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        "*"
      );
    }
  }, [inView]);

  return (
    // video embed component
    <div
      style={{
        width: !responsive ? `${width}px` : "100%",
        height: !responsive ? `${height}px` : "auto",
        maxWidth: "1305px",
      }}
      className="max-w-[1305px] w-full h-auto rounded-lg"
    >
      {playing ? (
        <div className="">
          {/* video container */}
          <div className="relative pb-[56.25%] pt-[30px] overflow-hidden h-0 rounded-lg">
            {/* video */}
            <iframe
              ref={ref}
              src={urlUpdate}
              frameBorder="0"
              className="absolute top-0 left-0 w-full h-full rounded-lg"
              allow="picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
              id={urlUpdate}
            />
          </div>
        </div>
      ) : (
        <VideoPlaceholder
          onPlay={() => setPlaying(true)}
          responsive={responsive}
          height={height}
          width={width}
          videoId={embedId}
        />
      )}
    </div>
  );
};

export default React.memo(VideoEmbed);
