import Accordion from "components/accordion/accordion";
import AccordionImage from "components/accordion/accordionImage";
import Container from "components/sections/container";
import useAccordionWithImage from "hooks/useAccordionWithImage";
import React from "react";
import tailwindConfig from "tailwind.config";

const AccordionHome = ({ data }) => {
  let { setCurrentTabIndex, currentImage, currentTabIndex, tabs } =
    useAccordionWithImage(data.accordionDataList, {
      initialImage:
        data.defaultImage?.data?.attributes?.url ??
        data.accordionDataList[0]?.image?.data?.attributes?.url ??
        "/images/webp/aos_splash.webp",
      imageKey: "imageSRC",
    });

  return (
    <Container
      style={{
        backgroundImage: `
            url(${
              data?.backgroundImage?.data?.attributes?.url ??
              "/images/png/thrive_pattern_bg.png"
            })`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: tailwindConfig.theme.extend.colors.white,
      }}
    >
      <div className="flex flex-row py-16 select-none">
        <div className="justify-center hidden pt-20 md:w-1/2 lg:w-1/3 md:flex">
          <AccordionImage
            // selectedIndex={selectedIndex}
            currentImageIndex={currentTabIndex}
            // images={tabs}
            src={currentImage}
            width={315}
            height={636}
            // currentLocation={CURRENT_LOCATION}
          />
        </div>
        <div className="w-full pt-16 md:w-2/3 lg:1/2 md:px-16">
          <h3 className="font-bold text-h3">{data?.title}</h3>
          <p className="mt-5 text-lg mb-14">{data?.description}</p>
          <Accordion
            currentTabIndex={currentTabIndex}
            setCurrentTabIndex={setCurrentTabIndex}
            tabs={tabs}
            currentImage={currentImage}
          />
        </div>
      </div>
    </Container>
  );
};

const AccordionRight = ({ data }) => {
  const { currentTabIndex, setCurrentTabIndex, tabs } = useAccordionWithImage(
    data?.accordionDataList
  );

  const accordionImageLocation = {
    start: "self-start",
    center: "self-center",
    end: "self-end",
  };

  return (
    <Container>
      <div className="py-7">
        <h3 className="font-bold text-center text-h3">{data?.title}</h3>
        <div className="py-5">
          <p className="text-lg font-normal">{data?.description}</p>
        </div>
        <div className="flex flex-col justify-between space-x-8 md:flex-row lg:pb-20">
          <div className="mr-3 md:w-1/2">
            <Accordion
              tabs={tabs}
              // currentLocation={CURRENT_LOCATION}
              currentTabIndex={currentTabIndex}
              setCurrentTabIndex={setCurrentTabIndex}
            />
          </div>
          <div
            className={`md:w-1/2 ${
              accordionImageLocation[tabs[currentTabIndex].ImagePosition]
            }`}
          >
            <AccordionImage
              // selectedIndex={selectedIndex}
              currentImageIndex={currentTabIndex}
              images={tabs}
              width={653}
              height={440}
              className="-z-10"
              // currentLocation={CURRENT_LOCATION}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

const AccordionSection = ({ data }) => {
  // console.log(data);
  return data?.imageRight ? (
    <AccordionRight data={data} />
  ) : (
    <AccordionHome data={data} />
  );
};
export default AccordionSection;
