import React from "react";
import VideoEmbed from "components/videoEmbed";
import Image from "next/image";

const TestimonialSlide = ({ data }) => {
  return (
    <div className="flex flex-col-reverse justify-center w-full px-4 lg:items-center xl:flex-row sm:px-16">
      {/* col */}
      <div className="flex flex-row items-center justify-center lg:w-1/2 lg:justify-start">
        <p className="font-medium text-9xl md:text-huge font-poppins text-thrive-lime-green">
          “
        </p>
        <div className="flex ml-4 flex-col max-w-[444px] text-left">
          <p className="mb-4 text-lg leading-7 md:pr-9">{data.message}</p>
          <p className="text-lg font-bold leading-8 text-thrive-dark">
            {data.full_name}
          </p>
          <p className="text-lg font-normal leading-7">{data.designation}</p>
        </div>
      </div>
      {/* col */}
      <div className="flex flex-col items-center justify-center  lg:w-1/2 lg:justify-center h-[389px]">
        {/* video with placeholder thumbnail */}
        {data.mediaType === "image" && (
          <div className="">
            <Image
              width={534}
              height={389}
              src={data.image.data.attributes.url}
              className="rounded-lg"
              alt=""
            />
            {/* <div className="absolute top-0 bottom-0 left-0 right-0 z-40 w-full h-full bg-gradient-to-b from-transparent to-black/50" /> */}
          </div>
        )}
        {data.mediaType === "video" && (
          <div className="w-[340px] md:w-[534px] h-auto rounded-lg">
            <VideoEmbed
              responsive
              width={534}
              embedId={data.videoId}
              url={data.embededYoutubeLink}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TestimonialSlide;
