import React from "react";

const GenderEquality = (props) => {
  const { fill, width = 35, height = 45 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 45"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00861 10.4372C0.621615 10.1894 0.35718 9.83825 0.214198 9.38391C0.0712152 8.93012 0 8.3626 0 7.68192H1.89134C1.89134 8.27041 1.946 8.69715 2.0553 8.96158C2.16461 9.22657 2.37881 9.35906 2.69845 9.35906C2.95074 9.35906 3.13568 9.27515 3.25326 9.10677C3.37085 8.93895 3.44427 8.70985 3.47409 8.41946C3.50334 8.12964 3.51825 7.71173 3.51825 7.16464C3.51825 6.54247 3.46139 6.0782 3.34766 5.77125C3.23449 5.46486 3.00097 5.31084 2.64766 5.31084C2.19387 5.31084 1.90404 5.61833 1.77817 6.23167H0.100474L0.239592 0.430664H4.94311V2.10781H1.84111L1.72738 4.36517C1.84497 4.22218 2.04702 4.10239 2.33243 4.00578C2.6184 3.90917 2.92534 3.86059 3.25326 3.86059C3.95106 3.86059 4.47662 4.14269 4.82938 4.70579C5.1827 5.26888 5.35936 6.05943 5.35936 7.07631C5.35936 7.88342 5.28759 8.55417 5.14516 9.08801C5.00162 9.62184 4.73056 10.042 4.33143 10.3489C3.93229 10.6558 3.36257 10.8093 2.62282 10.8093C1.9333 10.8093 1.39504 10.6851 1.00861 10.4372Z"
        fill="currentColor"
      />
      <path
        d="M10.714 4.37977C10.3452 4.37977 10.0797 4.26439 9.91735 4.03363C9.75449 3.80287 9.67334 3.46722 9.67334 3.02613V1.75475C9.67334 1.53061 9.69101 1.33684 9.72634 1.17454C9.76167 1.01223 9.82129 0.869248 9.9041 0.747244C9.98746 0.624688 10.1017 0.533046 10.248 0.471768C10.3938 0.41049 10.5726 0.380127 10.7846 0.380127C11.1711 0.380127 11.4476 0.474529 11.6155 0.663332C11.7838 0.852687 11.8678 1.1392 11.8678 1.52399V1.715H11.1871V1.51239C11.1871 1.42572 11.1854 1.35561 11.1816 1.3004C11.1771 1.24575 11.1683 1.18999 11.1528 1.13424C11.1379 1.07903 11.117 1.03597 11.0883 1.00616C11.0601 0.976347 11.0215 0.952057 10.9729 0.933287C10.9243 0.914517 10.8647 0.905132 10.794 0.905132C10.741 0.905132 10.693 0.911757 10.6516 0.925006C10.6102 0.938255 10.5754 0.954817 10.5467 0.974139C10.5185 0.994013 10.4948 1.02162 10.4749 1.05805C10.4556 1.09394 10.4407 1.12927 10.4302 1.16294C10.4203 1.19662 10.4125 1.24023 10.407 1.29323C10.4015 1.34678 10.3976 1.3937 10.396 1.43455C10.3949 1.47541 10.3938 1.52785 10.3938 1.59244V3.16746C10.3938 3.40263 10.423 3.57487 10.481 3.68418C10.539 3.79349 10.6494 3.84759 10.8128 3.84759C10.8978 3.84759 10.969 3.83158 11.0259 3.79956C11.0833 3.76754 11.128 3.71896 11.16 3.65382C11.1926 3.58868 11.2152 3.5136 11.2285 3.42968C11.2417 3.34632 11.2484 3.24419 11.2484 3.12495V2.80476H10.8271V2.346H11.8887V4.32788H11.4272L11.3753 3.93261C11.2577 4.23072 11.0375 4.37977 10.714 4.37977Z"
        fill="currentColor"
      />
      <path
        d="M12.4014 4.33645V0.432861H14.0349V0.934681H13.1124V2.0548H13.8422V2.55662H13.1124V3.84457H14.047V4.33645H12.4014Z"
        fill="currentColor"
      />
      <path
        d="M14.4331 4.32742V0.423828H14.9228L15.8784 2.63481V0.423828H16.4674V4.32742H16.0009L15.0359 2.00381V4.32742H14.4331Z"
        fill="currentColor"
      />
      <path
        d="M17.0264 4.33645V0.432861H17.9351C18.1702 0.432861 18.3673 0.4566 18.5258 0.503525C18.6848 0.551001 18.8117 0.625529 18.9072 0.727107C19.0033 0.829238 19.0712 0.954555 19.1109 1.10306C19.1512 1.25101 19.1711 1.43374 19.1711 1.65015V3.05789C19.1711 3.28092 19.1507 3.47028 19.1098 3.6254C19.069 3.78108 19.0016 3.91247 18.9089 4.02068C18.815 4.12943 18.6908 4.20893 18.5351 4.26027C18.38 4.31106 18.1896 4.33645 17.9632 4.33645H17.0264ZM17.7374 3.83739H17.9395C18.0101 3.83739 18.0725 3.83132 18.1255 3.81862C18.1791 3.80593 18.2238 3.79102 18.2597 3.77391C18.2961 3.75679 18.3265 3.72809 18.3508 3.68889C18.375 3.64969 18.3933 3.61381 18.406 3.58069C18.4187 3.54756 18.428 3.49788 18.4341 3.43108C18.4402 3.36428 18.4446 3.30742 18.4457 3.26049C18.4474 3.21357 18.4485 3.14346 18.4485 3.05127V1.6242C18.4485 1.53201 18.4468 1.45693 18.4435 1.40062C18.4402 1.34431 18.433 1.28744 18.4209 1.23003C18.4093 1.17262 18.3922 1.12845 18.3695 1.09699C18.3469 1.06552 18.3165 1.03681 18.279 1.01086C18.2409 0.984918 18.194 0.9667 18.1377 0.956763C18.0814 0.946274 18.0129 0.941305 17.9328 0.941305H17.7374V3.83739Z"
        fill="currentColor"
      />
      <path
        d="M19.6699 4.33645V0.432861H21.304V0.934681H20.381V2.0548H21.1108V2.55662H20.381V3.84457H21.3156V4.33645H19.6699Z"
        fill="currentColor"
      />
      <path
        d="M21.7007 4.33645V0.432861H22.595C22.8009 0.432861 22.9776 0.449975 23.125 0.483651C23.2724 0.517326 23.401 0.573636 23.5098 0.653132C23.6191 0.732628 23.7008 0.839727 23.7549 0.975533C23.809 1.11134 23.836 1.27751 23.836 1.47349C23.836 1.7186 23.7996 1.91845 23.7267 2.07357C23.6533 2.22925 23.533 2.34242 23.3651 2.41309L23.9067 4.33645H23.1934L22.727 2.55441H22.4117V4.33645H21.7007ZM22.4117 2.09952H22.5834C22.7938 2.09952 22.9434 2.0548 23.0317 1.96537C23.1206 1.87649 23.1647 1.72302 23.1647 1.50661C23.1647 1.29793 23.1266 1.14833 23.0499 1.05834C22.9726 0.967804 22.8313 0.922535 22.6259 0.922535H22.4117V2.09952Z"
        fill="currentColor"
      />
      <path
        d="M9.7373 10.7163V6.81274H11.3714V7.31456H10.4484V8.43469H11.1782V8.93651H10.4484V10.2245H11.383V10.7163H9.7373Z"
        fill="currentColor"
      />
      <path
        d="M13.6616 11.4886C13.544 11.4334 13.4143 11.3345 13.2718 11.1921C13.1299 11.0491 13.0096 10.9023 12.9108 10.7516C12.8931 10.7532 12.8666 10.7538 12.8307 10.7538C12.4288 10.7538 12.1407 10.645 11.9668 10.427C11.7923 10.2083 11.7051 9.88926 11.7051 9.46859V8.02772C11.7051 7.8207 11.725 7.64073 11.7641 7.48836C11.8033 7.33655 11.8663 7.20461 11.9535 7.09309C12.0407 6.98158 12.1572 6.89822 12.303 6.84246C12.4493 6.7867 12.6248 6.75854 12.8307 6.75854C13.231 6.75854 13.518 6.8662 13.6936 7.0815C13.8686 7.29625 13.9558 7.61202 13.9558 8.02772V9.46859C13.9558 10.0747 13.7753 10.4645 13.4143 10.6389C13.5699 10.8239 13.6958 10.9464 13.7935 11.0061L13.6616 11.4886ZM12.653 10.1962C12.7021 10.2166 12.7617 10.2266 12.8307 10.2266C12.8997 10.2266 12.9583 10.2166 13.0068 10.1962C13.0554 10.1758 13.0946 10.1487 13.1239 10.1162C13.1526 10.083 13.1752 10.04 13.1918 9.98642C13.2083 9.93287 13.2194 9.87987 13.2249 9.82632C13.2304 9.77332 13.2332 9.70984 13.2332 9.63586V7.86266C13.2332 7.76881 13.2282 7.69042 13.2188 7.62748C13.2094 7.56455 13.1912 7.50548 13.1636 7.44972C13.136 7.39396 13.0952 7.35256 13.04 7.32495C12.9853 7.29735 12.9152 7.28355 12.8307 7.28355C12.7457 7.28355 12.6756 7.29735 12.6198 7.32495C12.5641 7.35256 12.5232 7.39396 12.4962 7.44972C12.4697 7.50548 12.4515 7.56455 12.4421 7.62748C12.4327 7.69042 12.4283 7.76881 12.4283 7.86266V9.63586C12.4283 9.70984 12.4305 9.77332 12.436 9.82632C12.4415 9.87987 12.4526 9.93287 12.4691 9.98642C12.4857 10.04 12.5083 10.083 12.5365 10.1162C12.5646 10.1487 12.6033 10.1758 12.653 10.1962Z"
        fill="currentColor"
      />
      <path
        d="M15.5206 10.7542C15.3461 10.7542 15.1954 10.7338 15.0674 10.6929C14.9393 10.6521 14.8344 10.5952 14.7521 10.5213C14.6693 10.4473 14.6036 10.3529 14.5539 10.2375C14.5048 10.1221 14.47 9.99735 14.4507 9.8632C14.4308 9.72905 14.4209 9.57337 14.4209 9.39561V6.80371H15.111V9.46903C15.111 9.56012 15.1132 9.63741 15.1181 9.702C15.1226 9.76604 15.1325 9.83394 15.1485 9.90461C15.164 9.97527 15.1866 10.0316 15.2159 10.0741C15.2446 10.116 15.2849 10.1514 15.3356 10.1801C15.387 10.2082 15.4483 10.222 15.5206 10.222C15.5929 10.222 15.6542 10.2082 15.7055 10.1801C15.7563 10.1514 15.7955 10.116 15.8231 10.0741C15.8507 10.0316 15.8722 9.97527 15.8888 9.90461C15.9054 9.83394 15.9153 9.76659 15.9197 9.70311C15.9236 9.63962 15.9252 9.56178 15.9252 9.46903V6.80371H16.6153V9.39561C16.6153 9.57337 16.6054 9.72905 16.5855 9.8632C16.5662 9.99735 16.5314 10.1221 16.4823 10.2375C16.4326 10.3529 16.3674 10.4473 16.2857 10.5213C16.204 10.5952 16.0991 10.6521 15.9716 10.6929C15.8435 10.7338 15.6934 10.7542 15.5206 10.7542Z"
        fill="currentColor"
      />
      <path
        d="M16.9414 10.7163L17.7347 6.81274H18.4767L19.2678 10.7163H18.6058L18.448 9.80986H17.7722L17.6127 10.7163H16.9414ZM17.8479 9.34613H18.3707L18.109 7.74296L17.8479 9.34613Z"
        fill="currentColor"
      />
      <path
        d="M19.6279 10.7163V6.81274H20.339V10.2245H21.2736V10.7163H19.6279Z"
        fill="currentColor"
      />
      <path
        d="M22.3486 6.80444H21.647V10.708H22.3486V6.80444Z"
        fill="currentColor"
      />
      <path
        d="M23.3061 10.7073V7.32872H22.6514V6.80371H24.6504V7.32872H24.0172V10.7073H23.3061Z"
        fill="currentColor"
      />
      <path
        d="M25.4859 10.7073V9.22172L24.6904 6.80371H25.354L25.837 8.35775L26.298 6.80371H26.955L26.1644 9.22172V10.7073H25.4859Z"
        fill="currentColor"
      />
      <path
        d="M34.7165 16.0105H28.0283C27.9825 16.0105 27.9433 16.0486 27.9433 16.0961V18.3186C27.9433 18.3661 27.9825 18.4042 28.0283 18.4042H30.6915L27.7341 21.3616C26.2447 20.1912 24.3704 19.4912 22.3333 19.4912C17.5034 19.4912 13.5728 23.4207 13.5728 28.2512C13.5728 32.6986 16.905 36.3791 21.2027 36.9362V39.4971H18.5644C18.5153 39.4971 18.4783 39.5347 18.4783 39.5816V41.9637C18.4783 42.0112 18.5153 42.0487 18.5644 42.0487H21.2027V44.5694C21.2027 44.6164 21.2403 44.6539 21.2877 44.6539H23.6688C23.7157 44.6539 23.7549 44.6164 23.7549 44.5694V42.0487H26.3926C26.4395 42.0487 26.4776 42.0112 26.4776 41.9637V39.5816C26.4776 39.5347 26.4395 39.4971 26.3926 39.4971H23.7549V36.8942C27.9113 36.2124 31.0934 32.5976 31.0934 28.2512C31.0934 26.3764 30.4999 24.6391 29.4929 23.2126L32.4084 20.2972V22.7848C32.4084 22.8317 32.4476 22.8698 32.4945 22.8698H34.7165C34.764 22.8698 34.8032 22.8317 34.8032 22.7848V16.0961C34.8032 16.0486 34.764 16.0105 34.7165 16.0105ZM22.3333 34.6992C18.7775 34.6992 15.8859 31.8065 15.8859 28.2512C15.8859 24.696 18.7775 21.8032 22.3333 21.8032C25.8891 21.8032 28.7813 24.696 28.7813 28.2512C28.7813 31.8065 25.8891 34.6992 22.3333 34.6992Z"
        fill="currentColor"
      />
      <path
        d="M26.2712 25.6042H18.4503C18.4033 25.6042 18.3652 25.639 18.3652 25.6804V27.574C18.3652 27.6181 18.4033 27.6513 18.4503 27.6513H26.2712C26.3198 27.6513 26.3579 27.6181 26.3579 27.574V25.6804C26.3579 25.639 26.3198 25.6042 26.2712 25.6042Z"
        fill="currentColor"
      />
      <path
        d="M26.2712 28.9849H18.4503C18.4033 28.9849 18.3652 29.0207 18.3652 29.0638V30.9574C18.3652 30.9999 18.4033 31.0335 18.4503 31.0335H26.2712C26.3198 31.0335 26.3579 30.9999 26.3579 30.9574V29.0638C26.3579 29.0207 26.3198 28.9849 26.2712 28.9849Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GenderEquality;
