import classNames from "classnames";
import React from "react";
import { ChevronDown } from "react-feather";
import styles from "../../styles/accordion.module.css";

const FAQtab = ({ title, content, isActive, onClick }) => {
  let faqArrowClass = classNames({
    [`${styles.arrow_active}`]: isActive,
  });

  return (
    <div className="border-b border-b-zinc-500 py-2">
      <button
        className="flex justify-between items-center pb-2 w-full"
        onClick={onClick}
      >
        <h2
          className={classNames(
            "font-semibold text-xl transition-all text-left w-4/5",
            {
              "text-primary": isActive,
            }
          )}
        >
          {title}
        </h2>
        <ChevronDown className={faqArrowClass} size={isActive ? 22 : 18} />
      </button>
      <div
        className={classNames(
          "transition-all duration-1000 max-h-0 overflow-hidden",
          {
            "max-h-screen": isActive,
          }
        )}
      >
        {content}
      </div>
    </div>
  );
};

export default React.memo(FAQtab, (prevProps, nextProps) => {
  prevProps.isActive != nextProps.isActive;
});
