import React from "react";

const DecentWork = (props) => {
  const { fill, width = 45, height = 44 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 44"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 7.76994C0 7.19801 0.101026 6.70226 0.302527 6.2816C0.504579 5.86148 0.802689 5.55068 1.19796 5.34862C0.886602 5.12173 0.634864 4.817 0.441093 4.43442C0.247873 4.05185 0.151263 3.58757 0.151263 3.04103C0.151263 2.20025 0.373742 1.54662 0.819803 1.08013C1.26531 0.613094 1.90404 0.380127 2.73654 0.380127C3.56849 0.380127 4.20335 0.611438 4.64058 1.07351C5.07781 1.53613 5.29643 2.17928 5.29643 3.00294C5.29643 3.57487 5.20368 4.05405 5.01874 4.44049C4.8338 4.82748 4.58593 5.13001 4.27512 5.34862C4.66984 5.55068 4.9685 5.86976 5.17 6.30699C5.37206 6.74422 5.47308 7.2615 5.47308 7.85827C5.47308 8.80836 5.25447 9.54756 4.81724 10.0775C4.38001 10.6075 3.68663 10.8719 2.73654 10.8719C0.911996 10.8719 0 9.83794 0 7.76994ZM3.39846 8.97397C3.51218 8.67586 3.56849 8.29936 3.56849 7.84557C3.56849 7.34099 3.51218 6.94186 3.39846 6.64761C3.28473 6.35337 3.06446 6.20597 2.73654 6.20597C2.40862 6.20597 2.1878 6.35557 2.07463 6.65368C1.9609 6.95235 1.90404 7.34982 1.90404 7.84557C1.90404 8.29936 1.9609 8.67586 2.07463 8.97397C2.1878 9.27263 2.40862 9.42169 2.73654 9.42169C3.06446 9.42169 3.28473 9.27263 3.39846 8.97397ZM3.45532 3.04103C3.45532 2.67116 3.39625 2.37857 3.27866 2.16437C3.16107 1.95017 2.98 1.84307 2.73654 1.84307C2.49253 1.84307 2.30759 1.95017 2.18173 2.16437C2.0553 2.37857 1.99237 2.67116 1.99237 3.04103C1.99237 4.09215 2.24024 4.61715 2.73654 4.61715C3.21573 4.61715 3.45532 4.09215 3.45532 3.04103Z"
        fill="currentColor"
      />
      <path
        d="M9.30713 4.41311V0.509521H10.2158C10.4515 0.509521 10.6486 0.53326 10.8071 0.580185C10.9655 0.627661 11.0925 0.702189 11.1885 0.803767C11.284 0.905897 11.3519 1.03121 11.3917 1.17972C11.432 1.32767 11.4519 1.50985 11.4519 1.7268V3.13455C11.4519 3.35758 11.4314 3.54693 11.3906 3.70206C11.3497 3.85774 11.2829 3.98913 11.1896 4.09733C11.0958 4.20609 10.9716 4.28558 10.8164 4.33692C10.6608 4.38771 10.4703 4.41311 10.244 4.41311H9.30713ZM10.0182 3.91405H10.2208C10.2914 3.91405 10.3533 3.90798 10.4068 3.89528C10.4598 3.88258 10.5045 3.86768 10.541 3.85056C10.5769 3.8329 10.6072 3.80474 10.6315 3.76554C10.6558 3.72635 10.674 3.69047 10.6867 3.65734C10.6994 3.62422 10.7088 3.57453 10.7149 3.50774C10.7215 3.44094 10.7254 3.38407 10.727 3.33715C10.7281 3.29023 10.7292 3.22011 10.7292 3.12792V1.70086C10.7292 1.60866 10.7276 1.53358 10.7243 1.47727C10.7215 1.42097 10.7138 1.36355 10.7022 1.30669C10.6906 1.24928 10.6729 1.20511 10.6503 1.17364C10.6276 1.14218 10.5973 1.11347 10.5597 1.08752C10.5222 1.06158 10.4747 1.04336 10.4184 1.03342C10.3621 1.02293 10.2937 1.01796 10.2136 1.01796H10.0182V3.91405Z"
        fill="currentColor"
      />
      <path
        d="M11.8491 4.41311V0.509521H13.4832V1.01134H12.5602V2.13146H13.29V2.63328H12.5602V3.92122H13.4948V4.41311H11.8491Z"
        fill="currentColor"
      />
      <path
        d="M14.8266 4.45153C14.6588 4.45153 14.5103 4.43055 14.3817 4.38804C14.253 4.34553 14.1465 4.28702 14.0626 4.21138C13.9787 4.13575 13.9102 4.04301 13.8578 3.93149C13.8053 3.81998 13.7683 3.70018 13.7474 3.5721C13.7258 3.44458 13.7153 3.30049 13.7153 3.14039V1.77958C13.7153 1.6101 13.7264 1.45994 13.7485 1.32855C13.7705 1.19771 13.807 1.07571 13.8589 0.963638C13.9108 0.851571 13.9787 0.759378 14.0626 0.687058C14.1465 0.614739 14.2525 0.558429 14.3806 0.517577C14.5086 0.476725 14.6571 0.456299 14.8266 0.456299C15.0215 0.456299 15.1877 0.482245 15.3257 0.534139C15.4637 0.58548 15.573 0.661664 15.653 0.76269C15.7331 0.862612 15.7911 0.9802 15.8275 1.11435C15.8634 1.2485 15.8816 1.40528 15.8816 1.58415V1.91814H15.1965V1.58636C15.1965 1.39203 15.186 1.26064 15.1656 1.19329C15.1247 1.06135 15.0254 0.990689 14.8669 0.981304H14.8288C14.7692 0.981304 14.7173 0.988481 14.6726 1.00283C14.6279 1.01664 14.5914 1.03872 14.5627 1.06963C14.5346 1.1 14.5119 1.13257 14.4948 1.16735C14.4772 1.20157 14.4645 1.24684 14.4556 1.3026C14.4474 1.35836 14.4418 1.4097 14.4391 1.45662C14.4369 1.5041 14.4358 1.56372 14.4358 1.63604V3.2889C14.4358 3.51745 14.4645 3.68086 14.5219 3.77802C14.5787 3.87573 14.6814 3.92431 14.8288 3.92431C14.8791 3.92431 14.9232 3.91824 14.9619 3.90665C15.0005 3.8945 15.0325 3.88015 15.0585 3.86193C15.0844 3.84371 15.1065 3.81777 15.1242 3.78409C15.1424 3.75042 15.1562 3.71895 15.1656 3.68969C15.175 3.66098 15.1821 3.62068 15.1871 3.56879C15.1915 3.5169 15.1943 3.47273 15.1948 3.43685C15.1959 3.40097 15.1965 3.35128 15.1965 3.2889V2.94H15.8816V3.26019C15.8816 3.40649 15.8711 3.53732 15.8507 3.65325C15.8303 3.76974 15.7955 3.87905 15.7458 3.98062C15.6967 4.08275 15.6326 4.16722 15.5542 4.23512C15.4758 4.30247 15.3754 4.35547 15.2528 4.39411C15.1303 4.43221 14.9884 4.45153 14.8266 4.45153Z"
        fill="currentColor"
      />
      <path
        d="M16.248 4.41311V0.509521H17.8821V1.01134H16.9591V2.13146H17.6889V2.63328H16.9591V3.92122H17.8937V4.41311H16.248Z"
        fill="currentColor"
      />
      <path
        d="M18.1782 4.41311V0.509521H18.6679L19.6235 2.7205V0.509521H20.2125V4.41311H19.7461L18.7811 2.0895V4.41311H18.1782Z"
        fill="currentColor"
      />
      <path
        d="M21.0522 4.40433V1.02574H20.3975V0.500732H22.3965V1.02574H21.7632V4.40433H21.0522Z"
        fill="currentColor"
      />
      <path
        d="M24.0576 4.40433L23.4995 0.500732H24.0979L24.4181 3.01756L24.8344 0.500732H25.3125L25.7387 3.01977L26.0588 0.500732H26.6451L26.087 4.40433H25.5195L25.0795 1.79807L24.6417 4.40433H24.0576Z"
        fill="currentColor"
      />
      <path
        d="M28.0002 4.45153C27.5983 4.45153 27.3101 4.34277 27.1362 4.12416C26.9617 3.9061 26.8745 3.58701 26.8745 3.16634V1.72548C26.8745 1.5179 26.8944 1.33848 26.9336 1.18612C26.9728 1.0343 27.0363 0.901808 27.1229 0.790845C27.2102 0.679329 27.3266 0.595417 27.4729 0.540211C27.6187 0.483902 27.7948 0.456299 28.0002 0.456299C28.4004 0.456299 28.688 0.56395 28.863 0.778699C29.038 0.994001 29.1258 1.30923 29.1258 1.72548V3.16634C29.1258 3.37005 29.1054 3.54947 29.0656 3.70404C29.0253 3.85862 28.9618 3.99277 28.8746 4.10649C28.7879 4.22022 28.6715 4.30634 28.5252 4.3643C28.3794 4.42227 28.2044 4.45153 28.0002 4.45153ZM27.8224 3.89395C27.8721 3.91438 27.9311 3.92431 28.0002 3.92431C28.0692 3.92431 28.1282 3.91438 28.1768 3.89395C28.2254 3.87353 28.264 3.84647 28.2933 3.81335C28.322 3.78078 28.3452 3.73772 28.3618 3.68417C28.3783 3.63062 28.3888 3.57762 28.3943 3.52407C28.3998 3.47052 28.4026 3.40704 28.4026 3.33361V1.56041C28.4026 1.46656 28.3982 1.38817 28.3888 1.32523C28.3794 1.2623 28.3606 1.20323 28.3336 1.14747C28.306 1.09172 28.2646 1.04976 28.2099 1.02271C28.1547 0.995105 28.0852 0.981304 28.0002 0.981304C27.9157 0.981304 27.845 0.995105 27.7893 1.02271C27.7335 1.04976 27.6927 1.09172 27.6656 1.14747C27.6391 1.20323 27.6209 1.2623 27.6115 1.32523C27.6021 1.38817 27.5977 1.46656 27.5977 1.56041V3.33361C27.5977 3.40704 27.6005 3.47052 27.606 3.52407C27.6115 3.57762 27.6225 3.63062 27.6386 3.68417C27.6551 3.73772 27.6778 3.78078 27.7059 3.81335C27.7341 3.84647 27.7733 3.87353 27.8224 3.89395Z"
        fill="currentColor"
      />
      <path
        d="M29.5205 4.41311V0.509521H30.4154C30.6208 0.509521 30.7974 0.526635 30.9448 0.560311C31.0922 0.593986 31.2208 0.650296 31.3301 0.729792C31.4389 0.809288 31.5206 0.916386 31.5747 1.05219C31.6288 1.188 31.6559 1.35417 31.6559 1.55015C31.6559 1.79526 31.6194 1.9951 31.5465 2.15023C31.4737 2.30591 31.3528 2.41908 31.185 2.48974L31.7265 4.41311H31.0133L30.5473 2.63107H30.2316V4.41311H29.5205ZM30.2316 2.17618H30.4032C30.6136 2.17618 30.7632 2.13146 30.8521 2.04203C30.9404 1.95315 30.9851 1.79968 30.9851 1.58327C30.9851 1.37459 30.9465 1.22499 30.8697 1.135C30.7924 1.04446 30.6511 0.999195 30.4458 0.999195H30.2316V2.17618Z"
        fill="currentColor"
      />
      <path
        d="M32.0703 4.41311V0.509521H32.7814V2.20488L33.5487 0.509521H34.2272L33.4808 2.25678L34.3045 4.41311H33.5962L32.9227 2.57697L32.7814 2.83091V4.41311H32.0703Z"
        fill="currentColor"
      />
      <path
        d="M35.3481 4.41311L36.1414 0.509521H36.8829L37.674 4.41311H37.0126L36.8547 3.50663H36.179L36.0189 4.41311H35.3481ZM36.2546 3.0429H36.7774L36.5157 1.43974L36.2546 3.0429Z"
        fill="currentColor"
      />
      <path
        d="M37.9331 4.41311V0.509521H38.4228L39.3784 2.7205V0.509521H39.9674V4.41311H39.5009L38.5359 2.0895V4.41311H37.9331Z"
        fill="currentColor"
      />
      <path
        d="M40.4255 4.41311V0.509521H41.3342C41.5699 0.509521 41.767 0.53326 41.9255 0.580185C42.0839 0.627661 42.2109 0.702189 42.3069 0.803767C42.4024 0.905897 42.4703 1.03121 42.5101 1.17972C42.5504 1.32767 42.5703 1.50985 42.5703 1.7268V3.13455C42.5703 3.35758 42.5498 3.54693 42.509 3.70206C42.4681 3.85774 42.4013 3.98913 42.308 4.09733C42.2147 4.20609 42.09 4.28558 41.9349 4.33692C41.7792 4.38771 41.5887 4.41311 41.3624 4.41311H40.4255ZM41.1366 3.91405H41.3392C41.4099 3.91405 41.4717 3.90798 41.5252 3.89528C41.5782 3.88258 41.6229 3.86768 41.6594 3.85056C41.6953 3.8329 41.7256 3.80474 41.7499 3.76554C41.7742 3.72635 41.7924 3.69047 41.8051 3.65734C41.8178 3.62422 41.8272 3.57453 41.8333 3.50774C41.8399 3.44094 41.8438 3.38407 41.8454 3.33715C41.8465 3.29023 41.8476 3.22011 41.8476 3.12792V1.70086C41.8476 1.60866 41.846 1.53358 41.8427 1.47727C41.8399 1.42097 41.8322 1.36355 41.8206 1.30669C41.809 1.24928 41.7913 1.20511 41.7687 1.17364C41.7461 1.14218 41.7157 1.11347 41.6781 1.08752C41.6406 1.06158 41.5931 1.04336 41.5368 1.03342C41.4805 1.02293 41.4121 1.01796 41.332 1.01796H41.1366V3.91405Z"
        fill="currentColor"
      />
      <path
        d="M9.30713 10.793V6.8894H10.9412V7.39122H10.0182V8.5119H10.748V9.01317H10.0182V10.3011H10.9528V10.793H9.30713Z"
        fill="currentColor"
      />
      <path
        d="M12.3139 10.8307C12.1461 10.8307 11.9976 10.8097 11.869 10.7672C11.7403 10.7247 11.6338 10.6662 11.5499 10.5905C11.466 10.5149 11.3975 10.4222 11.3451 10.3106C11.2926 10.1991 11.2556 10.0793 11.2347 9.95125C11.2131 9.82373 11.2026 9.67964 11.2026 9.51955V8.15873C11.2026 7.98925 11.2137 7.83909 11.2358 7.7077C11.2578 7.57686 11.2943 7.45486 11.3462 7.34279C11.3981 7.23072 11.466 7.13853 11.5499 7.06621C11.6338 6.99389 11.7398 6.93758 11.8679 6.89673C11.9959 6.85588 12.1444 6.83545 12.3139 6.83545C12.5088 6.83545 12.675 6.8614 12.813 6.91329C12.951 6.96463 13.0603 7.04081 13.1404 7.14184C13.2204 7.24176 13.2784 7.35935 13.3148 7.4935C13.3507 7.62765 13.3689 7.78443 13.3689 7.9633V8.29729H12.6838V7.96551C12.6838 7.77118 12.6733 7.63979 12.6529 7.57244C12.612 7.4405 12.5127 7.36984 12.3542 7.36045H12.3161C12.2565 7.36045 12.2046 7.36763 12.1599 7.38198C12.1152 7.39579 12.0787 7.41787 12.05 7.44878C12.0219 7.47915 11.9993 7.51172 11.9821 7.5465C11.9645 7.58072 11.9518 7.62599 11.9429 7.68175C11.9347 7.73751 11.9291 7.78885 11.9264 7.83577C11.9242 7.88325 11.9231 7.94287 11.9231 8.01519V9.66805C11.9231 9.8966 11.9518 10.06 12.0092 10.1572C12.0661 10.2549 12.1687 10.3035 12.3161 10.3035C12.3664 10.3035 12.4105 10.2974 12.4492 10.2858C12.4878 10.2737 12.5198 10.2593 12.5458 10.2411C12.5717 10.2229 12.5938 10.1969 12.6115 10.1632C12.6297 10.1296 12.6435 10.0981 12.6529 10.0688C12.6623 10.0401 12.6694 9.99983 12.6744 9.94794C12.6788 9.89605 12.6816 9.85188 12.6821 9.816C12.6832 9.78012 12.6838 9.73043 12.6838 9.66805V9.31915H13.3689V9.63934C13.3689 9.78564 13.3584 9.91647 13.338 10.0324C13.3176 10.1489 13.2828 10.2582 13.2331 10.3598C13.184 10.4619 13.1199 10.5464 13.0415 10.6143C12.9631 10.6816 12.8627 10.7346 12.7401 10.7733C12.6176 10.8114 12.4757 10.8307 12.3139 10.8307Z"
        fill="currentColor"
      />
      <path
        d="M14.8253 10.8307C14.4235 10.8307 14.1353 10.7219 13.9614 10.5033C13.7869 10.2852 13.6997 9.96616 13.6997 9.54549V8.10463C13.6997 7.89705 13.7196 7.71763 13.7588 7.56527C13.798 7.41345 13.8615 7.28096 13.9481 7.17C14.0354 7.05848 14.1518 6.97457 14.2981 6.91936C14.4439 6.86305 14.62 6.83545 14.8253 6.83545C15.2256 6.83545 15.5132 6.9431 15.6882 7.15785C15.8632 7.37315 15.951 7.68838 15.951 8.10463V9.54549C15.951 9.7492 15.9306 9.92862 15.8908 10.0832C15.8505 10.2378 15.787 10.3719 15.6998 10.4856C15.6131 10.5994 15.4966 10.6855 15.3504 10.7435C15.2046 10.8014 15.0296 10.8307 14.8253 10.8307ZM14.6476 10.2731C14.6973 10.293 14.7563 10.3035 14.8253 10.3035C14.8944 10.3035 14.9534 10.293 15.002 10.2731C15.0506 10.2527 15.0892 10.2256 15.1185 10.1925C15.1472 10.1599 15.1704 10.1169 15.1869 10.0633C15.2035 10.0098 15.214 9.95677 15.2195 9.90322C15.225 9.84967 15.2278 9.78619 15.2278 9.71276V7.93956C15.2278 7.84571 15.2234 7.76732 15.214 7.70439C15.2046 7.64145 15.1858 7.58238 15.1588 7.52662C15.1312 7.47087 15.0898 7.42891 15.0351 7.40186C14.9799 7.37426 14.9104 7.36045 14.8253 7.36045C14.7409 7.36045 14.6702 7.37426 14.6145 7.40186C14.5587 7.42891 14.5179 7.47087 14.4908 7.52662C14.4643 7.58238 14.4461 7.64145 14.4367 7.70439C14.4273 7.76732 14.4229 7.84571 14.4229 7.93956V9.71276C14.4229 9.78619 14.4257 9.84967 14.4312 9.90322C14.4367 9.95677 14.4477 10.0098 14.4638 10.0633C14.4803 10.1169 14.5029 10.1599 14.5311 10.1925C14.5593 10.2256 14.5985 10.2527 14.6476 10.2731Z"
        fill="currentColor"
      />
      <path
        d="M16.375 10.793V6.8894H16.8647L17.8208 9.10039V6.8894H18.4093V10.793H17.9428L16.9778 8.46939V10.793H16.375Z"
        fill="currentColor"
      />
      <path
        d="M19.9586 10.8307C19.5568 10.8307 19.2686 10.7219 19.0947 10.5033C18.9202 10.2852 18.833 9.96616 18.833 9.54549V8.10463C18.833 7.89705 18.8529 7.71763 18.8921 7.56527C18.9313 7.41345 18.9948 7.28096 19.0814 7.17C19.1687 7.05848 19.2851 6.97457 19.4314 6.91936C19.5772 6.86305 19.7533 6.83545 19.9586 6.83545C20.3589 6.83545 20.6465 6.9431 20.8215 7.15785C20.9965 7.37315 21.0837 7.68838 21.0837 8.10463V9.54549C21.0837 9.7492 21.0639 9.92862 21.0241 10.0832C20.9838 10.2378 20.9203 10.3719 20.8331 10.4856C20.7459 10.5994 20.6299 10.6855 20.4837 10.7435C20.3379 10.8014 20.1629 10.8307 19.9586 10.8307ZM19.7809 10.2731C19.8306 10.293 19.8896 10.3035 19.9586 10.3035C20.0277 10.3035 20.0867 10.293 20.1353 10.2731C20.1839 10.2527 20.2225 10.2256 20.2518 10.1925C20.2805 10.1599 20.3037 10.1169 20.3202 10.0633C20.3368 10.0098 20.3473 9.95677 20.3528 9.90322C20.3583 9.84967 20.3611 9.78619 20.3611 9.71276V7.93956C20.3611 7.84571 20.3567 7.76732 20.3473 7.70439C20.3379 7.64145 20.3191 7.58238 20.2915 7.52662C20.2645 7.47087 20.2231 7.42891 20.1684 7.40186C20.1132 7.37426 20.0437 7.36045 19.9586 7.36045C19.8742 7.36045 19.8035 7.37426 19.7478 7.40186C19.692 7.42891 19.6512 7.47087 19.6241 7.52662C19.5976 7.58238 19.5794 7.64145 19.57 7.70439C19.5606 7.76732 19.5562 7.84571 19.5562 7.93956V9.71276C19.5562 9.78619 19.559 9.84967 19.5645 9.90322C19.57 9.95677 19.581 10.0098 19.5971 10.0633C19.6136 10.1169 19.6362 10.1599 19.6644 10.1925C19.6926 10.2256 19.7318 10.2527 19.7809 10.2731Z"
        fill="currentColor"
      />
      <path
        d="M21.5005 10.793L21.564 6.8894H22.2938L22.8591 9.63258L23.4404 6.8894H24.1537L24.2199 10.793H23.6828L23.6358 8.10448L23.0733 10.793H22.6565L22.0868 8.0951L22.0443 10.793H21.5005Z"
        fill="currentColor"
      />
      <path
        d="M25.4229 6.88062H24.7212V10.7842H25.4229V6.88062Z"
        fill="currentColor"
      />
      <path
        d="M26.9746 10.8307C26.8067 10.8307 26.6582 10.8097 26.5296 10.7672C26.401 10.7247 26.2944 10.6662 26.2105 10.5905C26.1266 10.5149 26.0582 10.4222 26.0057 10.3106C25.9533 10.1991 25.9163 10.0793 25.8953 9.95125C25.8738 9.82373 25.8633 9.67964 25.8633 9.51955V8.15873C25.8633 7.98925 25.8743 7.83909 25.8964 7.7077C25.9185 7.57686 25.9549 7.45486 26.0068 7.34279C26.0587 7.23072 26.1266 7.13853 26.2105 7.06621C26.2944 6.99389 26.4004 6.93758 26.5285 6.89673C26.6566 6.85588 26.8051 6.83545 26.9746 6.83545C27.1694 6.83545 27.3356 6.8614 27.4736 6.91329C27.6116 6.96463 27.7209 7.04081 27.801 7.14184C27.881 7.24176 27.939 7.35935 27.9754 7.4935C28.0113 7.62765 28.0295 7.78443 28.0295 7.9633V8.29729H27.3444V7.96551C27.3444 7.77118 27.334 7.63979 27.3135 7.57244C27.2727 7.4405 27.1733 7.36984 27.0149 7.36045H26.9768C26.9172 7.36045 26.8653 7.36763 26.8205 7.38198C26.7758 7.39579 26.7394 7.41787 26.7107 7.44878C26.6825 7.47915 26.6599 7.51172 26.6428 7.5465C26.6251 7.58072 26.6124 7.62599 26.6036 7.68175C26.5953 7.73751 26.5898 7.78885 26.587 7.83577C26.5848 7.88325 26.5837 7.94287 26.5837 8.01519V9.66805C26.5837 9.8966 26.6124 10.06 26.6698 10.1572C26.7267 10.2549 26.8294 10.3035 26.9768 10.3035C27.027 10.3035 27.0712 10.2974 27.1098 10.2858C27.1485 10.2737 27.1805 10.2593 27.2064 10.2411C27.2324 10.2229 27.2545 10.1969 27.2721 10.1632C27.2903 10.1296 27.3041 10.0981 27.3135 10.0688C27.3229 10.0401 27.3301 9.99983 27.3351 9.94794C27.3395 9.89605 27.3422 9.85188 27.3428 9.816C27.3439 9.78012 27.3444 9.73043 27.3444 9.66805V9.31915H28.0295V9.63934C28.0295 9.78564 28.0191 9.91647 27.9986 10.0324C27.9782 10.1489 27.9434 10.2582 27.8937 10.3598C27.8446 10.4619 27.7806 10.5464 27.7022 10.6143C27.6238 10.6816 27.5233 10.7346 27.4008 10.7733C27.2782 10.8114 27.1363 10.8307 26.9746 10.8307Z"
        fill="currentColor"
      />
      <path
        d="M30.483 10.8351C30.1142 10.8351 29.8487 10.7197 29.6858 10.489C29.5235 10.2582 29.4424 9.92255 29.4424 9.48146V8.21007C29.4424 7.98594 29.46 7.79216 29.4954 7.62986C29.5307 7.467 29.5898 7.32457 29.6731 7.20257C29.7565 7.08001 29.8708 6.98837 30.0171 6.92709C30.1628 6.86581 30.3417 6.83545 30.5537 6.83545C30.9396 6.83545 31.2167 6.92985 31.3845 7.11865C31.5523 7.30801 31.6368 7.59453 31.6368 7.97931V8.17032H30.9561V7.96772C30.9561 7.88104 30.9545 7.81093 30.95 7.75573C30.9462 7.70107 30.9368 7.64532 30.9219 7.58956C30.907 7.53435 30.8855 7.49129 30.8573 7.46148C30.8291 7.43167 30.7905 7.40738 30.7419 7.38861C30.6933 7.36984 30.6337 7.3599 30.5631 7.3599C30.5095 7.3599 30.462 7.36708 30.4206 7.38033C30.3792 7.39358 30.3439 7.41014 30.3157 7.42946C30.2876 7.44934 30.2638 7.47694 30.244 7.51337C30.2241 7.54926 30.2097 7.58459 30.1992 7.61827C30.1893 7.65194 30.181 7.69555 30.1755 7.74855C30.17 7.8021 30.1667 7.84903 30.165 7.88988C30.1634 7.93073 30.1628 7.98318 30.1628 8.04777V9.62278C30.1628 9.85796 30.1921 10.0302 30.25 10.1395C30.308 10.2488 30.4184 10.3029 30.5818 10.3029C30.6668 10.3029 30.7375 10.2869 30.7949 10.2549C30.8523 10.2229 30.897 10.1743 30.9291 10.1086C30.9611 10.0435 30.9843 9.96892 30.9975 9.88501C31.0108 9.8011 31.0174 9.69952 31.0174 9.58028V9.26008H30.5962V8.80132H31.6578V10.7832H31.1963L31.1444 10.3879C31.0268 10.686 30.8065 10.8351 30.483 10.8351Z"
        fill="currentColor"
      />
      <path
        d="M32.0977 10.793V6.8894H32.9925C33.1979 6.8894 33.3746 6.90652 33.522 6.94019C33.6694 6.97387 33.798 7.03018 33.9073 7.10968C34.0161 7.18917 34.0978 7.29627 34.1519 7.43208C34.206 7.56788 34.233 7.73405 34.233 7.93003C34.233 8.17515 34.1966 8.37499 34.1237 8.53067C34.0508 8.6858 33.9299 8.79897 33.7621 8.86963L34.3037 10.793H33.5904L33.1245 9.01096H32.8087V10.793H32.0977ZM32.8087 8.55662H32.9804C33.1907 8.55662 33.3403 8.5119 33.4292 8.42192C33.5175 8.33303 33.5623 8.17956 33.5623 7.96316C33.5623 7.75448 33.5236 7.60487 33.4469 7.51489C33.3696 7.42435 33.2288 7.37908 33.0229 7.37908H32.8087V8.55662Z"
        fill="currentColor"
      />
      <path
        d="M35.7379 10.8307C35.3361 10.8307 35.0479 10.7219 34.874 10.5033C34.6995 10.2852 34.6123 9.96616 34.6123 9.54549V8.10463C34.6123 7.89705 34.6322 7.71763 34.6714 7.56527C34.7106 7.41345 34.7741 7.28096 34.8607 7.17C34.948 7.05848 35.0644 6.97457 35.2107 6.91936C35.3565 6.86305 35.5326 6.83545 35.7379 6.83545C36.1382 6.83545 36.4258 6.9431 36.6008 7.15785C36.7758 7.37315 36.863 7.68838 36.863 8.10463V9.54549C36.863 9.7492 36.8432 9.92862 36.8034 10.0832C36.7631 10.2378 36.6996 10.3719 36.6124 10.4856C36.5252 10.5994 36.4087 10.6855 36.263 10.7435C36.1172 10.8014 35.9422 10.8307 35.7379 10.8307ZM35.5602 10.2731C35.6099 10.293 35.6689 10.3035 35.7379 10.3035C35.807 10.3035 35.866 10.293 35.9146 10.2731C35.9632 10.2527 36.0018 10.2256 36.0311 10.1925C36.0598 10.1599 36.083 10.1169 36.0995 10.0633C36.1161 10.0098 36.1266 9.95677 36.1321 9.90322C36.1376 9.84967 36.1404 9.78619 36.1404 9.71276V7.93956C36.1404 7.84571 36.136 7.76732 36.1266 7.70439C36.1172 7.64145 36.0984 7.58238 36.0708 7.52662C36.0438 7.47087 36.0024 7.42891 35.9477 7.40186C35.8925 7.37426 35.823 7.36045 35.7379 7.36045C35.6535 7.36045 35.5828 7.37426 35.5271 7.40186C35.4713 7.42891 35.4305 7.47087 35.4034 7.52662C35.3769 7.58238 35.3587 7.64145 35.3493 7.70439C35.3399 7.76732 35.3355 7.84571 35.3355 7.93956V9.71276C35.3355 9.78619 35.3383 9.84967 35.3438 9.90322C35.3493 9.95677 35.3603 10.0098 35.3764 10.0633C35.3929 10.1169 35.4155 10.1599 35.4437 10.1925C35.4719 10.2256 35.5111 10.2527 35.5602 10.2731Z"
        fill="currentColor"
      />
      <path
        d="M37.7105 10.7842L37.1523 6.88062H37.7508L38.071 9.39744L38.4872 6.88062H38.9653L39.3915 9.39964L39.7117 6.88062H40.298L39.7398 10.7842H39.1723L38.7323 8.17795L38.2945 10.7842H37.7105Z"
        fill="currentColor"
      />
      <path
        d="M41.0327 10.7842V7.40562H40.3779V6.88062H42.3769V7.40562H41.7437V10.7842H41.0327Z"
        fill="currentColor"
      />
      <path
        d="M42.5981 10.793V6.8894H43.3092V8.52129H44.1025V6.8894H44.8135V10.793H44.1025V9.02752H43.3092V10.793H42.5981Z"
        fill="currentColor"
      />
      <path
        d="M32.8018 22.2001L27.332 27.4037C27.1255 27.6091 27.0195 27.7604 27.0195 28.1849V42.7685C27.0195 42.9397 27.1598 43.0799 27.332 43.0799H32.9823C33.154 43.0799 33.2931 42.9397 33.2931 42.7685V22.3315C33.2931 22.1592 33.1589 21.8451 32.8018 22.2001Z"
        fill="currentColor"
      />
      <path
        d="M17.0435 28.9792L11.5731 34.1817C11.3672 34.3877 11.2612 34.5384 11.2612 34.9629V42.7684C11.2612 42.9384 11.4009 43.0787 11.5731 43.0787H17.2234C17.3957 43.0787 17.5348 42.9384 17.5348 42.7684V29.1094C17.5348 28.9372 17.399 28.6225 17.0435 28.9792Z"
        fill="currentColor"
      />
      <path
        d="M35.2879 15.8902C35.2901 15.8487 35.2752 15.804 35.2415 15.7715C35.2128 15.7433 35.1758 15.7306 35.1405 15.7273L35.1101 15.729L35.0654 15.7317L30.877 16.6763L30.8008 16.6846C30.771 16.6901 30.7428 16.705 30.7191 16.7271C30.6578 16.7894 30.6578 16.8899 30.7191 16.9523L30.7666 16.9998L31.7233 17.9559L22.2323 27.4469L17.849 23.063C17.7993 23.0133 17.7209 23.0133 17.6707 23.063L16.4816 24.2527C16.4805 24.2527 16.4805 24.2543 16.4805 24.2543L9.45392 31.2798C9.40534 31.3289 9.40534 31.4095 9.45392 31.4581L10.6447 32.6483C10.6933 32.698 10.7728 32.698 10.8219 32.6483L17.7607 25.7112L20.9416 28.8922C20.945 28.8971 20.9466 28.9026 20.9521 28.9071L22.1418 30.0956C22.1909 30.1459 22.2704 30.1459 22.3201 30.0956L33.0913 19.3245L34.096 20.3281C34.1573 20.3905 34.2583 20.3905 34.319 20.3281C34.3489 20.2989 34.3632 20.263 34.3649 20.2254L34.3654 20.2116L35.2857 15.936L35.2879 15.8902Z"
        fill="currentColor"
      />
      <path
        d="M24.9536 29.8925L22.3071 32.5611C22.2568 32.6025 22.1834 32.6009 22.1365 32.5545L19.7389 30.1106C19.3 29.6728 19.2051 30.0278 19.2051 30.1994V42.7681C19.2051 42.9387 19.3431 43.0789 19.5148 43.0789H25.1667C25.3379 43.0789 25.4775 42.9387 25.4775 42.7681V29.9913C25.4775 29.8191 25.3417 29.5039 24.9536 29.8925Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DecentWork;
