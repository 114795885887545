import useOnTouchOutside from "hooks/useOnTouchOutside";
import React from "react";
import { XCircle } from "react-feather";

const Popover = (props, ref) => {
  const { children, onClose, active, style } = props;

  const _handleClose = () => {
    onClose && onClose();
  };
  // if (!active) return <div ref={ref} style={style}></div>;
  useOnTouchOutside({ ref: ref, onClickOutside: _handleClose });

  return (
    <div ref={ref} className={"z-10 duration-700"} style={style}>
      {!active ? (
        ""
      ) : (
        <div
          className={
            " px-6 py-8 text-white rounded-md shadow-2xl shadow-thrive-forest-green bg-black/90"
          }
        >
          <button onClick={_handleClose} className={"absolute top-2 right-2"}>
            <XCircle size={24} color="white" />
          </button>
          {children ? children : "empty popover"}
        </div>
      )}
    </div>
  );
};

export default React.forwardRef(Popover);
