import React, { useState } from "react";
import FAQtab from "components/elements/FAQtab";
import Container from "components/sections/container";
import PaginationButton from "components/pageComponents/resources/button/paginationButton";

const FaqSection = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [pagination, setPagination] = useState({ start: 0, end: 6 });
  const totalPages = Math.ceil(data?.FAQelement?.length / 6);
  const [activePage, setActivePage] = useState(0);
  const PaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <PaginationButton
          key={i}
          value={i}
          active={i === activeIndex}
          onClick={() => {
            setPagination({ start: (i - 1) * 6, end: i * 6 });
            setActiveIndex(i);
          }}
        />
      );
    }
    return buttons;
  };
  return (
    <Container>
      <div className="flex flex-col py-7">
        <h3 className="font-bold leading-9 text-center text-h3">
          {data?.title ?? "FAQ's"}
        </h3>
        <p className="leading-[26px] mt-6 text-lg font-normal">
          {data?.description}{" "}
          {data?.email && (
            <a
              href={`mailto:${data?.email}`}
              target="_blank"
              rel="noreferrer"
              className="not-italic"
            >
              {data?.email}
            </a>
          )}
        </p>
      </div>

      <div className="flex flex-col gap-4 py-7">
        {data?.FAQelement &&
          data?.FAQelement.slice(pagination.start, pagination.end).map(
            (item, index) => {
              return (
                <FAQtab
                  key={index}
                  isActive={index === activePage}
                  content={item?.answer}
                  title={item?.question}
                  onClick={() => setActivePage(index)}
                />
              );
            }
          )}
      </div>
      {data?.FAQelement && data?.FAQelement.length > 6 && (
        <div className="flex gap-2 justify-center">
          {PaginationButtons()}
          <PaginationButton
            onClick={() => {
              if (activeIndex === totalPages) {
                setActiveIndex(1);
                setPagination({ start: 0, end: 6 });
              } else {
                setPagination({
                  start: activeIndex * 6,
                  end: (activeIndex + 1) * 6,
                });
                setActiveIndex((prev) => prev + 1);
              }
            }}
            value={"Next"}
          />
        </div>
      )}
    </Container>
  );
};

export default FaqSection;
