import React from "react";
import Container from "../container";
import RichText from "./richText";

/** a Rich Text Component surounded by a continer */
const RichTextSection = ({ data, ...restProps }) => {
  return (
    <Container>
      <RichText data={data} {...restProps} />
    </Container>
  );
};

export default RichTextSection;
