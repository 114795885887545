import React from "react";
import Carousel from "react-multi-carousel";
import SliderButton from "components/sliderButton";
import defaultTheme from "tailwindcss/defaultTheme";
import { oneSpaceApart } from "utils/utils";

const DEFAULT_RESPONSIVE_OPTIONS = {
  xxl: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  xl: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  lg: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  md: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  sm: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  xs: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const SECOND_DEFAULT_RESPONSIVE = {
  xxl: {
    breakpoint: { max: 3000, min: defaultTheme.screens["2xl"] },
    items: DEFAULT_RESPONSIVE_OPTIONS.xxl.items,
    //   slidesToSlide: 3, // optional, default to 1.
  },
  xl: {
    breakpoint: {
      max: defaultTheme.screens["2xl"],
      min: defaultTheme.screens["xl"],
    },
    items: DEFAULT_RESPONSIVE_OPTIONS.xl.items,
    //   slidesToSlide: 2, // optional, default to 1.
  },
  lg: {
    breakpoint: {
      max: defaultTheme.screens["xl"],
      min: defaultTheme.screens["lg"],
    },
    items: DEFAULT_RESPONSIVE_OPTIONS.lg.items,
    //   slidesToSlide: 2, // optional, default to 1.
  },
  md: {
    breakpoint: {
      max: defaultTheme.screens["lg"],
      min: defaultTheme.screens["md"],
    },
    items: DEFAULT_RESPONSIVE_OPTIONS.md.items,
    //   slidesToSlide: 2, // optional, default to 1.
  },
  sm: {
    breakpoint: {
      max: defaultTheme.screens["md"],
      min: defaultTheme.screens["sm"],
    },
    items: DEFAULT_RESPONSIVE_OPTIONS.sm.items,
    //   slidesToSlide: 1, // optional, default to 1.
  },
  xs: {
    breakpoint: { max: defaultTheme.screens["sm"], min: 0 },
    items: DEFAULT_RESPONSIVE_OPTIONS.sm.items,
    //   slidesToSlide: 1, // optional, default to 1.
  },
};

export const NextButton = function (props) {
  const { onClick } = props;
  return <SliderButton next onClick={onClick} />;
};

//   you can overrie this ny passing your own function
export const PrevButton = function (props) {
  const { onClick } = props;
  return <SliderButton prev onClick={onClick} />;
};

const CustomDot = ({ active, carouselState, ...props }) => {
  const activeClass = active ? "bg-primary" : "bg-slate-400";
  return (
    <button
      className={oneSpaceApart(["w-4 h-4 mr-2 rounded-[50%]", activeClass])}
      carouselstate={carouselState}
      {...props}
    ></button>
  );
};
const SliderMobile = (props) => {
  const { children, responsive } = props;
  return (
    <Carousel
      autoPlay={false}
      showDots
      centerMode={false}
      customDot={<CustomDot />}
      renderButtonGroupOutside={true}
      responsive={responsive}
      {...props}
    >
      {children}
    </Carousel>
  );
};

const Slider = (props) => {
  const { children, responsive } = props;
  return (
    <>
      <div className="hidden md:block">
        <Carousel
          autoPlay={false}
          responsive={responsive ? responsive : SECOND_DEFAULT_RESPONSIVE}
          customRightArrow={<NextButton />}
          customLeftArrow={<PrevButton />}
          centerMode={false}
          {...props}
        >
          {children}
        </Carousel>
      </div>
      <div className="md:hidden">
        <SliderMobile
          autoPlay={false}
          responsive={responsive ? responsive : SECOND_DEFAULT_RESPONSIVE}
          {...props}
        >
          {children}
        </SliderMobile>
      </div>
    </>
  );
};

export default Slider;
