import React, { useEffect, useMemo, useState } from "react";
import Container from "components/sections/container";
import Image from "next/image";
import BottomBorderButton from "components/pageComponents/resources/button/bottomBorderButton";
import dayjs from "dayjs";
import { parseRichTextObjForText, tryJSONdecode } from "utils/utils";
import Link from "next/link";
import { getFilteredBlog } from "utils/api/hectare";

const FirstPost = ({ post }) => {
  const attributes = post?.attributes;
  const titleLimit = 15;
  const descriptionLimit = 10;
  const category = attributes?.category?.data?.attributes;
  const blogHeader = attributes?.Blogheader;
  const image = blogHeader?.image?.data?.attributes?.url;
  let formatDate = dayjs(new Date(blogHeader?.date ?? new Date())).format(
    "MMMM DD, YYYY"
  );
  const description = useMemo(() => {
    const parseDescription = tryJSONdecode(blogHeader?.description);
    const parsed =
      parseDescription && parseRichTextObjForText(parseDescription);

    return parsed ?? blogHeader?.description;
  }, [blogHeader?.description]);
  const titleWordSplit = blogHeader?.title?.split(" ");
  //const titleTotalWord = titleWordSplit?.length;
  const titleText = titleWordSplit?.slice(0, titleLimit)?.join(" ");

  const descriptionWordSplit = description?.split(" ");
  //const descriptionTotalWord = descriptionWordSplit?.length;
  const descriptionText = descriptionWordSplit
    ?.slice(0, descriptionLimit)
    ?.join(" ");

  return (
    <div className="col-span-2 md:row-span-2 shadow-md overflow-hidden bg-white">
      <figure className="relative w-full h-1/2 overflow-hidden">
        <Image
          src={image ?? "/images/png/about-bg-icon-2.png"}
          layout="fill"
          alt="image"
          className="object-cover"
        />
        <figcaption className="text-white text-left text-sm bg-primary min-w-[150px] absolute bottom-0 flex font-bold left-4 p-2">
          {category?.title ?? "Hectare"}
        </figcaption>
      </figure>
      <div className=" flex flex-col px-4 gap-2 pt-2 items-start">
        <p className="text-xs font-normal">{formatDate}</p>
        <h4 className="font-bold text-left text-base">
          {blogHeader?.title ? `${titleText}...` : ""}
        </h4>
        <p className="text-sm font-normal">
          {description ? `${descriptionText}...` : ""}
        </p>
        <BottomBorderButton
          value={"Read More"}
          url={`/resources/hectare/${attributes?.slug ?? ""}`}
        />
      </div>
    </div>
  );
};

const OtherPosts = ({ post }) => {
  const attributes = post?.attributes;
  const category = attributes?.category?.data?.attributes;
  const blogHeader = attributes?.Blogheader;
  const image = blogHeader?.image?.data?.attributes?.url;
  let formatDate = dayjs(new Date(blogHeader?.date ?? new Date())).format(
    "MMMM DD, YYYY"
  );

  const description = useMemo(() => {
    const parseDescription = tryJSONdecode(blogHeader?.description);
    const parsed =
      parseDescription && parseRichTextObjForText(parseDescription);

    return parsed ?? blogHeader?.description;
  }, [blogHeader?.description]);

  const descriptionLimit = 12;
  const descriptionWordSplit = description?.split(" ");
  // const descriptionTotalWord = descriptionWordSplit?.length;
  const descriptionText = descriptionWordSplit
    ?.slice(0, descriptionLimit)
    ?.join(" ");

  return (
    <div className="col-span-2 sm:col-span-1 h-full bg-white cursor-pointer hover:text-primary translate-all">
      <Link
        className="h-full"
        href={`/resources/hectare/${attributes?.slug ?? ""}`}
        passHref
      >
        <div className="shadow-md h-full overflow-hidden">
          <figure className="relative w-full h-3/5 overflow-hidden">
            <Image
              src={image ?? "/images/png/about-bg-icon-2.png"}
              layout="fill"
              alt="image"
              className="object-cover hover:scale-105 transition-all"
            />
            <figcaption className="text-white text-left text-sm bg-primary min-w-[150px] absolute bottom-0 flex font-bold left-4 p-2 hover:scale-105 transition-all">
              {category?.title ?? "Hectare"}
            </figcaption>
          </figure>
          <div className="flex flex-col gap-2 pt-2 px-4">
            <p className="text-xs font-normal">{formatDate}</p>
            <h4 className="font-bold text-left text-sm ">
              {description ? `${descriptionText}...` : ""}
            </h4>
          </div>
        </div>
      </Link>
    </div>
  );
};

const OurResourcesLanding = ({ data }) => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const update = async () => {
      const featuredBlog = await getFilteredBlog({
        Blogheader: {
          featured: {
            $eq: true,
          },
        },
      });

      if (featuredBlog) {
        setPosts(featuredBlog?.data);
      }
    };
    update();
  }, []);
  return (
    posts.length >= 5 && (
      <Container className="py-20 bg-[rgb(245,251,243)]">
        <h3 className="mx-auto font-bold leading-8 pb-8 sm:mx-0 text-h3 text-center lg:text-h2">
          {data?.title ?? "Our Resources"}
        </h3>
        <div className="grid grid-cols-2 md:grid-cols-4 md:grid-rows-2 gap-4 h-[1900px] md:h-[1000px] py-4">
          {[1, 2, 3, 4, 5].map((_, index) => {
            if (index === 0) {
              return <FirstPost post={posts[index]} key={index} />;
            }
            if (index < posts.length) {
              return <OtherPosts post={posts[index]} key={index} />;
            }
            return <OtherPosts key={index} />;
          })}
        </div>
      </Container>
    )
  );
};

export default OurResourcesLanding;
