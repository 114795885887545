import React, { useState } from "react";

const RenderMapPath = (
  {
    path,
    label,
    location,
    active,
    showLabel,
    color,
    activeColor,
    hoverColor,
    hoverLabelColor = "#fff",
    onClick,
  },
  ref
) => {
  const fillColor = active ? activeColor : color;
  const [isHover, setHover] = useState(false);

  return (
    <>
      {path && (
        <path
          ref={ref}
          d={path}
          fill={isHover ? hoverColor : fillColor}
          className={`${location}-path map-peice`}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onClick={onClick}
        />
      )}
      {label && showLabel && (
        <path
          d={label}
          fill={isHover ? hoverLabelColor : "#000"}
          className={`${location}-label`}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onClick={onClick}
        />
      )}
    </>
  );
};
export default React.forwardRef(RenderMapPath);
