import { fetchAPI } from "utils/api";

export const getHectareBlogs = async (startPage, pageSize, filters) => {
  try {
    const blogsRes = await fetchAPI(`/hectares`, {
      filters,
      populate: {
        category: "*",
        title: "*",
        slug: "*",
        Blogheader: {
          populate: {
            date: "*",
            title: "*",
            description: "*",
            image: {
              populate: "*",
            },
          },
        },
      },
      fields: "*",
      sort: [
        {
          Blogheader: {
            date: "desc",
          },
        },
        {
          id: "desc",
        },
      ],
      pagination: {
        page: startPage,
        pageSize,
      },
    });
    //console.log("blogRes",blogsRes)
    // printObj(blogs, "blogs obj from getHectareBlogs()");
    return blogsRes;
  } catch (err) {
    console.log(err);
  }
};

export const getOneObject = async (path, slug, populate) => {
  let filters = {};
  if (slug) {
    filters = {
      slug: {
        $eq: slug,
      },
    };
  }
  try {
    const blogRes = await fetchAPI(path, {
      filters,
      populate,
    });
    //console.log("blogRes",blogsRes)
    // printObj(blogs, "blogs obj from getHectareBlogs()");
    return blogRes;
  } catch (err) {
    console.log(err);
  }
};

export const getFilteredBlog = async (filters) => {
  try {
    const blogRes = await fetchAPI("/hectares", {
      filters,
      populate: {
        category: "*",
        content: "*",
        Blogheader: {
          populate: {
            date: "*",
            title: "*",
            description: "*",
            image: {
              populate: "*",
            },
          },
        },
      },
      sort: [
        {
          Blogheader: {
            date: "desc",
          },
        },
        {
          id: "desc",
        },
      ],
    });
    return blogRes;
  } catch (err) {
    console.log(err);
  }
};

export const relatedBlogs = async (path, slug, populate) => {
  try {
    const blogRes = await fetchAPI(path, {
      filters: {
        slug: {
          $eq: slug,
        },
      },

      sort: [
        {
          hectare_blogs: {
            Blogheader: {
              date: "desc",
            },
          },
        },
        {
          id: "desc",
        },
      ],
      populate,
    });
    //console.log("blogRes",blogsRes)
    // printObj(blogs, "blogs obj from getHectareBlogs()");
    return blogRes;
  } catch (err) {
    console.log(err);
  }
};
