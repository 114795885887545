import React from "react";

const ContainerHeader = ({ title, description }) => {
  return (
    <div className="flex flex-col items-start pt-24 pb-8 mx-auto">
      <h3 className="mx-auto font-bold leading-8 sm:mx-0 text-h4 lg:text-h3">
        {title}
      </h3>
      <p className="mt-6 text-sm leading-8 text-center sm:text-left sm:text-base xl:text-lg">
        {description}
      </p>
    </div>
  );
};

export default ContainerHeader;
