import React, { useState, useEffect } from "react";
import Image from "next/image";
import styles from "../../styles/accordion.module.css";
import classNames from "classnames";
import { oneSpaceApart } from "utils/utils";
import { FACEBOOK_ICON } from "assets/svg";

const AccordionImage = (props) => {
  let { currentImageIndex, src, images, width, height, className } = props;
  let [shouldShow, setShouldShow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShouldShow(false);
    }, []);
    setTimeout(() => {
      setShouldShow(true);
    }, [1000]);
  }, [src]);
  return (
    <div
      className={oneSpaceApart([
        styles.accordionImageContainer,
        "hidden md:block",
      ])}
    >
      {(images?.length > 0 &&
        images.map((image, imageIndex) => {
          if (currentImageIndex === imageIndex)
            return (
              <Image
                key={`accordion-image-${imageIndex}`}
                src={image?.image?.data?.attributes?.url ?? FACEBOOK_ICON}
                width={width}
                height={height}
                layout="responsive"
                alt=""
                className={classNames(styles.accordionImage, className, {
                  [`${styles.currentImage}`]: currentImageIndex === imageIndex,
                })}
              />
            );
        })) || (
        <Image
          src={src}
          width={width}
          height={height}
          // layout="responsive"
          alt=""
          className={classNames(styles.accordionImage, className, {
            [`${styles.currentImage}`]: shouldShow,
          })}
        />
      )}
    </div>
  );
};

export default AccordionImage;
