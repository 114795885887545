import {
  FARMER_ICON,
  GRAINS_ICON,
  HECTARE_ICON,
  PERSON_ICON,
  PYRAMIDS_ICON,
  STORE_ICON,
  WAREHOUSE_ICON,
} from "assets/svg";
import Image from "next/image";
import React from "react";

const data = {
  "Grains Icon": {
    icon: GRAINS_ICON,
    iconWidth: "30px",
    iconHeight: "30px",
  },

  "Farmer Icon": {
    icon: FARMER_ICON,
    iconWidth: "26px",
    iconHeight: "29px",
  },

  "Pyramids Icon": {
    icon: PYRAMIDS_ICON,
    iconWidth: "37px",
    iconHeight: "29px",
  },

  "Hectare Icon": {
    icon: HECTARE_ICON,
    iconWidth: "37px",
    iconHeight: "29px",
  },

  "Person Icon": {
    icon: PERSON_ICON,
    iconWidth: "26px",
    iconHeight: "29px",
  },
  "Warehouse Icon": {
    icon: WAREHOUSE_ICON,
    iconWidth: "38px",
    iconHeight: "32px",
  },
  "Store Icon": {
    icon: STORE_ICON,
    iconWidth: "37px",
    iconHeight: "29px",
  },
};

const MetricCard = (props) => {
  const { metric } = props;
  const icon = data[metric.icon] ?? data["Farmer Icon"];
  return (
    <>
      {/* metric card */}
      <div className="flex flex-row items-start  h-[69px]">
        <div className="block min-w-[35px] h-[65px]">
          <Image
            src={icon?.icon}
            alt="metric icon"
            layout="fixed"
            width={icon?.iconWidth}
            height={icon?.iconHeight}
            className=""
          />
        </div>
        <div className="w-full ml-3">
          <p className="text-3xl leading-7 font-semibold">{metric?.value}</p>
          <p className="mt-3">{metric?.title}</p>
        </div>
      </div>
    </>
  );
};

export default MetricCard;
