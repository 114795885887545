import Container from "components/sections/container";
import Image from "next/image";
import React from "react";
import LinkButton from "components/elements/LinkButton";

const bg = {
  title: "Who We Are",
  description: `We are a fast-growing agricultural technology company passionate
  about ensuring food security. We empower smallholder farmers,
  leveraging our technology to access finance, and improve
  productivity & sales to promote food security.`,
  backgroundImage: "/images/svg/tractor-icon.svg",
  firstImage: "/images/webp/who-we-are-bg1-icon.web",
  secondImage: "/images/png/who-we-are-bg2-icon.png",
};
const WhoWeAre = ({ data }) => {
  return (
    <Container
      style={{
        backgroundImage: `url(${bg.backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left bottom",
      }}
    >
      <div className="pt-20 pb-5 flex flex-col md:items-start md:justify-center md:flex-row">
        {/* left side */}
        <div className="flex flex-col w-full mr-0 md:mr-4 mt-8  md:justify-center md:items-end md:w-1/2">
          <Image
            width={588}
            height={692}
            alt=""
            src={data?.firstImage?.data?.attributes?.url}
            className="object-center object-cover"
          />
        </div>
        {/* right side */}
        <div className="w-full ml-0 md:ml-8 py-8 md:w-1/2">
          <div className="max-w-[649px]">
            <h3 className="font-bold text-h3">{data?.title}</h3>
            <p className="text-left text-lg mt-6">{data?.description}</p>
          </div>
          <div className="mt-2 md:py-10">
            {data?.button && <LinkButton data={data?.button} />}
          </div>
          <div className="mx-auto mt-8">
            <Image
              width={649}
              height={588}
              alt=""
              src={data?.secondImage?.data?.attributes?.url}
              layout="responsive"
              className="object-center object-cover"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WhoWeAre;
