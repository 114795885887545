import React from "react";
import styles from "styles/accordion.module.css";
import AccordionTab from "./accordionTab";

const Accordion = (props) => {
  const { tabs, currentTabIndex, currentImage, setCurrentTabIndex } = props;
  const displayedItemList = tabs.map((tab, tabIndex) => {
    return (
      <AccordionTab
        isActive={currentTabIndex === tabIndex}
        title={tab.title}
        textContent={tab.content}
        key={`accordionTab-${tabIndex}`}
        index={tabIndex}
        onClick={setCurrentTabIndex}
        currentImage={tab?.image?.data?.attributes?.url ?? currentImage}
      />
    );
  });
  return <ul className={styles.accordion}>{displayedItemList}</ul>;
};

export default Accordion;
