import React from "react";
import MetricCard from "./metricCard";

const ImpactGroupElement = ({ metricsGroup }) => {
  return (
    <div className="flex flex-col justify-start mx-auto my-4 sm:mx-0 sm:flex-row sm:justify-start ">
      {metricsGroup.map((metric, metricIndex) => (
        // rendering two in a row
        <div
          className="min-w-[250px] sm:max-w-[300px] w-full mt-4"
          key={`metricItem-${metricIndex}`}
        >
          <MetricCard metric={metric} />
        </div>
      ))}
    </div>
  );
};

export default ImpactGroupElement;
