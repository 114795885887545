import { ErrorMessage, Field } from "formik";
import FormMessage from "./formMessage";

const TextArea = ({
  label,
  disabled,
  placeholder,
  required,
  message,
  name,
}) => {
  return (
    <div className="w-full text-input_root">
      {label && (
        <>
          <label htmlFor={label}>{label}</label>
        </>
      )}
      <div className="block w-full">
        <Field
          component="textarea"
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          rows={8}
          cols={100}
          className="min-w-[250px] sm:min-w-[250px] w-full border placeholder:text-base text-base border-1 border-thrive-sonic-silver p-3 rounded-md resize-none"
        />
      </div>
      <ErrorMessage
        component={"p"}
        className="text-error text-xs"
        name={name}
      />
      <FormMessage message={message} />
    </div>
  );
};

export default TextArea;
