import classNames from "classnames";
import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";

const SliderButton = (props) => {
  const {
    disabled,
    className,
    next = false,
    prev = false,
    onClick,
    ariaLabel,
  } = props;

  const classname = classNames(
    "absolute z-10 w-20 h-20 top-1/3 md:bg-none transition-colors duration-700 hover:ease-in-out rounded-full hover:bg-thrive-nyanza-green/40",
    {
      "-left-8 ": prev,
      "-right-8": next,
      "cursor-not-allowed": disabled,
    }
  );

  return (
    <button
      label={ariaLabel}
      aria-label={ariaLabel}
      onClick={onClick}
      className={className || classname}
      disabled={disabled}
    >
      {next && <ChevronRight className="mx-auto" width={28} />}
      {prev && <ChevronLeft className="mx-auto" width={28} />}
    </button>
  );
};

export default SliderButton;
