import Container from "components/sections/container";
import Image from "next/image";
import React, { useState, useMemo, useRef } from "react";
import { ArrowRight } from "react-feather";
import tailwindConfig from "tailwind.config";
import styles from "styles/vertical.tab.module.css";
import scroll from "react-scroll";
import { oneSpaceApart } from "utils/utils";
import RightTab from "components/elements/ourProcess/ourProcessRightTab";
import LeftTab from "components/elements/ourProcess/ourProcessLeftTab";
import { FACEBOOK_CONTACT, FACEBOOK_ICON } from "assets/svg";

const MobileView = ({ index, title, content, selected, image, onChange }) => {
  return (
    <div className="md:hidden" onClick={onChange}>
      <div
        className={`md:hidden p-4 mb-2 flex justify-start text-base border ${
          selected && "border-primary"
        } rounded`}
      >
        <span className={`mr-4 text-base  ${selected && "text-primary"}`}>
          {`0${index}`}
        </span>
        <h3 className="mr-auto">{title}</h3>
        {selected && (
          <ArrowRight
            size={22}
            color={tailwindConfig.theme.extend.colors.primary}
          />
        )}
      </div>

      {selected && (
        <div className={"mb-4"}>
          {/* eslint-disable-next-line @next/next/no-img-element*/}
          <img
            alt={title}
            src={image?.data?.attributes?.url}
            className="w-full h-auto object-cover object-center rounded-t overflow-clip"
          />
          <p className="p-4 border border-t-0 border-primary rounded-b overflow-clip">
            {content}
          </p>
        </div>
      )}
    </div>
  );
};

const OurProcessTab = ({ data }) => {
  const [currentTab, setCurrentTab] = useState(data?.processes[0]?.id);
  const [content, setCurrentContent] = useState(
    data?.processes[0]?.content ?? FACEBOOK_ICON
  );
  const [currentImageUrl, setCurrentImageUrl] = useState(
    data?.processes[0]?.image?.data?.attributes?.url
  );

  const leftTabLastIndex = Math.ceil(data?.processes?.length / 2);
  console.log("index", leftTabLastIndex);
  const tabs = useRef({
    left: data?.processes.slice(0, leftTabLastIndex),
    right: data?.processes?.slice(leftTabLastIndex, data?.processes?.length),
  });
  console.log("tabs", tabs.current);

  const memorizedMobileView = useMemo(() => {
    return [...tabs.current.left, ...tabs.current.right].map(
      ({ title, content, id, image, step }) => {
        return (
          <MobileView
            index={step}
            title={title}
            content={content}
            image={image}
            selected={currentTab === id}
            onChange={() => {
              setCurrentTab(id);
              setCurrentContent(content);
              setCurrentImageUrl(image?.data?.attributes?.url);
            }}
            key={`our-process-unique-${step}`}
          />
        );
      }
    );
  }, [currentTab]);
  return (
    <Container
      style={{
        backgroundImage: `url(/images/svg/our-work-bg-icon.svg)`,
        backgroundSize: "80%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
      }}
      className="py-8"
    >
      <div>
        <div className="pt-5 pb-10 px-16">
          <h3 className="pb-10 font-bold text-center text-h3">{data?.title}</h3>
          <div
            className={oneSpaceApart([styles.verticaltabs, "hidden md:flex"])}
          >
            <div className={styles.leftTabContainter}>
              {tabs?.current?.left &&
                tabs?.current?.left?.length &&
                tabs?.current?.left?.map(
                  ({ title, content, image, id, step }, index) => (
                    <RightTab
                      currentTab={currentTab}
                      processKey={id}
                      key={`our-process-unique-${id}-${index}`}
                      onClick={() => {
                        setCurrentTab(id),
                          setCurrentContent(content),
                          setCurrentImageUrl(image?.data?.attributes?.url);
                        scroll.animateScroll.scrollTo(screen.height / 1.2);
                      }}
                      title={title}
                      step={step}
                    />
                  )
                )}
            </div>
            <div className={styles.contentContainer}>
              <Image
                className="object-fit rounded-t-lg"
                src={currentImageUrl ?? FACEBOOK_CONTACT}
                width={501}
                height={265}
                alt=""
                layout="responsive"
              />
              <p className="text-left lg:p-5 xl:p-5 2xl:pt-3 text-base">
                {content}
              </p>
            </div>
            <div className={styles.rightTabContainer}>
              {tabs?.current?.right &&
                tabs?.current?.right?.length &&
                tabs?.current?.right?.map(
                  ({ title, content, image, id, step }, index) => (
                    <LeftTab
                      title={title}
                      onClick={() => {
                        setCurrentTab(id),
                          setCurrentContent(content),
                          setCurrentImageUrl(image?.data?.attributes?.url);
                        scroll.animateScroll.scrollTo(screen.height / 1.2);
                      }}
                      currentTab={currentTab}
                      key={`${id}-${index}`}
                      processKey={id}
                      step={step}
                    />
                  )
                )}
            </div>
          </div>
        </div>
        {
          //mobile view
          memorizedMobileView
        }
      </div>
    </Container>
  );
};
export default OurProcessTab;
