import classNames from "classnames";
import IconTextElement from "components/elements/iconTextElement";
import React from "react";
import tailwindConfig from "tailwind.config";
import Container from "./container";

const IconTextFlexSection = ({ data }) => {
  return (
    <Container
      style={{
        backgroundColor:
          data?.PaleGreenBackgroundColor &&
          tailwindConfig.theme.extend.colors["pale-green"],
      }}
    >
      <div className="py-16">
        {data?.title && (
          <h3 className="font-bold text-center text-h3">{data.title}</h3>
        )}
        <div className="px-4 2xl:pt-5 lg:pt-3 md:pt-5">
          {data.description && (
            <p
              className={classNames("text-lg", {
                "text-center": data?.icons?.length < 4,
              })}
            >
              {data.description}
            </p>
          )}
        </div>
        <div className="flex items-center gap-4 justify-center py-8 md:flex-row flex-wrap">
          {data?.icons &&
            data?.icons?.map((item, index) => (
              <IconTextElement key={index} item={item} />
            ))}
        </div>
      </div>
    </Container>
  );
};

export default IconTextFlexSection;
