import React from "react";
import ReactTooltip from "react-tooltip";
import { oneSpaceApart } from "utils/utils";
import style from "../../../styles/partnersOrbit.module.css";

const PartnerLogo = ({
  itemStyle,
  item,
  isPlaying,
  index,
  setPlaying,
  inner,
}) => {
  console.log(item);
  const releaseSpeed = inner ? 8 : 4;
  return (
    <>
      <span
        className={oneSpaceApart([
          itemStyle,
          "shadow shadow-thrive-lime-green",
        ])}
        data-tip={item?.title ?? item?.id}
        style={{
          "--d": `${index * releaseSpeed}s`,
          animationPlayState: `${isPlaying ? "running" : "paused"}`,
        }}
        onMouseOver={() => {
          setPlaying(false);
        }}
        onMouseLeave={() => {
          setPlaying(true);
        }}
      >
        {/* eslint-disable-next-line @next/next/no-img-element*/}
        <img
          alt={item?.title ?? "Logo"}
          src={item?.Logo?.data?.attributes?.url}
          className={style.logo}
        />
      </span>
      <ReactTooltip type="success" key={oneSpaceApart([index, "-tooltip"])} />
    </>
  );
};

export default React.memo(PartnerLogo);
