import Image from "next/image";
import React from "react";
import DecentWork from "./sdgs/decentWork";
import GenderEquality from "./sdgs/genderEquality";
import IndustryAndInnovation from "./sdgs/IndustryAndinnovation";
import NoPoverty from "./sdgs/noPoverty";
import ResposibleConsumption from "./sdgs/responsibleConsumption";
import ZeroHunger from "./sdgs/zeroHunger";
import { oneSpaceApart } from "utils/utils";
import ClimateAction from "./sdgs/climateAction";
import GoodHealth from "./sdgs/goodHealth";
import LifeBelowWater from "./sdgs/lifeBelowWater";

const SDGCard = (props) => {
  const {
    className = "flex flex-row",
    imageIcon,
    // text = "",
    iconWidth = 70,
    iconHeight = 70,
    name,
    color,
  } = props;

  if (imageIcon) {
    return (
      <>
        <div className={oneSpaceApart(["hidden sm:block", className])}>
          <Image src={imageIcon} width={iconWidth} height={iconHeight} alt="" />
        </div>
        <div className={oneSpaceApart(["sm:hidden", className])}>
          <Image src={imageIcon} width={iconWidth} height={iconHeight} alt="" />
        </div>
      </>
    );
  }
  const resolveAndRenderIcon = () => {
    switch (name) {
      case "noPoverty":
        return <NoPoverty width={iconWidth} height={iconHeight} fill={color} />;
      case "zeroHunger":
        return (
          <ZeroHunger width={iconWidth} height={iconHeight} fill={color} />
        );
      case "genderEquality":
        return (
          <GenderEquality width={iconWidth} height={iconHeight} fill={color} />
        );
      case "decentWork":
        return (
          <DecentWork width={iconWidth} height={iconHeight} fill={color} />
        );
      case "industryAndInnovation":
        return (
          <IndustryAndInnovation
            width={iconWidth}
            height={iconHeight}
            fill={color}
          />
        );
      case "responsibleConsumption":
        return (
          <ResposibleConsumption
            width={iconWidth}
            height={iconHeight}
            fill={color}
          />
        );
      case "climateAction":
        return (
          <ClimateAction width={iconWidth} height={iconHeight} fill={color} />
        );
      case "goodHealthAndWellBeing":
        return (
          <GoodHealth width={iconWidth} height={iconHeight} fill={color} />
        );
      case "lifeBelowWater":
        return (
          <LifeBelowWater width={iconWidth} height={iconHeight} fill={color} />
        );
      default:
        return <></>;
    }
  };

  const resolveAndRenderIconMobile = () => {
    switch (name) {
      case "noPoverty":
        return <NoPoverty width={35} height={35} fill={color} />;
      case "zeroHunger":
        return <ZeroHunger width={30} height={35} fill={color} />;
      case "genderEquality":
        return <GenderEquality width={35} height={35} fill={color} />;
      case "decentWork":
        return <DecentWork width={35} height={35} fill={color} />;
      case "industryAndInnovation":
        return <IndustryAndInnovation width={35} height={35} fill={color} />;
      case "responsibleConsumption":
        return <ResposibleConsumption width={35} height={35} fill={color} />;
      case "climateAction":
        return <ClimateAction width={35} height={35} fill={color} />;
      case "goodHealthAndWellBeing":
        return <GoodHealth width={35} height={35} fill={color} />;
      case "lifeBelowWater":
        return <LifeBelowWater width={35} height={35} fill={color} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className={oneSpaceApart(["hidden sm:block", className])}>
        {resolveAndRenderIcon()}
      </div>
      <div className={oneSpaceApart(["sm:hidden", className])}>
        {resolveAndRenderIconMobile()}
      </div>
    </>
  );
};

export default SDGCard;
