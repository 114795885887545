import PhotoId from "components/elements/photoId";
import React from "react";
import Container from "./container";

const PhotoIdGrid = ({ data }) => {
  return (
    <Container>
      <div className="flex flex-col gap-5 py-8">
        {data.title && (
          <div className="mt-5">
            {(data?.title || data?.description) && (
              <h3 className="font-bold text-center text-h3">{data?.title}</h3>
            )}
            {data?.description && (
              <h4 className="pt-5 font-demibold text-center lg:text-sm 2xl:text-lg ">
                {data?.description}
              </h4>
            )}
          </div>
        )}
        <div className="flex flex-wrap gap-4 justify-center">
          {data?.photoId &&
            data?.photoId?.length &&
            data?.photoId?.map((photoIdData, index) => {
              return <PhotoId key={index} data={photoIdData} />;
            })}
        </div>
      </div>
    </Container>
  );
};

export default PhotoIdGrid;
