import {
  BOWL,
  FEMALE_FARMER,
  INSTITUTION,
  DEVELOPMENT_ICON,
  FIN_INCLUSION_ICON,
  INPUT_ICON,
  MARKET_ICON,
  POST_HARVEST_ICON,
  TECHNOLOGY_ICON,
} from "assets/svg";
import Image from "next/image";
import React from "react";

const icons = {
  "Food Producer": FEMALE_FARMER,
  Institution: INSTITUTION,
  "Food Bowl": BOWL,
  "bulb innovation": DEVELOPMENT_ICON,
  "phone cash": FIN_INCLUSION_ICON,
  seeds: INPUT_ICON,
  market: MARKET_ICON,
  warehouse: POST_HARVEST_ICON,
  "agric tech": TECHNOLOGY_ICON,
};

const IconTextElement = ({ item }) => {
  return (
    <div className="w-full my-3 sm:px-1 max-w-[350px] md:mr-4 hover:scale-110 transition-all">
      <div className="py-3 text-center">
        <Image
          src={icons[item.icon] ?? FEMALE_FARMER}
          alt={"icon"}
          width={124}
          height={131}
          layout="fixed"
        />
      </div>
      <div className="flex flex-col gap-4 items-center">
        {item?.title && (
          <h4 className="text-h4 font-bold leading-6 text-center">
            {item.title}
          </h4>
        )}

        {item?.description && (
          <p className=" text-base font-normal leading-7 text-center">
            {item.description}
          </p>
        )}
      </div>
    </div>
  );
};

export default IconTextElement;
