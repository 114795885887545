import React from "react";
import Container from "components/sections/container";
import {
  PointDown,
  PointRight,
} from "../elements/theoryOfChange/theoryOfChangeData";
import ContainerHeader from "components/elements/containerElement/containerHeader";

const Pointers = ({ data }) => {
  return data.pointDirection === "Down" ? (
    <div className="md:w-3/12">
      <PointDown data={data} />
    </div>
  ) : (
    <div className="flex gap-5 flex-row md:w-4/12">
      <PointRight data={data} />
    </div>
  );
};
const TheoryOfChange = (props) => {
  const { data } = props;

  return (
    <Container
      style={{
        backgroundImage: `url(/images/svg/tractor-icon.svg)`,
        backgroundSize: "80%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left bottom",
      }}
    >
      <div className="mt-7">
        <ContainerHeader
          title={data?.heading?.title}
          description={data?.heading?.description}
        />
      </div>
      <div className="flex flex-col md:flex-row gap-6 justify-between py-12">
        {data?.TocColumn &&
          data.TocColumn.map((item, index) => {
            return <Pointers data={item} key={index} />;
          })}
      </div>
    </Container>
  );
};

export default TheoryOfChange;
