import Image from "next/image";
import React from "react";
import SDGCard from "./sdgCard";

const StateMetrics = (props) => {
  const { state, iconColor } = props;

  return (
    <div className="flex flex-col">
      <h3 className="mb-2 text-2xl font-bold border-b pb-[10px]">
        {state?.state}
      </h3>
      <div className={"flex flex-row flex-wrap items-start gap-4"}>
        {state?.metrics?.length > 0 &&
          state.metrics.map((metric, metricIndex) => {
            return (
              <div
                key={`metric-${metricIndex}`}
                className="mt-4 flex flex-row w-[45%] px-2 mb-4 items-start"
              >
                <div className="self-start">
                  <Image
                    src={metric.icon}
                    alt=""
                    className="text-white fill-white"
                    width={metric.iconWidth}
                    height={metric.iconHeight}
                  />
                </div>
                <div className=" md:ml-2 lg:ml-3 xl:ml-5 ">
                  <p className="text-sm font-bold leading-7">{metric.value}</p>
                  <p className=" text-xs">{metric.title}</p>
                </div>
              </div>
            );
          })}
      </div>
      <div className="py-3">
        <h4 className="mb-2 text-base font-bold leading-[28px] border-b">
          SDG Targets
        </h4>
        <div className="flex flex-row flex-wrap items-center">
          {state?.sdgs?.map((sdg, sdgIndex) => {
            return (
              <SDGCard
                key={`state-sdg-${sdgIndex}`}
                // imageIcon={sdg.icon}
                name={sdg.name}
                // text={sdg.title}
                color={iconColor}
                iconWidth={47}
                iconHeight={47}
                className="mb-4 mr-4 fill-current"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StateMetrics;
