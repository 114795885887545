import React, { useCallback, useEffect, useState } from "react";
import Image from "next/image";
import Container from "components/sections/container";
import StateMetrics from "components/stateMetrics";
import { INTERACTIVE_HAND } from "assets/svg";
import { XCircle } from "react-feather";
import tailwindConfig from "tailwind.config";
import NigerianMap from "components/svgMaps/nigerianMap";
import { getStateData } from "utils/api/map";
import { parseStateData } from "utils/mapState";

const InteractiveMap = ({ data }) => {
  const [selectedState, setSelectedState] = useState("");
  const [mapState, setMapState] = useState([]);
  const handleShowData = (e, state) => {
    const selected = mapState.find((data) => {
      return data.stateId === state.country;
    });

    if (selected?.active) setSelectedState(selected);
  };
  const handleHideData = useCallback(() => {
    if (selectedState) return setSelectedState(null);
  }, [selectedState]);

  useEffect(() => {
    getStateData().then((data) => {
      if (data) {
        setMapState(parseStateData(data?.data ?? []));
      }
    });
  }, []);
  return (
    <Container>
      <div className="flex flex-col py-20">
        <h3 className="font-bold leading-9 text-center text-h3">
          {data?.title}
        </h3>
        {/* <div className=""> */}
        <br />
        {data?.description && (
          <p className="my-6 text-lg">
            From a small town in Kaduna State, Nigeria with less than 500
            smallholder farmers to...
          </p>
        )}
        <br />
        {/* </div> */}
        <div className="flex flex-col items-center py-16  lg:justify-between lg:flex-row">
          {/* left side */}
          <div className="w-full">
            {/* non-mobile map */}
            <div className="hidden xl:block w-full">
              <NigerianMap
                mapData={mapState}
                onStateClick={(e, state) => handleShowData(e, state)}
              />
            </div>
            {/* mobile map */}
            <div className="block xl:hidden mx-auto w-full">
              <NigerianMap
                mapData={mapState}
                onStateClick={(e, state) => handleShowData(e, state)}
              />
            </div>
          </div>
          {/* right side */}
          <div className="hidden xl:flex lg:flex-col">
            {/* show metrics */}
            <div className="border-thrive-lime-green relative w-full xl:w-[480px] border-[3px] p-2 rounded-2xl min-h-[572px] flex flex-col justify-center">
              {selectedState ? (
                <>
                  <button
                    onClick={handleHideData}
                    className="absolute top-2 right-2"
                  >
                    <XCircle size={28} />
                  </button>
                  <StateMetrics
                    iconColor={tailwindConfig.theme.extend.colors.black}
                    state={selectedState}
                  />{" "}
                </>
              ) : (
                <div className="flex flex-col h-full justify-center items-center">
                  <Image
                    src={INTERACTIVE_HAND}
                    width={79}
                    height={135}
                    layout="fixed"
                    alt=""
                  />
                  <p className="text-lg font-normal mt-4 leading-5">
                    Select a Location
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default InteractiveMap;
