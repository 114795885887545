import { useEffect, useState } from "react";

const useAccordionWithImage = (
  data,
  config = {
    initialIndex: 0,
    initialImage: "",
    imageKey: "image",
  }
) => {
  const [accordionData, setAccordionData] = useState(data);
  const [currentTabIndex, _setCurrentTabIndex] = useState(config.initialIndex);
  const [currentImage, _setCurrentImage] = useState(
    config?.initialImage?.length === 0
      ? accordionData[0][config.imageKey]
      : config?.initialImage
  );

  const setCurrentTabIndex = (tabIndex) => {
    if (currentTabIndex === tabIndex) return;
    _setCurrentTabIndex(tabIndex);
    _setCurrentImage(accordionData[tabIndex]?.image?.data?.attributes?.url);
  };

  useEffect(() => {
    setAccordionData(data);
  }, [data]);

  return {
    currentImage,
    currentTabIndex,
    currentTab: accordionData[currentTabIndex],
    setCurrentTabIndex,
    tabs: accordionData,
    images: accordionData,
  };
};

export default useAccordionWithImage;
