import React from "react";
import Container from "components/sections/container";

const VisionAndMission = ({ data }) => {
  return (
    <>
      <Container>
        <div className="py-7">
          <h3 className="font-bold text-center text-h3">{data?.title}</h3>
          <div className="flex py-5">
            <div>
              <p className="pt-3 font-normal text-lg">
                We are a fast-growing agricultural technology company passionate
                about ensuring food security. We link farmers to capital,
                data-driven best practices and access to local and global
                markets for their commodities.
              </p>
              <h4 className="pt-4 font-demibold mt-9 text-h4">Our Vision...</h4>
              <p className="pt-4 font-demibold text-lg">
                To build an Africa that feeds herself and the world.
              </p>
              <p className="pt-4 text-lg">
                With our available resources, Africa has the capacity to supply
                enough food to ensure no one ever goes to sleep hungry. We
                believe that smallholder farmers are the key to unlocking our
                continent's food production potential. That's why we empower
                them, leveraging our technology to access finance, and improve
                productivity &amp; sales to promote food security.
              </p>
              <p className="pt-4 text-lg">
                Our key focus areas are{" "}
                <span className="font-demibold">
                  Impact, Scale, and Technology.
                </span>
              </p>
              <h4 className="pt-4 font-demibold mt-9 text-h4">
                Our Mission...
              </h4>
              <p className="pt-4 font-demibold text-lg">
                To build the largest network of profitable farmers across
                Africa, ensuring there's always food for all.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <div
        style={{
          backgroundImage: `url(/images/png/about-bg-icon-2.png)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="lg:h-[500px] 2xl:h-[700px] -z-50"
      >
        <div
          style={{
            backgroundImage: `url(/images/svg/thrive-arc-white.svg)`,
            backgroundSize: "40%",
            backgroundPosition: "right bottom",
            backgroundRepeat: "no-repeat",
          }}
          className="lg:h-[500px] 2xl:h-[700px] -z-10"
        ></div>
      </div>
    </>
  );
};

export default VisionAndMission;
