import Container from "components/sections/container";
import FarmerStoryElement from "components/elements/farmerStoryElement";
import React from "react";

const FarmerStories = ({ data }) => {
  return (
    <Container>
      <div className="flex flex-col py-7">
        <h3 className="font-bold leading-9 text-center text-h3">
          {data?.title}
        </h3>
        {data?.description && (
          <p className="leading-[26px] mt-6 text-lg font-normal">
            {data?.description}
          </p>
        )}
        {data?.embededYoutubeLink && (
          <FarmerStoryElement data={data?.embededYoutubeLink} />
        )}
      </div>
    </Container>
  );
};

export default FarmerStories;
