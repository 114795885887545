import Link from "next/link";
import React from "react";

const _checkExternalLink = ({ external, children, ...rest }) => {
  return external ? (
    <a {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <Link {...rest}>{children}</Link>
  );
};
const BottomBorderButton = ({ value, url, external }) => {
  return (
    <_checkExternalLink
      external={external}
      href={url}
      className="inline-block text-primary hover:scale-110 transition-allborder-b-[2px]"
    >
      <a className="inline-block text-primary hover:scale-110 transition-all border-b-[2px]">
        {`${value} `}&rarr;
      </a>
    </_checkExternalLink>
  );
};

export default BottomBorderButton;
