import React from "react";
import { oneSpaceApart } from "utils/utils";
import tailwindConfig from "tailwind.config";
import { ArrowRight } from "react-feather";
import styles from "styles/vertical.tab.module.css";

const RightTab = ({ title, onClick, currentTab, processKey, step }) => {
  return (
    <div onClick={onClick} className={styles.leftTabs}>
      <div
        className={oneSpaceApart([
          currentTab === processKey ? `${styles.active}` : "",
          styles.inner,
        ])}
      >
        <p
          className={currentTab === processKey ? `${styles.selectedTab}` : ""}
        >{`${step}`}</p>
        <p className="text-[24px]">{title}</p>

        {currentTab === processKey ? (
          // Change the SVG icon color on mouse over
          <ArrowRight
            size={22}
            color={tailwindConfig.theme.extend.colors.primary}
          />
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default RightTab;
