/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import React, { useCallback, useEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
  EMAIL_ICON,
  PHONE_ICON,
  FACEBOOK_CONTACT,
  INSTAGRAM_CONTACT,
  LINKEDIN_CONTACT,
  TWITTER_CONTACT,
  YOUTUBE_CONTACT,
} from "assets/svg";
import Button from "components/primary/button";
import Container from "components/sections/container";
import TextArea from "components/form/textArea";
import Image from "next/image";
import TextField from "components/form/textField";
import useReCaptcha from "hooks/useReCaptcha";
import { postAPI } from "utils/api";
import { hyphenate, oneSpaceApart } from "utils/utils";
import { Form, Formik } from "formik";
import useMessage from "hooks/useMessage";

const ImageProps = {
  instagram: {
    src: INSTAGRAM_CONTACT,
    className: "w-full object-cover object-center h-8",
    alt: "instagram",
  },
  youtube: {
    src: YOUTUBE_CONTACT,
    className: "pt-[2px] w-full object-cover object-center h-[30px]",
    alt: "youtube",
  },
  linkedin: {
    src: LINKEDIN_CONTACT,
    className: "w-full object-cover object-center h-8",
    alt: "linkedin",
  },
  twitter: {
    src: TWITTER_CONTACT,
    className: "w-full object-cover object-center h-[30px]",
    alt: "twitter",
  },
  facebook: {
    src: FACEBOOK_CONTACT,
    className: "mt-[-4px] w-full object-cover object-center h-9",
    alt: "facebook",
  },
};

const ContactUs = ({ data }) => {
  let _buttonText = useRef(data?.buttonText || "Submit");

  const { message, setMessage } = useMessage(); // type = success | error

  const [btnText, setBtnText] = useState(_buttonText.current);
  const [formMeta, setFormMeta] = useState({
    initialValues: {},
    validationSchema: {},
  });

  const { handleSubmit } = useReCaptcha({
    minimumScore: data?.minimumCaptchaScore || 0.5,
    actionName: "Contact Page Form",
    // handle recaptcha loaded
    onLoad: () => {
      setBtnText(_buttonText);
    },
    // handle sucessful captcha challenge, submit form content to strapi
    onSuccess: async (values, config) => {
      config.formikOptions.setSubmitting(true);
      try {
        const response = await postAPI("/ezforms/submit", {
          token: config.token,
          formData: values,
        });
        if (response.ok) {
          config.formikOptions.setSubmitting(false);
          config.formikOptions.resetForm();
          setBtnText(_buttonText.current);
          setMessage({
            text: "Thanks for getting in touch, we will get back to you shortly.",
            type: "success",
          });
        }
      } catch (err) {
        console.log(err);
        config.formikOptions.setSubmitting(false);
        setMessage({
          text: "An Error Occured, Kindly try again.",
          type: "error",
        });
        setBtnText("Try Again");
      }
    },
    onError: (error, config) => {
      // handle error
      setMessage({
        text: "An Error Occured, Kindly try again." + error.message,
        type: "error",
      });
      setBtnText("Try Again");
      config.formikOptions.setSubmitting(false);
    },
  });

  let initForm = useCallback(() => {
    let initialValues = {};
    let validationForFields = {};
    let validationSchema;

    if (data?.inputField?.length) {
      data.inputField.forEach((item) => {
        initialValues = { ...initialValues, [item.name]: "" };
        validationForFields = {
          ...validationForFields,
          [item.name]:
            item.typeOfText === "email"
              ? yup.string().email().required()
              : yup.string().required(),
        };
      });
    }

    validationSchema = yup.object().shape(validationForFields);

    return { initialValues, validationSchema };
  }, [data]);

  useEffect(() => {
    setFormMeta(() => initForm());
  }, [initForm]);

  return (
    <div className="py-10">
      <Container>
        <h3 className="text-3xl text-center font-demibold md:text-h3">
          {data?.title}
        </h3>
        <div className="mt-10">
          <p className="text-base text-center">{data?.description ?? ""}</p>
        </div>
      </Container>
      <Container>
        <div className="flex flex-col justify-between gap-4 md:pt-10 md:flex-row">
          <Formik
            initialValues={formMeta.initialValues}
            validationSchema={formMeta.validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit: submitForValidation, isSubmitting }) => (
              <Form className="flex flex-col order-2 w-full gap-4 my-4 md:order-1 md:w-1/2 ">
                {data.inputField &&
                  data.inputField.map((item, index) => {
                    return item.typeOfText === "message" ? (
                      <TextArea
                        placeholder={item?.placeholder}
                        name={item?.name}
                        required={item?.required}
                        key={hyphenate(["form-text-area", index])}
                      />
                    ) : (
                      <TextField
                        type={item?.typeOfText}
                        placeholder={item?.placeholder}
                        name={item?.name}
                        required={item?.required}
                        key={hyphenate(["form-text-field", index])}
                      />
                    );
                  })}
                <div className="mt-5">
                  <Button
                    disabled={isSubmitting}
                    cta
                    onClick={submitForValidation}
                    loading={isSubmitting}
                    buttonText={btnText}
                  />
                </div>
                {message?.text && (
                  <span
                    className={oneSpaceApart([
                      `text-${message?.type || "black"}`,
                      "text-sm",
                    ])}
                  >
                    {message?.text || ""}
                  </span>
                )}
              </Form>
            )}
          </Formik>
          <div className="flex flex-col order-1 w-full gap-5 sm:w-1/2 md:ml-16 md:order-2">
            {data?.locationlink &&
              data?.locationlink.map((item, index) => {
                return (
                  <div key={index}>
                    <h4 className="text-h4 font-demibold">{item?.title}</h4>
                    <address className="text-lg not-italic font-normal">
                      <a rel="noreferrer" href={item?.url} target="_blank">
                        {item?.description}
                      </a>
                    </address>
                  </div>
                );
              })}
            {data?.directContactLink &&
              data?.directContactLink.map((item, index) => {
                const icon = item?.icon === "message" ? EMAIL_ICON : PHONE_ICON;
                return (
                  <div className="flex items-center" key={index}>
                    <Image
                      src={icon}
                      width={29}
                      height={22}
                      layout="fixed"
                      alt=""
                    />
                    <address className="pl-4 text-lg not-italic">
                      <a
                        href={item?.link}
                        target="_blank"
                        rel="noreferrer"
                        className="not-italic"
                      >
                        {item?.information}
                      </a>
                    </address>
                  </div>
                );
              })}
            <div className="flex items-start gap-4">
              {data?.socialMedia &&
                data.socialMedia.map((item, index) => {
                  const getIconProps =
                    ImageProps[item?.icon] ?? ImageProps["facebook"];
                  return (
                    <div className="flex hover:scale-110" key={index}>
                      <address className="text-lg ">
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                          className="block not-italic"
                        >
                          <img {...getIconProps} />
                        </a>
                      </address>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default React.memo(ContactUs);
