import _ from "lodash";
import { useRouter } from "next/router";
import { hyphenate } from "utils/utils";
import ImpactMetricSection from "./impactMetricSection";
import IconTextFlexSection from "./iconTextFlexSection";
import TestimonialSection from "./testimonialSection";
import PartnersList from "components/sections/ourPartners";
import WhoWeAre from "./whoWeAre";
import TheoryOfChange from "./theoryOfChangeSection";
import AccordionSection from "./AccordionSection";
import PhotoIdGrid from "./photoIDGrid";
import ContactUs from "./contact-us";
import OurProcessTab from "./ourProcess/ourProcess";
import FarmerStories from "./farmerStories";
import PartnerStories from "./partnerStories";
import InteractiveMap from "./interactiveMap";
import VisionAndMission from "components/pageComponents/aboutUs/visionAndMission";
import RichTextSection from "./richText/richTextSection";
import OurResourcesLanding from "./ourResourcesLanding";
import FaqSection from "./faqSection";
import ResourceHeader from "components/pageComponents/resources/header";

// map Strapi sections to section Components
const sectionComponents = {
  sections: {
    "sections.rich-text": RichTextSection,
    "sections.metrics-and-map": ImpactMetricSection,
    "sections.accordion-section": AccordionSection,
    "sections.icon-text-grid": IconTextFlexSection,
    "sections.testimonial": TestimonialSection,
    "sections.partners-logo": PartnersList,
    "sections.who-we-are": WhoWeAre,
    "sections.toc": TheoryOfChange,
    "sections.photo-id-grid": PhotoIdGrid,
    "sections.contact-us": ContactUs,
    "sections.process-section": OurProcessTab,
    "sections.farmer-stories": FarmerStories,
    "sections.partner-stories": PartnerStories,
    "sections.impact-map": InteractiveMap,
    "sections.vission-and-mission": VisionAndMission,
    "sections.our-resources-landing": OurResourcesLanding,
    "sections.faq-section": FaqSection,
    "elements.component-header": ResourceHeader,
  },
};

/** Match each seaction obect (sectionData) with the rendered */
const Section = ({ sectionData }) => {
  // prepare component
  const SectionComponent = _.get(
    sectionComponents.sections,
    sectionData.__component
  );
  if (!SectionComponent) return null;
  // Display section
  return <SectionComponent data={sectionData} />;
};

const PreviewModeBanner = () => {
  const router = useRouter();
  const exitURL = `/api/exit-preview?redirect=${encodeURIComponent(
    router.asPath
  )}`;

  return (
    <div className="py-4 font-semibold tracking-wide text-red-100 uppercase bg-red-600">
      <div className="container">
        Preview mode is on.{" "}
        <a className="underline" href={exitURL}>
          Turn off
        </a>
      </div>
    </div>
  );
};

/** Iterate through the sections array (contentSections on strapi) */
const Sections = ({ sections, preview }) => {
  return (
    <div>
      {/* show a banner if preview mode is on */}
      {preview && <PreviewModeBanner />}
      {/* Map through and pass each section object to <Section />*/}
      {sections.map((section) => (
        <Section
          sectionData={section}
          key={hyphenate([section.__component, section.id])}
        />
      ))}
    </div>
  );
};
export default Sections;
