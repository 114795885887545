import {
  FARMER_ICON,
  GRAINS_ICON,
  HECTARE_ICON,
  PERSON_ICON,
  PYRAMIDS_ICON,
  STORE_ICON,
  WAREHOUSE_ICON,
} from "assets/svg";

export const metric = {
  "Farmers Onboarded": {
    title: "Farmers Onboarded",
    icon: FARMER_ICON,
    iconWidth: "26px",
    iconHeight: "29px",
  },
  "Storage Acquired": {
    title: "Storage Acquired",
    icon: WAREHOUSE_ICON,
    iconWidth: "28px",
    iconHeight: "22px",
  },
  "Grains Produced & Traded": {
    title: "Grains Produced & Traded",
    icon: GRAINS_ICON,
    iconWidth: "30px",
    iconHeight: "30px",
  },
  "Hectares Cultivated": {
    title: "Hectares Cultivated",
    icon: HECTARE_ICON,
    iconWidth: "37px",
    iconHeight: "29px",
  },
  "Communities Reached": {
    title: "Communities Reached",
    icon: PYRAMIDS_ICON,
    iconWidth: "37px",
    iconHeight: "29px",
  },
  "Jobs Created": {
    title: "Jobs Created",
    icon: PERSON_ICON,
    iconWidth: "26px",
    iconHeight: "29px",
  },
  "Warehouses Available": {
    title: "Warehouses Available",
    icon: STORE_ICON,
    iconWidth: "37px",
    iconHeight: "29px",
  },
};

export const sdg = {
  noPoverty: {
    title: "",
    name: "noPoverty",
    iconWidth: "47px",
    iconHeight: "47px",
  },
  zeroHunger: {
    title: "",
    name: "zeroHunger",
    iconWidth: "47px",
    iconHeight: "47px",
  },
  genderEquality: {
    title: "",
    name: "genderEquality",
    iconWidth: "47px",
    iconHeight: "47px",
  },
  decentWork: {
    title: "",
    name: "decentWork",
    iconWidth: "47px",
    iconHeight: "47px",
  },
  industryAndInnovation: {
    title: "",
    name: "industryAndInnovation",
    iconWidth: "47px",
    iconHeight: "47px",
  },
  responsibleConsumption: {
    title: "",
    name: "responsibleConsumption",
    iconWidth: "27px",
    iconHeight: "13px",
  },
};

////////////////mapStateParser//////////////////////
export const parseStateData = (data) => {
  try {
    return data.map((val) => {
      const parsedVal = val?.attributes;
      return {
        state: parsedVal?.stateName ?? "",
        active: parsedVal?.active ?? false,
        stateId: parsedVal?.state ?? "",
        metrics: parsedVal?.metricCard?.map((one) => {
          return {
            ...metric[one?.title ?? "Farmers Onboarded"],
            value: one?.value ?? 0,
          };
        }),
        sdgs:
          parsedVal?.sdg?.map((one) => {
            return sdg[one?.sdg ?? "zeroHunger"];
          }) ?? [],
      };
    });
  } catch (e) {
    return [];
  }
};
