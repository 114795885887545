import React from "react";

const ContainerBody = ({ children }) => {
  return (
    <div className="flex flex-col max-w-[1440px] items-center pb-24 xl:flex-row justify-center mx-auto">
      {children}
    </div>
  );
};

export default ContainerBody;
