import Image from "next/image";
import React from "react";

const PhotoId = (props) => {
  const { data } = props;
  const avatar = "/images/png/avatar_male.png";
  return (
    <div className="m-3">
      <Image
        src={data?.photo?.data?.attributes?.url ?? avatar}
        width={data?.image?.width ?? 283}
        height={data?.image?.height ?? 330}
        alt=""
        layout="fixed"
        className="object-cover object-center"
      />
      <div className="font-normal w-[283px]">
        {data?.fullName && (
          <p className="leading-8 font-demibold text-dark text-lg">
            {data?.fullName}
          </p>
        )}
        {data?.workPosition && (
          <p className="mt-2 leading-[22.4px] text-lg text-primary">
            {data?.workPosition}
          </p>
        )}
      </div>
    </div>
  );
};

export default PhotoId;
