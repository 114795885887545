import mixpanel from "mixpanel-browser";
mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, { debug: true });

let env_check = process.env.NODE_ENV === "production";

let actions = {
  track: (name, props = {}) => {
    try {
      if (env_check) {
        mixpanel.track(name, props);
      }
    } catch (error) {
      //   FIXME: use Sentry to track these errors
      console.log(error);
    }
  },
};

export let Mixpanel = actions;
