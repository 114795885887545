import React from "react";
import { chunk } from "lodash";
import ImpactGroup from "../elements/impactGroupElement";

const MetricList = ({ metric }) => {
  return (
    chunk(metric, metric?.cols ?? 2)?.map((metricsGroup, index) => {
      return <ImpactGroup metricsGroup={metricsGroup} key={index} />;
    }) ?? null
  );
};

export default MetricList;
