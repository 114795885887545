/* eslint-disable @next/next/no-img-element */
import React, { useState } from "react";
import style from "../../../styles/partnersOrbit.module.css";
import PartnerLogo from "./PartnerLogo";

const PartnerOrbit = ({ data }) => {
  const itemStyle = [style.item, style.item1].join(" ");
  const itemStyle2 = [style.item2].join(" ");
  const itemStyle3 = [style.item3].join(" ");
  const [isPlayingFirst, setPlayingFirst] = useState(true);
  const [isPlayingSecond, setPlayingSecond] = useState(true);
  const [isPlayingThird, setPlayingThird] = useState(true);

  const thirdCircle = data.slice(0, 3);
  const secondCircle = data.slice(3, 12);
  const firstCircle = data.slice(12, data.length);

  return (
    <div className={style.container}>
      {firstCircle.map((item, index) => {
        return (
          <PartnerLogo
            index={index}
            isPlaying={isPlayingFirst}
            setPlaying={setPlayingFirst}
            itemStyle={itemStyle}
            item={item}
            key={index}
          />
        );
      })}

      <div className={style.container2}>
        {secondCircle.map((item, index) => {
          return (
            <PartnerLogo
              index={index}
              isPlaying={isPlayingSecond}
              setPlaying={setPlayingSecond}
              itemStyle={itemStyle2}
              item={item}
              key={index}
            />
          );
        })}
      </div>
      <div className={style.container3}>
        {thirdCircle.map((item, index) => {
          return (
            <PartnerLogo
              index={index}
              isPlaying={isPlayingThird}
              setPlaying={setPlayingThird}
              itemStyle={itemStyle3}
              item={item}
              key={index}
              inner
            />
          );
        })}
      </div>
    </div>
  );
};

export default PartnerOrbit;
