import React from "react";

export default function GoodHealth(props) {
  const { width = 72, height = 71, fill } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 72 71"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.801186 0.189941H71.0166V70.4053H0.801186V0.189941Z"
        fill="#48A928"
      />
      <path
        d="M7.20823 18.8591C6.64671 18.1543 6.36523 17.1564 6.36523 15.8677V15.4046H8.91086V15.851C8.92171 16.5341 9.0042 17.0247 9.15833 17.3221C9.31318 17.6195 9.59394 17.7679 10.0013 17.7679C10.4094 17.7679 10.6822 17.6166 10.8197 17.3134C10.9579 17.011 11.0267 16.5175 11.0267 15.8344C11.0267 15.118 10.9217 14.5862 10.7126 14.2396C10.5028 13.8923 10.1171 13.7135 9.55559 13.702C9.5223 13.6911 9.36817 13.6853 9.09248 13.6853V11.8177H9.50566C10.0787 11.8177 10.4753 11.6636 10.696 11.3553C10.916 11.0463 11.0267 10.5398 11.0267 9.8343C11.0267 9.26121 10.9463 8.83718 10.7872 8.56149C10.6272 8.28652 10.3429 8.14831 9.93548 8.14831C9.5498 8.14831 9.28496 8.29665 9.14241 8.59477C8.99914 8.89217 8.92171 9.33285 8.91086 9.91679V10.5116H6.36523V9.78437C6.36523 8.61648 6.67638 7.71343 7.2994 7.07376C7.9217 6.43482 8.80015 6.11499 9.93548 6.11499C11.0925 6.11499 11.9854 6.42397 12.6135 7.04048C13.2416 7.65843 13.5556 8.54484 13.5556 9.70188C13.5556 10.4732 13.3957 11.1209 13.0759 11.644C12.7561 12.1679 12.3487 12.4957 11.853 12.6274C12.9876 13.0464 13.5556 14.0985 13.5556 15.7845C13.5556 17.0739 13.2691 18.0848 12.696 18.8178C12.1229 19.5508 11.2141 19.917 9.96876 19.917C8.69016 19.917 7.77047 19.5646 7.20823 18.8591Z"
        fill="white"
      />
      <path
        d="M20.5593 11.4776C20.0759 11.4776 19.7279 11.3264 19.5144 11.0239C19.3017 10.7214 19.1953 10.2815 19.1953 9.70333V8.03688C19.1953 7.74309 19.2185 7.48911 19.2648 7.27637C19.3111 7.06363 19.3885 6.87622 19.4978 6.7163C19.607 6.55566 19.7568 6.43555 19.9486 6.35523C20.1396 6.27491 20.3741 6.23511 20.6519 6.23511C21.1577 6.23511 21.521 6.35884 21.7409 6.60632C21.9609 6.85451 22.0716 7.23006 22.0716 7.73441V7.98478H21.1794V7.71921C21.1794 7.60561 21.1772 7.51371 21.1715 7.44135C21.1664 7.36971 21.1541 7.29663 21.1346 7.22355C21.115 7.15119 21.0868 7.09475 21.0499 7.05567C21.013 7.0166 20.9623 6.98476 20.8987 6.96016C20.835 6.93555 20.7568 6.92325 20.6642 6.92325C20.594 6.92325 20.5318 6.93194 20.4775 6.9493C20.4233 6.96667 20.3769 6.98838 20.34 7.0137C20.3031 7.03975 20.272 7.07593 20.246 7.12369C20.2199 7.17072 20.2011 7.21703 20.1874 7.26117C20.1743 7.30531 20.1635 7.36248 20.1563 7.43194C20.149 7.50213 20.1447 7.56364 20.1425 7.61719C20.1403 7.67073 20.1396 7.73948 20.1396 7.82414V9.88857C20.1396 10.1968 20.178 10.4226 20.2539 10.5659C20.3299 10.7091 20.4746 10.7801 20.6888 10.7801C20.8003 10.7801 20.8929 10.7591 20.9681 10.7171C21.0434 10.6751 21.102 10.6115 21.144 10.5261C21.1859 10.4407 21.2163 10.3423 21.2337 10.2323C21.2511 10.123 21.2597 9.98915 21.2597 9.83286V9.41317H20.7076V8.81185H22.0991V11.4096H21.4942L21.4262 10.8915C21.272 11.2822 20.9833 11.4776 20.5593 11.4776Z"
        fill="white"
      />
      <path
        d="M24.1671 11.4716C23.6403 11.4716 23.2626 11.329 23.0346 11.0425C22.806 10.7566 22.6924 10.3384 22.6924 9.78702V7.89842C22.6924 7.62635 22.7177 7.39118 22.7691 7.19146C22.8205 6.99247 22.9037 6.81881 23.0173 6.67337C23.1316 6.5272 23.2843 6.41721 23.476 6.34485C23.6671 6.27177 23.8979 6.23486 24.1671 6.23486C24.6917 6.23486 25.0687 6.37597 25.2981 6.65745C25.5275 6.93965 25.6425 7.35283 25.6425 7.89842V9.78702C25.6425 10.054 25.6157 10.2892 25.5636 10.4918C25.5108 10.6944 25.4276 10.8703 25.3133 11.0193C25.1997 11.1684 25.047 11.2813 24.8552 11.3572C24.6642 11.4339 24.4348 11.4716 24.1671 11.4716ZM23.9341 10.7407C23.9992 10.7675 24.0766 10.7805 24.1671 10.7805C24.2575 10.7805 24.335 10.7675 24.3986 10.7407C24.4623 10.714 24.513 10.6785 24.5513 10.6351C24.5897 10.5924 24.6193 10.5359 24.641 10.4658C24.6628 10.3956 24.6765 10.3261 24.6837 10.2559C24.691 10.1857 24.6946 10.1025 24.6946 10.0063V7.68207C24.6946 7.55905 24.6888 7.4563 24.6765 7.37381C24.6642 7.29132 24.6396 7.2139 24.6041 7.14081C24.568 7.06773 24.5137 7.01346 24.4421 6.97728C24.3697 6.9411 24.2785 6.92301 24.1671 6.92301C24.0564 6.92301 23.9638 6.9411 23.8907 6.97728C23.8176 7.01346 23.764 7.06773 23.7286 7.14081C23.6939 7.2139 23.67 7.29132 23.6577 7.37381C23.6454 7.4563 23.6396 7.55905 23.6396 7.68207V10.0063C23.6396 10.1025 23.6432 10.1857 23.6504 10.2559C23.6577 10.3261 23.6721 10.3956 23.6931 10.4658C23.7148 10.5359 23.7445 10.5924 23.7814 10.6351C23.8183 10.6785 23.8697 10.714 23.9341 10.7407Z"
        fill="white"
      />
      <path
        d="M27.6864 11.4716C27.1596 11.4716 26.7819 11.329 26.5539 11.0425C26.3253 10.7566 26.2109 10.3384 26.2109 9.78702V7.89842C26.2109 7.62635 26.237 7.39118 26.2884 7.19146C26.3397 6.99247 26.423 6.81881 26.5366 6.67337C26.6509 6.5272 26.8036 6.41721 26.9953 6.34485C27.1864 6.27177 27.4172 6.23486 27.6864 6.23486C28.211 6.23486 28.588 6.37597 28.8173 6.65745C29.0467 6.93965 29.1618 7.35283 29.1618 7.89842V9.78702C29.1618 10.054 29.135 10.2892 29.0829 10.4918C29.0301 10.6944 28.9469 10.8703 28.8325 11.0193C28.7189 11.1684 28.5663 11.2813 28.3745 11.3572C28.1835 11.4339 27.9541 11.4716 27.6864 11.4716ZM27.4534 10.7407C27.5185 10.7675 27.5959 10.7805 27.6864 10.7805C27.7768 10.7805 27.8542 10.7675 27.9179 10.7407C27.9816 10.714 28.0322 10.6785 28.0706 10.6351C28.1082 10.5924 28.1386 10.5359 28.1603 10.4658C28.182 10.3956 28.1958 10.3261 28.203 10.2559C28.2102 10.1857 28.2139 10.1025 28.2139 10.0063V7.68207C28.2139 7.55905 28.2081 7.4563 28.1958 7.37381C28.1835 7.29132 28.1589 7.2139 28.1234 7.14081C28.0872 7.06773 28.033 7.01346 27.9613 6.97728C27.889 6.9411 27.7978 6.92301 27.6864 6.92301C27.5756 6.92301 27.483 6.9411 27.4099 6.97728C27.3369 7.01346 27.2833 7.06773 27.2479 7.14081C27.2131 7.2139 27.1892 7.29132 27.1769 7.37381C27.1646 7.4563 27.1589 7.55905 27.1589 7.68207V10.0063C27.1589 10.1025 27.1625 10.1857 27.1697 10.2559C27.1769 10.3261 27.1914 10.3956 27.2124 10.4658C27.2341 10.5359 27.2638 10.5924 27.3007 10.6351C27.3376 10.6785 27.389 10.714 27.4534 10.7407Z"
        fill="white"
      />
      <path
        d="M29.8105 11.4039V6.28735H31.0016C31.3106 6.28735 31.5689 6.31847 31.7766 6.37997C31.9842 6.4422 32.1507 6.53989 32.2766 6.67303C32.4018 6.8069 32.4908 6.97116 32.5429 7.16581C32.5957 7.35973 32.6217 7.59925 32.6217 7.8829V9.72809C32.6217 10.0204 32.595 10.2686 32.5414 10.4719C32.4879 10.676 32.4003 10.8482 32.278 10.99C32.155 11.1326 31.9922 11.2368 31.7889 11.3041C31.5848 11.3707 31.3352 11.4039 31.0385 11.4039H29.8105ZM30.7425 10.7498H31.0081C31.1007 10.7498 31.1818 10.7419 31.252 10.7252C31.3214 10.7086 31.38 10.689 31.4278 10.6666C31.4748 10.6442 31.5146 10.6065 31.5465 10.5552C31.5783 10.5038 31.6022 10.4568 31.6188 10.4133C31.6355 10.3699 31.6478 10.3048 31.6557 10.2172C31.6644 10.1297 31.6695 10.0552 31.6716 9.99365C31.6731 9.93214 31.6745 9.84024 31.6745 9.7194V7.84889C31.6745 7.72805 31.6724 7.62964 31.668 7.55583C31.6644 7.48202 31.6543 7.40749 31.6391 7.33224C31.6239 7.25698 31.6007 7.19909 31.5711 7.15785C31.5414 7.1166 31.5016 7.07898 31.4524 7.04497C31.4032 7.01096 31.341 6.98708 31.2672 6.97405C31.1933 6.9603 31.1036 6.95379 30.9987 6.95379H30.7425V10.7498Z"
        fill="white"
      />
      <path
        d="M34.7959 11.4039V6.28735H35.7279V8.42632H36.7677V6.28735H37.6997V11.4039H36.7677V9.08987H35.7279V11.4039H34.7959Z"
        fill="white"
      />
      <path
        d="M38.4424 11.4039V6.28735H40.5843V6.94511H39.3744V8.4133H40.331V9.07105H39.3744V10.7592H40.5994V11.4039H38.4424Z"
        fill="white"
      />
      <path
        d="M40.8408 11.4039L41.8806 6.28735H42.8524L43.8894 11.4039H43.0225L42.8155 10.2158H41.9298L41.72 11.4039H40.8408ZM42.029 9.60797H42.7142L42.3712 7.50662L42.029 9.60797Z"
        fill="white"
      />
      <path
        d="M44.3613 11.4042V6.2876H45.2933V10.7595H46.5184V11.4042H44.3613Z"
        fill="white"
      />
      <path
        d="M47.3367 11.4042V6.97574H46.4785V6.2876H49.0987V6.97574H48.2687V11.4042H47.3367Z"
        fill="white"
      />
      <path
        d="M49.4893 11.4039V6.28735H50.4213V8.42632H51.4611V6.28735H52.3931V11.4039H51.4611V9.08987H50.4213V11.4039H49.4893Z"
        fill="white"
      />
      <path
        d="M19.0068 19.7655L20.0467 14.6489H21.0185L22.0554 19.7655H21.1885L20.9816 18.5774H20.0959L19.886 19.7655H19.0068ZM20.195 17.9695H20.8802L20.5373 15.8682L20.195 17.9695Z"
        fill="white"
      />
      <path
        d="M22.543 19.7655V14.6489H23.1848L24.4374 17.5469V14.6489H25.2094V19.7655H24.598L23.3331 16.7199V19.7655H22.543Z"
        fill="white"
      />
      <path
        d="M25.9365 19.7655V14.6489H27.1276C27.4365 14.6489 27.6949 14.68 27.9025 14.7415C28.1102 14.8038 28.2766 14.9015 28.4026 15.0346C28.5277 15.1685 28.6167 15.3327 28.6688 15.5274C28.7217 15.7213 28.7477 15.9601 28.7477 16.2445V18.0897C28.7477 18.382 28.7209 18.6302 28.6674 18.8335C28.6138 19.0376 28.5263 19.2098 28.404 19.3516C28.2817 19.4942 28.1182 19.5984 27.9148 19.6657C27.7108 19.7322 27.4612 19.7655 27.1645 19.7655H25.9365ZM26.8685 19.1114H27.1341C27.2267 19.1114 27.3077 19.1034 27.3779 19.0868C27.4474 19.0701 27.506 19.0506 27.5538 19.0282C27.6008 19.0057 27.6406 18.9681 27.6724 18.9167C27.7043 18.8654 27.7282 18.8183 27.7448 18.7749C27.7614 18.7315 27.7737 18.6664 27.7817 18.5788C27.7904 18.4912 27.7955 18.4167 27.7976 18.3552C27.7991 18.2937 27.8005 18.2018 27.8005 18.081V16.2105C27.8005 16.0896 27.7983 15.9912 27.794 15.9174C27.7904 15.8436 27.7803 15.7683 27.7651 15.6938C27.7499 15.6186 27.7267 15.5607 27.697 15.5194C27.6674 15.4782 27.6276 15.4405 27.5784 15.4065C27.5292 15.3725 27.4669 15.3486 27.3931 15.3356C27.3193 15.3219 27.2296 15.3154 27.1247 15.3154H26.8685V19.1114Z"
        fill="white"
      />
      <path
        d="M31.462 19.7655L30.7305 14.6489H31.5149L31.9345 17.9478L32.4801 14.6489H33.1068L33.6654 17.9507L34.0851 14.6489H34.8536L34.122 19.7655H33.3781L32.8014 16.3494L32.2276 19.7655H31.462Z"
        fill="white"
      />
      <path
        d="M35.4209 19.7655V14.6489H37.5627V15.3067H36.3529V16.7749H37.3095V17.4326H36.3529V19.1208H37.5779V19.7655H35.4209Z"
        fill="white"
      />
      <path
        d="M38.0801 19.7655V14.6489H39.0121V19.1208H40.2371V19.7655H38.0801Z"
        fill="white"
      />
      <path
        d="M40.6934 19.7655V14.6489H41.6254V19.1208H42.8504V19.7655H40.6934Z"
        fill="white"
      />
      <path d="M44.8166 17.4536H43.292V18.0527H44.8166V17.4536Z" fill="white" />
      <path
        d="M45.5156 19.7655V14.6489H46.7682C47.0048 14.6489 47.2117 14.6699 47.3876 14.7126C47.5634 14.7546 47.7197 14.8248 47.8565 14.9224C47.9932 15.0201 48.0967 15.1554 48.1662 15.3277C48.2364 15.5006 48.2704 15.7097 48.2682 15.9543C48.266 16.586 48.0149 16.9391 47.5157 17.0129C47.8174 17.0708 48.0432 17.2025 48.1929 17.4095C48.342 17.6164 48.4173 17.9066 48.4194 18.2814C48.4216 18.7669 48.3 19.1353 48.0555 19.3878C47.8102 19.6396 47.4534 19.7655 46.9845 19.7655H45.5156ZM46.4476 16.7351H46.7378C47.0113 16.7351 47.1994 16.6765 47.3022 16.5592C47.4049 16.442 47.4563 16.2445 47.4563 15.9666C47.4563 15.8226 47.4397 15.7061 47.4057 15.6164C47.3717 15.5274 47.3145 15.4594 47.2342 15.4145C47.1546 15.3689 47.0656 15.3392 46.9679 15.3248C46.8702 15.3103 46.74 15.3031 46.5771 15.3031H46.4476V16.7351ZM46.4476 19.1085H46.7899C47.0677 19.1085 47.2602 19.0434 47.3673 18.9138C47.4744 18.7843 47.528 18.5701 47.528 18.272C47.528 17.963 47.4679 17.7394 47.3471 17.6005C47.2269 17.4623 47.0251 17.3928 46.7407 17.3928H46.4476V19.1085Z"
        fill="white"
      />
      <path
        d="M48.9941 19.7655V14.6489H51.136V15.3067H49.9261V16.7749H50.8827V17.4326H49.9261V19.1208H51.1512V19.7655H48.9941Z"
        fill="white"
      />
      <path
        d="M52.6004 14.6567H51.6807V19.7733H52.6004V14.6567Z"
        fill="white"
      />
      <path
        d="M53.3564 19.7655V14.6489H53.9983L55.2508 17.5469V14.6489H56.0229V19.7655H55.4115L54.1466 16.7199V19.7655H53.3564Z"
        fill="white"
      />
      <path
        d="M58.032 19.8399C57.5486 19.8399 57.2005 19.6887 56.9871 19.3862C56.7743 19.0837 56.668 18.6438 56.668 18.0656V16.3992C56.668 16.1054 56.6911 15.8514 56.7374 15.6387C56.7837 15.4252 56.8612 15.2385 56.9704 15.0786C57.0797 14.918 57.2295 14.7978 57.4212 14.7175C57.6123 14.6372 57.8467 14.5974 58.1246 14.5974C58.6304 14.5974 58.9936 14.7211 59.2136 14.9686C59.4336 15.2168 59.5443 15.5924 59.5443 16.0967V16.3471H58.6521V16.0815C58.6521 15.9679 58.6499 15.876 58.6441 15.8037C58.6391 15.732 58.6268 15.6589 58.6072 15.5859C58.5877 15.5135 58.5595 15.4571 58.5226 15.418C58.4857 15.3789 58.435 15.3471 58.3713 15.3225C58.3077 15.2979 58.2295 15.2856 58.1369 15.2856C58.0667 15.2856 58.0045 15.2942 57.9502 15.3116C57.8959 15.329 57.8496 15.3507 57.8127 15.376C57.7758 15.4021 57.7447 15.4382 57.7186 15.486C57.6926 15.533 57.6738 15.5793 57.66 15.6235C57.647 15.6676 57.6361 15.7248 57.6289 15.7942C57.6217 15.8644 57.6173 15.9259 57.6152 15.9795C57.613 16.033 57.6123 16.1018 57.6123 16.1864V18.2509C57.6123 18.5591 57.6506 18.7849 57.7266 18.9282C57.8026 19.0714 57.9473 19.1424 58.1615 19.1424C58.2729 19.1424 58.3655 19.1214 58.4408 19.0794C58.5161 19.0374 58.5747 18.9738 58.6166 18.8884C58.6586 18.8023 58.689 18.7046 58.7064 18.5946C58.7237 18.4846 58.7324 18.3515 58.7324 18.1952V17.7755H58.1803V17.1742H59.5718V19.7719H58.9669L58.8988 19.2538C58.7447 19.6445 58.456 19.8399 58.032 19.8399Z"
        fill="white"
      />
      <path
        d="M59.6083 44.107C59.6083 42.1222 58.3348 40.5129 56.4056 40.5129C55.4418 40.5129 54.2783 41.5694 53.6458 42.2192C53.0141 41.5687 51.928 40.5115 50.9634 40.5115C49.0343 40.5115 47.6826 42.1208 47.6826 44.1063C47.6826 45.0984 48.0734 45.9971 48.7058 46.6476L53.6502 51.6014L58.588 46.6534L58.5844 46.6498C59.2168 45.9993 59.6083 45.0998 59.6083 44.107Z"
        fill="white"
      />
      <path
        d="M38.5473 59.6994C38.5307 59.6994 38.514 59.6986 38.4966 59.6979C38.1211 59.6748 37.8107 59.3962 37.747 59.025L35.2354 44.3973L31.4307 51.451C31.2642 51.76 30.9162 51.9264 30.5739 51.8649C30.2288 51.8034 29.9625 51.5277 29.9126 51.1811L28.2975 39.8922L25.3423 46.5291C25.212 46.8221 24.9212 47.011 24.6006 47.011H13.1156C12.667 47.011 12.3037 46.647 12.3037 46.1991C12.3037 45.7505 12.667 45.3872 13.1156 45.3872H24.0731L27.9603 36.657C28.1072 36.3263 28.4552 36.1331 28.8141 36.183C29.1723 36.2329 29.4545 36.5137 29.5059 36.8719L31.1709 48.5125L34.9228 41.5558C35.087 41.2497 35.4271 41.0832 35.7694 41.1397C36.1109 41.1954 36.3794 41.4624 36.438 41.804L38.7637 55.3512L47.2407 27.193C47.3369 26.8724 47.6205 26.6438 47.9541 26.6177C48.2884 26.5931 48.6039 26.7726 48.7494 27.0743L53.2096 36.3212C53.4043 36.725 53.235 37.2105 52.8312 37.4052C52.4274 37.5998 51.9419 37.4305 51.7472 37.0267L48.1936 29.6597L39.3244 59.1212C39.221 59.4664 38.9033 59.6994 38.5473 59.6994Z"
        fill="white"
      />
    </svg>
  );
}
