import ClickMapElement from "components/elements/clickMapElement";
import Popover from "components/popover";
import StateMetrics from "components/stateMetrics";
import useOnTouchOutside from "hooks/useOnTouchOutside";
import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { getStateData } from "utils/api/map";
import { parseStateData } from "utils/mapState";
import NigerianMap from "./nigerianMap";

const NigerianMapWithPopper = ({ showClick }) => {
  const [mapState, setMapState] = useState([]);

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [selectedState, setSelectedState] = useState();

  const [popperElement, setPopperElement] = useState();
  const [referenceElement, setReferenceElement] = useState();
  const [arrowElement, setArrowReference] = useState();

  let mapRef = useRef();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto",
    // strategy: "fixed",
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      { name: "preventOverflow", options: { mainAxis: true, altAxis: true } },
      { name: "offset", options: { offset: [250, -90] } },
    ],
  });

  const handleShowData = (e, state) => {
    const selected = mapState.find((data) => {
      return data.stateId === state.country;
    });
    if (
      selected?.active &&
      (selected?.metrics?.length > 0 || selected?.sdgs?.length > 0)
    ) {
      setSelectedState(selected);
      setPopoverVisible(true);
      return;
    }
    if (popoverVisible) setPopoverVisible(false);
  };
  useEffect(() => {
    getStateData().then((data) => {
      if (data) {
        console.log(parseStateData(data?.data ?? []));
        setMapState(parseStateData(data?.data ?? []));
      }
    });
  }, []);
  const handleHideData = () => {
    setPopoverVisible(false);
    setSelectedState(null);
  };

  const multipleRefs = useRef({ mapRef, setReferenceElement });

  useOnTouchOutside({ ref: mapRef, handler: handleHideData });
  return (
    <div>
      {showClick && <ClickMapElement />}
      <NigerianMap
        ref={multipleRefs}
        mapData={mapState}
        onStateClick={(e, mapItem) => handleShowData(e, mapItem)}
      />
      <Popover
        style={styles.popper}
        ref={setPopperElement}
        active={popoverVisible}
        {...attributes.popper}
        onClose={handleHideData}
      >
        {/* wrapper to set width of state metrics */}
        <div className=" w-[352px]">
          <StateMetrics state={selectedState} />
        </div>
        <div style={styles.arrow} ref={setArrowReference} />
      </Popover>
    </div>
  );
};

export default NigerianMapWithPopper;
