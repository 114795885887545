import React from "react";

export default function LifeBelowWater(props) {
  const { width = 72, height = 71, fill = "none" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 72 71"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.888107 -0.000244141H71.1035V70.2152H0.888107V-0.000244141Z"
        fill="#48A928"
      />
      <path
        d="M6.90099 8.74901C6.77942 8.81486 6.42703 8.95307 5.84308 9.16219C5.3807 9.31704 5.08836 9.42124 4.9668 9.47623V7.57533C5.34162 7.43206 5.79604 7.21208 6.33079 6.91468C6.86481 6.61656 7.24253 6.34665 7.46322 6.10425H9.46326V19.4931H6.90099V8.74901Z"
        fill="white"
      />
      <path
        d="M14.6252 16.3232H10.5918V14.3066L14.0796 6.10815H16.9392V14.3891H18.2779V16.3232H16.9392V19.497H14.6252V16.3232ZM14.6252 14.3891V8.70299L12.5918 14.3891H14.6252Z"
        fill="white"
      />
      <path
        d="M23.3242 11.2199V6.10327H24.2562V10.5751H25.4813V11.2199H23.3242Z"
        fill="white"
      />
      <path d="M26.7303 6.0918H25.8105V11.2084H26.7303V6.0918Z" fill="white" />
      <path
        d="M27.3301 11.2199V6.10327H29.4314V6.74873H28.2621V8.23573H29.197V8.89277H28.2621V11.2199H27.3301Z"
        fill="white"
      />
      <path
        d="M29.7324 11.2199V6.10327H31.8743V6.76103H30.6644V8.22994H31.621V8.88698H30.6644V10.5751H31.8895V11.2199H29.7324Z"
        fill="white"
      />
      <path
        d="M23.3242 19.5819V14.4653H24.5775C24.8141 14.4653 25.0203 14.4863 25.1962 14.529C25.372 14.571 25.5283 14.6412 25.6651 14.7389C25.8018 14.8365 25.9053 14.9719 25.9748 15.1441C26.045 15.317 26.079 15.5261 26.0768 15.7707C26.0746 16.4024 25.8235 16.7555 25.3243 16.8293C25.6267 16.8872 25.8525 17.0189 26.0015 17.2259C26.1506 17.4328 26.2259 17.723 26.228 18.0978C26.2302 18.5834 26.1086 18.9517 25.8641 19.2042C25.6195 19.456 25.262 19.5819 24.7931 19.5819H23.3242ZM24.2562 16.5515H24.5464C24.8199 16.5515 25.008 16.4929 25.1108 16.3756C25.2143 16.2584 25.2656 16.0609 25.2656 15.783C25.2656 15.639 25.2483 15.5225 25.2143 15.4328C25.1803 15.3438 25.1231 15.2758 25.0435 15.2309C24.9632 15.1853 24.8742 15.1557 24.7765 15.1412C24.6788 15.1267 24.5486 15.1195 24.3857 15.1195H24.2562V16.5515ZM24.2562 18.9249H24.5985C24.8763 18.9249 25.0688 18.8598 25.1759 18.7303C25.283 18.6007 25.3366 18.3865 25.3366 18.0884C25.3366 17.7794 25.2765 17.5558 25.1557 17.4169C25.0355 17.2787 24.8337 17.2092 24.5493 17.2092H24.2562V18.9249Z"
        fill="white"
      />
      <path
        d="M26.6484 19.5819V14.4653H28.7903V15.1231H27.5804V16.5913H28.537V17.249H27.5804V18.9372H28.8055V19.5819H26.6484Z"
        fill="white"
      />
      <path
        d="M29.1484 19.5819V14.4653H30.0804V18.9372H31.3055V19.5819H29.1484Z"
        fill="white"
      />
      <path
        d="M32.9364 19.6369C32.4096 19.6369 32.0319 19.4943 31.8039 19.2078C31.5753 18.9219 31.4609 18.5037 31.4609 17.9523V16.0637C31.4609 15.7916 31.487 15.5565 31.5384 15.3567C31.5897 15.1578 31.673 14.9841 31.7866 14.8386C31.9009 14.6925 32.0536 14.5825 32.2453 14.5101C32.4364 14.4371 32.6672 14.4001 32.9364 14.4001C33.461 14.4001 33.838 14.5412 34.0673 14.8227C34.2967 15.1049 34.4118 15.5188 34.4118 16.0637V17.9523C34.4118 18.2193 34.385 18.4545 34.3329 18.6571C34.2801 18.8597 34.1969 19.0355 34.0825 19.1846C33.9689 19.3337 33.8163 19.4465 33.6245 19.5225C33.4335 19.5992 33.2041 19.6369 32.9364 19.6369ZM32.7034 18.906C32.7685 18.9328 32.8459 18.9458 32.9364 18.9458C33.0268 18.9458 33.1042 18.9328 33.1679 18.906C33.2316 18.8792 33.2822 18.8438 33.3206 18.8004C33.3582 18.7577 33.3886 18.7012 33.4103 18.6311C33.432 18.5609 33.4458 18.4914 33.453 18.4212C33.4602 18.351 33.4639 18.2678 33.4639 18.1716V15.8474C33.4639 15.7243 33.4581 15.6216 33.4458 15.5391C33.4335 15.4566 33.4089 15.3792 33.3734 15.3061C33.3372 15.233 33.283 15.1787 33.2113 15.1426C33.139 15.1064 33.0478 15.0883 32.9364 15.0883C32.8256 15.0883 32.733 15.1064 32.6599 15.1426C32.5869 15.1787 32.5333 15.233 32.4979 15.3061C32.4631 15.3792 32.4392 15.4566 32.4269 15.5391C32.4146 15.6216 32.4089 15.7243 32.4089 15.8474V18.1716C32.4089 18.2678 32.4125 18.351 32.4197 18.4212C32.4269 18.4914 32.4414 18.5609 32.4624 18.6311C32.4841 18.7012 32.5138 18.7577 32.5507 18.8004C32.5876 18.8438 32.639 18.8792 32.7034 18.906Z"
        fill="white"
      />
      <path
        d="M35.4679 19.5819L34.7363 14.4653H35.5207L35.9404 17.7642L36.486 14.4653H37.1126L37.6713 17.7671L38.0909 14.4653H38.8594L38.1278 19.5819H37.384L36.8073 16.1658L36.2335 19.5819H35.4679Z"
        fill="white"
      />
      <path
        d="M41.171 19.5819L40.4395 14.4653H41.2238L41.6435 17.7642L42.1891 14.4653H42.8158L43.3744 17.7671L43.7941 14.4653H44.5625L43.831 19.5819H43.0871L42.5104 16.1658L41.9366 19.5819H41.171Z"
        fill="white"
      />
      <path
        d="M44.5293 19.5819L45.5691 14.4653H46.5409L47.5778 19.5819H46.711L46.504 18.3938H45.6183L45.4085 19.5819H44.5293ZM45.7175 17.7859H46.4027L46.0597 15.6846L45.7175 17.7859Z"
        fill="white"
      />
      <path
        d="M48.2176 19.5819V15.1535H47.3594V14.4653H49.9795V15.1535H49.1496V19.5819H48.2176Z"
        fill="white"
      />
      <path
        d="M50.1953 19.5819V14.4653H52.3372V15.1231H51.1273V16.5913H52.0839V17.249H51.1273V18.9372H52.3524V19.5819H50.1953Z"
        fill="white"
      />
      <path
        d="M52.6992 19.5819V14.4653H53.8722C54.1414 14.4653 54.3729 14.4878 54.5661 14.5319C54.7593 14.576 54.9279 14.6499 55.0705 14.7541C55.2137 14.8582 55.3208 14.9986 55.3917 15.1766C55.4626 15.3546 55.4981 15.5724 55.4981 15.8293C55.4981 16.1506 55.4503 16.4126 55.3548 16.6159C55.2593 16.8199 55.1008 16.9683 54.8809 17.0609L55.5907 19.5819H54.6558L54.0444 17.2461H53.6312V19.5819H52.6992ZM53.6312 16.6499H53.8563C54.1319 16.6499 54.328 16.5913 54.4445 16.4741C54.5603 16.3576 54.6189 16.1564 54.6189 15.8727C54.6189 15.5992 54.5683 15.4031 54.4677 15.2852C54.3664 15.1665 54.1812 15.1072 53.912 15.1072H53.6312V16.6499Z"
        fill="white"
      />
      <path
        d="M54.8902 51.2998C54.0023 50.1008 48.2678 42.7896 40.2285 42.7896C33.2472 42.7896 25.7789 49.1218 25.7789 49.1218C25.7789 49.1218 22.9735 43.3793 17.2347 42.8279C17.2006 42.8279 17.1652 42.8207 17.1268 42.8207H17.0914C16.9713 42.8272 16.8591 42.9437 16.8721 43.0472C16.8888 43.1477 16.9619 43.2577 16.9619 43.2577C17.7021 44.4857 19.5893 47.9792 19.5893 51.4699C19.5893 54.9714 17.6905 58.4751 16.9575 59.6972L16.9083 59.7746C16.8866 59.8116 16.8721 59.8485 16.8721 59.8962C16.8721 60.0171 16.9662 60.1111 17.0841 60.122L17.1464 60.1169C17.1652 60.1169 17.1862 60.1169 17.2064 60.1111C22.9634 59.5749 25.7789 53.8151 25.7789 53.8151C25.7789 53.8151 33.2472 60.148 40.2285 60.148C48.2678 60.148 54.0023 52.8368 54.8902 51.6399C54.9148 51.6074 54.9343 51.5791 54.9517 51.5582C54.9618 51.5299 54.9705 51.5032 54.9705 51.4699C54.9705 51.4388 54.9618 51.4069 54.9517 51.3823C54.9343 51.3577 54.9148 51.3295 54.8902 51.2998ZM44.699 52.3939C43.403 52.3939 42.3523 51.3418 42.3523 50.0458C42.3523 48.7506 43.403 47.7014 44.699 47.7014C45.9978 47.7014 47.0478 48.7506 47.0478 50.0458C47.0478 51.3418 45.9978 52.3939 44.699 52.3939Z"
        fill="white"
      />
      <path
        d="M25.2429 36.2405C25.261 36.2145 25.2784 36.1949 25.3044 36.1768C25.342 36.1537 25.384 36.1341 25.431 36.1341C25.4904 36.1341 25.5475 36.158 25.5881 36.1978C25.5902 36.2022 25.5946 36.2051 25.5982 36.208C29.9811 40.386 34.5788 36.9996 35.5354 36.208L35.6288 36.1182C35.6563 36.1074 35.6852 36.1038 35.7156 36.1038C35.7489 36.1038 35.7807 36.1074 35.8082 36.1182L35.9023 36.208C36.8582 36.9996 41.4538 40.386 45.8381 36.208C45.8388 36.2051 45.8431 36.2022 45.8467 36.1978C45.8873 36.158 45.9408 36.1341 46.0016 36.1341C46.0501 36.1341 46.0935 36.1537 46.1289 36.1768C46.1564 36.1949 46.1745 36.2145 46.1941 36.2405C47.0436 37.0763 50.7137 40.237 54.5987 36.8621C54.6392 36.8201 54.6667 36.7658 54.6667 36.7V35.0951C54.6667 34.9677 54.564 34.8657 54.4373 34.8657C54.4207 34.8657 54.4055 34.8722 54.3881 34.8751C54.352 34.8924 54.3143 34.9127 54.2716 34.9337C53.0791 35.5321 49.375 36.9504 46.0161 33.7759C45.9849 33.7455 45.9517 33.7159 45.9184 33.6833C45.8873 33.6659 45.8583 33.6602 45.82 33.6602C45.7968 33.6602 45.7722 33.6659 45.7512 33.6775C45.7006 33.6905 45.6608 33.7224 45.634 33.7636C44.6333 34.5263 40.0362 37.6392 35.9421 33.8201L35.882 33.76C35.8393 33.7195 35.7807 33.6956 35.7156 33.6956C35.6563 33.6956 35.5962 33.7195 35.5557 33.76L35.4927 33.8201C31.4008 37.6392 26.7994 34.5263 25.7994 33.7636C25.7733 33.7224 25.7342 33.6905 25.6858 33.6775C25.6626 33.6659 25.6373 33.6602 25.6127 33.6602C25.5794 33.6602 25.5475 33.6659 25.5172 33.6833C25.4853 33.7159 25.4513 33.7455 25.4173 33.7759C22.0569 36.9504 18.3571 35.5321 17.1624 34.9337C17.1212 34.9127 17.0814 34.8924 17.0452 34.8751C17.0271 34.8722 17.0156 34.8657 17.0011 34.8657C16.8737 34.8657 16.7695 34.9677 16.7695 35.0951V36.7C16.7695 36.7658 16.7949 36.8201 16.8368 36.8621C20.7175 40.237 24.3927 37.0763 25.2429 36.2405Z"
        fill="white"
      />
      <path
        d="M17.0504 29.0499C17.033 29.0463 17.0207 29.0405 17.0062 29.0405C16.8789 29.0405 16.7754 29.144 16.7754 29.2735V30.8734C16.7754 30.94 16.8007 30.9993 16.8427 31.0391C20.7234 34.4111 24.3978 31.2511 25.2488 30.4168C25.2661 30.3915 25.2842 30.3669 25.3096 30.3517C25.3472 30.3271 25.3891 30.3097 25.4362 30.3097C25.4962 30.3097 25.5527 30.3329 25.5925 30.3712C25.5961 30.3755 25.6004 30.3792 25.6041 30.3813C29.9876 34.5652 34.584 31.1766 35.5406 30.3813L35.6346 30.2952C35.6614 30.2829 35.6903 30.2779 35.7222 30.2779C35.7547 30.2779 35.7873 30.2829 35.8134 30.2952L35.9074 30.3813C36.8633 31.1766 41.4603 34.5652 45.8432 30.3813C45.8446 30.3792 45.8497 30.3755 45.8519 30.3712C45.8931 30.3329 45.9474 30.3097 46.0082 30.3097C46.0552 30.3097 46.0994 30.3271 46.1348 30.3517C46.1616 30.3669 46.1797 30.3915 46.1999 30.4168C47.0494 31.2511 50.7203 34.4111 54.6038 31.0391C54.6444 30.9993 54.6711 30.94 54.6711 30.8734V29.2735C54.6711 29.144 54.5706 29.0405 54.4432 29.0405C54.4266 29.0405 54.4114 29.0463 54.3933 29.0499C54.3578 29.0702 54.3202 29.0897 54.2768 29.11C53.0836 29.7084 49.3816 31.1303 46.0212 27.9508C45.9901 27.9218 45.9568 27.8921 45.9242 27.8581C45.8931 27.8451 45.8635 27.8364 45.8258 27.8364C45.8027 27.8364 45.7774 27.8437 45.7564 27.8473C45.7064 27.8675 45.6666 27.8994 45.6399 27.9385C44.6377 28.7019 40.0414 31.8133 35.9479 27.9949L35.8879 27.9363C35.8452 27.8987 35.7873 27.8675 35.7222 27.8675C35.6614 27.8675 35.6013 27.8987 35.5615 27.9363L35.4979 27.9949C31.4066 31.8133 26.8052 28.7019 25.8052 27.9385C25.7792 27.8994 25.7401 27.8675 25.6909 27.8473C25.6677 27.8437 25.6439 27.8364 25.6178 27.8364C25.5852 27.8364 25.5527 27.8451 25.5216 27.8581C25.4919 27.8921 25.4564 27.9218 25.4232 27.9508C22.0635 31.1303 18.363 29.7084 17.1683 29.11C17.1263 29.0897 17.0865 29.0702 17.0504 29.0499Z"
        fill="white"
      />
    </svg>
  );
}
