import React from "react";
// import { SALOME_TESTIMONIAL_IMAGE } from "assets/jpg";
// import { SANUSI_TESTIMONIAL_IMAGE, YUSUF_TESTIMONIAL_IMAGE } from "assets/png";
import Container from "components/sections/container";
import { NextButton, PrevButton } from "components/multiSlider";
import TestimonialSlide from "components/elements/testimonalSlideElement";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const TestimonialSection = ({ data }) => {
  // return the full slider
  return (
    <Container>
      <div className="py-28">
        <Carousel
          infiniteLoop
          swipeable={false}
          renderArrowNext={(clickHandler, hasNext, label) => (
            <NextButton
              onClick={clickHandler}
              ariaLabel={label}
              disabled={!hasNext}
            />
          )}
          renderArrowPrev={(clickHandler, hasPrev, label) => (
            <PrevButton
              onClick={clickHandler}
              ariaLabel={label}
              disabled={!hasPrev}
            />
          )}
          renderArrowsWhenDisabled
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
        >
          {data?.testimonial &&
            data.testimonial.map((testimonial, testimonialIndex) => {
              return (
                <div
                  key={`testimonial-${testimonialIndex}`}
                  className="relative"
                >
                  <TestimonialSlide data={testimonial} />
                </div>
              );
            })}
        </Carousel>
      </div>
    </Container>
  );
};

export default TestimonialSection;
