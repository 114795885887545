import PartnerOrbit from "../elements/partnerOrbit";
import Container from "components/sections/container";
import React from "react";

const PartnerStories = ({ data }) => {
  return (
    <Container className="my-12">
      <div className="flex flex-col items-center mt-8">
        <h3 className="font-bold leading-9 text-h3">{data?.title}</h3>
      </div>
      <div className="mt-6 text-lg">
        <p>{data?.description}</p>
        <br />
        {data?.joinUsLink && (
          <a
            href={data?.joinUsLink?.url}
            className="font-semibold text-lg"
            target="_blank"
            rel="noopener noreferrer"
          >
            {data?.joinUsLink?.title}
          </a>
        )}
        &nbsp;
        <span className="text-lg">So far, &nbsp;we've worked work with:</span>
        <br />
        {/* <p className="leading-[26px] text-lg font-normal">
          <b>Click each logo for more details on our partnerships </b>
        </p> */}
        <br />
      </div>
      <div className="flex flex-col items-center justify-center">
        {data.logos && <PartnerOrbit data={data?.logos} />}
      </div>
    </Container>
  );
};

export default PartnerStories;
