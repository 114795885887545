import React from "react";
import styles from "../../styles/accordion.module.css";
import classNames from "classnames";
import { ChevronDown } from "react-feather";

const AccordionTab = (props) => {
  const {
    title,
    textContent,
    index,
    currentImage,
    onClick = () => null,
    isActive,
  } = props;

  let accordionTabClassNames = classNames(styles.accordionTab, {
    [`${styles.active}`]: isActive,
  });
  let accordionTabArrowClassNames = classNames({
    [`${styles.arrow_active}`]: isActive,
  });

  return (
    <>
      <li className={accordionTabClassNames}>
        <button
          className={styles.accordionTabButton}
          onClick={() => {
            onClick(index);
          }}
        >
          <h3 className={styles.accordionTabHeader}>{title}</h3>
          <ChevronDown
            className={accordionTabArrowClassNames}
            size={isActive ? 22 : 18}
          />
        </button>
        <p className={styles.accordionTabTextContent}>{textContent}</p>

        <div className="w-full flex justify-center my-2">
          {/* eslint-disable-next-line @next/next/no-img-element*/}
          <img
            src={currentImage}
            alt="accordionImage"
            className={`${
              isActive ? "flex" : "hidden"
            } h-[300px] w-auto md:hidden`}
          />
        </div>
      </li>
    </>
  );
};

export default AccordionTab;
