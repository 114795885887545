import Container from "components/sections/container";
import React from "react";
import Image from "next/image";
import Slider from "components/multiSlider";
import { FMN } from "assets/svg";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 678, min: 330 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

// const partnersListData = [
//   {
//     icon: KOBOICON,
//   },
//   { icon: WFPICON },
//   { icon: WACOTICON },
//   { icon: STERLINGICON },
//   { icon: CBNICON },
//   { icon: TAKLOGISTICSICON },
//   { icon: SYNGENTA },
//   { icon: ROMOIL },
//   { icon: PREMIER_FEEDS },
//   { icon: NOTORE },
//   { icon: KAMTRAC },
//   { icon: INDORAMA },
//   { icon: FMN },
//   { icon: DIAGEO },
//   { icon: USAID_TRADE },
//   { icon: AMO_BYNG },
//   { icon: AFEX },
//   { icon: TECHNOSERVE },
//   { icon: OCP },
//   { icon: LEADWAY },
// ];

const PartnersList = ({ data }) => {
  return (
    <Container
      style={{
        backgroundImage: `url(/images/svg/machine-icon.svg)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
      }}
    >
      <div className="py-8">
        <h3 className="font-bold text-center text-h3">
          {data?.title ?? "Our Partners"}
        </h3>
        <div className="my-20 block">
          <Slider
            swipeable={false}
            draggable={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            arrows={true}
            autoPlay
            renderButtonGroupOutside={false}
            containerClass="md:mx-16 py-8"
            removeArrowOnDeviceType={["mobile"]}
          >
            {data?.Logos &&
              data?.Logos?.map((partner, partnerIndex) => (
                <div
                  key={`partner-${partnerIndex}`}
                  className="flex justify-center"
                >
                  <Image
                    src={partner?.Logo?.data?.attributes.url ?? FMN}
                    width={100}
                    height={79.59}
                    alt=""
                  />
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </Container>
  );
};

export default PartnersList;
