import Image from "next/image";
import React from "react";

const icons = {
  noPoverty: "/images/svg/no-poverty-icon.svg",
  zeroHunger: "/images/svg/zero_hunger_icon.svg",
  genderEquality: "/images/svg/gender-equality-icon.svg",
  decentWork: "/images/svg/eco-growth-icon.svg",
  industryAndInnovation: "/images/svg/industry-icon.svg",
  responsibleConsumption: "/images/svg/resp-consumption-icon.svg",
  money: "/images/svg/green-money-icon.svg",
  tractor: "/images/svg/green-tractor-icon.svg",
  house: "/images/svg/green-house-icon.svg",
};
//todo: flex-row component
const ImageText = ({ data }) => {
  return (
    <div className="flex gap-5 items-center">
      <Image
        src={icons[data.icons] ?? icons["noPoverty"]}
        width={data.width ?? 39.5}
        height={data.height ?? 40.81}
        alt=""
        layout="fixed"
      />
      <p className="w-4/5 text-lg">{data.text}</p>
    </div>
  );
};

export default ImageText;
