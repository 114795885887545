import React from "react";

const SquareButton = ({ value, onClick, className }) => {
  return (
    <button
      className={`inline-block p-2 px-4 capitalize font-semibold ${className}`}
      onClick={onClick}
    >
      {value}
    </button>
  );
};

export default SquareButton;
