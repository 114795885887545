import React from "react";
import SquareButton from "./squareButton";

const PaginationButton = ({ value, onClick, active }) => {
  return (
    <SquareButton
      className={` ${
        active && "bg-primary text-white"
      } hover:bg-primary hover:text-white my-2 px-3 rounded `}
      onClick={onClick}
      value={value}
    />
  );
};

export default PaginationButton;
