import React from "react";
//import Section from "components/sections/sections";
const ResourceHeader = ({ data }) => {
  return (
    <>
      <div className="px-4 py-7">
        <h3 className="font-bold text-center text-h3">
          {data?.title ?? "resources"}
        </h3>
      </div>
      <div className="py-5">
        <p className="text-lg font-normal">
          {data?.description ??
            "Discover all our latest news, stories and publications. Use the filter to get to the content you're looking for."}
        </p>
      </div>
    </>
  );
};

export default ResourceHeader;
