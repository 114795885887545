import React from "react";

const ZeroHunger = (props) => {
  const { fill, width = 37, height = 43 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 43"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9.34607L2.47155 5.52474L2.62282 5.29784C2.94246 4.81866 3.17763 4.41731 3.3289 4.09381C3.48071 3.7703 3.55634 3.41478 3.55634 3.02834C3.55634 2.70042 3.49286 2.45034 3.36699 2.2781C3.24057 2.10585 3.06005 2.01973 2.82487 2.01973C2.47155 2.01973 2.22755 2.15388 2.0934 2.42273C1.95869 2.69214 1.89134 3.06202 1.89134 3.53237V3.99941H0V3.49483C0 2.53646 0.224687 1.77793 0.674612 1.2187C1.12399 0.659467 1.82841 0.380127 2.78678 0.380127C3.66952 0.380127 4.33971 0.613095 4.79847 1.08013C5.25613 1.54662 5.48578 2.20854 5.48578 3.06588C5.48578 3.45287 5.42671 3.81888 5.30912 4.16337C5.19098 4.50785 5.05242 4.81479 4.89287 5.08364C4.73333 5.35305 4.4849 5.73507 4.1487 6.23137L2.18173 9.18211H5.22079V10.7207H0V9.34607Z"
        fill="currentColor"
      />
      <path
        d="M9.65283 4.41238V3.94644L10.6885 1.00122H9.70473V0.508789H11.4161V0.859345L10.3308 3.9205H11.4183V4.41238H9.65283Z"
        fill="currentColor"
      />
      <path
        d="M11.7993 4.41238V0.508789H13.4334V1.01061H12.5104V2.13073H13.2402V2.63255H12.5104V3.9205H13.445V4.41238H11.7993Z"
        fill="currentColor"
      />
      <path
        d="M13.8306 4.41238V0.508789H14.7254C14.9308 0.508789 15.1075 0.525903 15.2549 0.559578C15.4023 0.593254 15.5309 0.649563 15.6396 0.72906C15.749 0.808556 15.8307 0.915655 15.8848 1.05146C15.9389 1.18727 15.9659 1.35344 15.9659 1.54942C15.9659 1.79453 15.9295 1.99437 15.8566 2.1495C15.7837 2.30518 15.6628 2.41835 15.495 2.48902L16.0366 4.41238H15.3233L14.8568 2.63034H14.5416V4.41238H13.8306ZM14.5416 2.17545H14.7133C14.9236 2.17545 15.0732 2.13073 15.1621 2.0413C15.2505 1.95242 15.2952 1.79895 15.2952 1.58254C15.2952 1.37386 15.2565 1.22425 15.1798 1.13427C15.1025 1.04373 14.9612 0.998463 14.7558 0.998463H14.5416V2.17545Z"
        fill="currentColor"
      />
      <path
        d="M17.5426 4.45128C17.1407 4.45128 16.8526 4.34253 16.6787 4.12391C16.5042 3.90585 16.417 3.58676 16.417 3.1661V1.72523C16.417 1.51766 16.4369 1.33824 16.4761 1.18587C16.5153 1.03406 16.5787 0.901564 16.6654 0.790601C16.7526 0.679085 16.8691 0.595173 17.0154 0.539967C17.1612 0.48421 17.3373 0.456055 17.5426 0.456055C17.9429 0.456055 18.2305 0.563706 18.4055 0.778455C18.5805 0.993757 18.6683 1.30898 18.6683 1.72523V3.1661C18.6683 3.36981 18.6479 3.54922 18.6081 3.7038C18.5678 3.85838 18.5043 3.99253 18.4171 4.10625C18.3299 4.21997 18.2139 4.30609 18.0676 4.36406C17.9219 4.42258 17.7469 4.45128 17.5426 4.45128ZM17.3649 3.89371C17.4146 3.91413 17.4736 3.92407 17.5426 3.92407C17.6116 3.92407 17.6707 3.91413 17.7193 3.89371C17.7679 3.87328 17.8065 3.84623 17.8358 3.81311C17.8645 3.78054 17.8877 3.73748 17.9042 3.68393C17.9208 3.63038 17.9313 3.57738 17.9368 3.52383C17.9423 3.47028 17.9451 3.40679 17.9451 3.33337V1.56017C17.9451 1.46632 17.9407 1.38793 17.9313 1.32499C17.9219 1.26206 17.9031 1.20299 17.8755 1.14723C17.8485 1.09147 17.8071 1.05007 17.7524 1.02246C17.6972 0.994861 17.6277 0.98106 17.5426 0.98106C17.4582 0.98106 17.3875 0.994861 17.3317 1.02246C17.276 1.05007 17.2351 1.09147 17.2081 1.14723C17.1816 1.20299 17.1634 1.26206 17.154 1.32499C17.1446 1.38793 17.1402 1.46632 17.1402 1.56017V3.33337C17.1402 3.40679 17.1429 3.47028 17.1485 3.52383C17.154 3.57738 17.165 3.63038 17.181 3.68393C17.1976 3.73748 17.2202 3.78054 17.2484 3.81311C17.2765 3.84623 17.3157 3.87328 17.3649 3.89371Z"
        fill="currentColor"
      />
      <path
        d="M9.78223 10.7832V6.87964H10.4933V8.51151H11.2866V6.87964H11.9976V10.7832H11.2866V9.01775H10.4933V10.7832H9.78223Z"
        fill="currentColor"
      />
      <path
        d="M13.6334 10.8301C13.4589 10.8301 13.3082 10.8097 13.1801 10.7689C13.0521 10.728 12.9472 10.6712 12.8649 10.5972C12.7821 10.5232 12.7164 10.4288 12.6667 10.3134C12.6176 10.198 12.5828 10.0733 12.5635 9.93913C12.5436 9.80498 12.5337 9.6493 12.5337 9.47154V6.87964H13.2238V9.54496C13.2238 9.63605 13.226 9.71334 13.2309 9.77793C13.2354 9.84197 13.2458 9.90987 13.2613 9.98053C13.2773 10.0512 13.2994 10.1075 13.3287 10.15C13.3574 10.192 13.3977 10.2273 13.4484 10.256C13.4998 10.2842 13.5611 10.298 13.6334 10.298C13.7057 10.298 13.767 10.2842 13.8183 10.256C13.8691 10.2273 13.9083 10.192 13.9359 10.15C13.9635 10.1075 13.9856 10.0512 14.0022 9.98053C14.0182 9.90987 14.0287 9.84252 14.0325 9.77903C14.0364 9.71555 14.0386 9.63771 14.0386 9.54496V6.87964H14.7281V9.47154C14.7281 9.6493 14.7182 9.80498 14.6989 9.93913C14.679 10.0733 14.6448 10.198 14.5951 10.3134C14.5459 10.4288 14.4802 10.5232 14.3985 10.5972C14.3168 10.6712 14.2119 10.728 14.0844 10.7689C13.9563 10.8097 13.8062 10.8301 13.6334 10.8301Z"
        fill="currentColor"
      />
      <path
        d="M15.2573 10.7923V6.88867H15.747L16.7032 9.09966V6.88867H17.2916V10.7923H16.8252L15.8602 8.46866V10.7923H15.2573Z"
        fill="currentColor"
      />
      <path
        d="M18.8277 10.8356C18.459 10.8356 18.1934 10.7202 18.0306 10.4894C17.8683 10.2587 17.7871 9.92303 17.7871 9.48194V8.21056C17.7871 7.98642 17.8048 7.79265 17.8401 7.63035C17.8754 7.46749 17.9345 7.32506 18.0179 7.20305C18.1012 7.0805 18.2155 6.98886 18.3618 6.92758C18.5075 6.8663 18.6864 6.83594 18.8984 6.83594C19.2843 6.83594 19.5614 6.93034 19.7292 7.11914C19.8971 7.3085 19.9815 7.59501 19.9815 7.9798V8.17081H19.3008V7.9682C19.3008 7.88153 19.2992 7.81142 19.2948 7.75621C19.2909 7.70156 19.2815 7.6458 19.2666 7.59005C19.2517 7.53484 19.2302 7.49178 19.202 7.46197C19.1739 7.43216 19.1352 7.40787 19.0866 7.3891C19.0381 7.37033 18.9784 7.36094 18.9078 7.36094C18.8542 7.36094 18.8068 7.36757 18.7654 7.38082C18.7239 7.39407 18.6886 7.41063 18.6605 7.42995C18.6323 7.44982 18.6086 7.47743 18.5887 7.51386C18.5688 7.54975 18.5545 7.58508 18.544 7.61875C18.534 7.65243 18.5258 7.69604 18.5202 7.74904C18.5147 7.80259 18.5114 7.84951 18.5098 7.89036C18.5081 7.93122 18.5075 7.98366 18.5075 8.04825V9.62327C18.5075 9.85844 18.5368 10.0307 18.5948 10.14C18.6527 10.2493 18.7631 10.3034 18.9266 10.3034C19.0116 10.3034 19.0822 10.2874 19.1396 10.2554C19.1971 10.2234 19.2418 10.1748 19.2738 10.1096C19.3058 10.0439 19.329 9.96941 19.3423 9.88549C19.3555 9.80158 19.3621 9.7 19.3621 9.58076V9.26057H18.9409V8.80181H20.0025V10.7837H19.541L19.4891 10.3884C19.3715 10.6865 19.1512 10.8356 18.8277 10.8356Z"
        fill="currentColor"
      />
      <path
        d="M20.5156 10.7923V6.88867H22.1497V7.39049H21.2267V8.51061H21.9565V9.01243H21.2267V10.3004H22.1613V10.7923H20.5156Z"
        fill="currentColor"
      />
      <path
        d="M22.5469 10.7923V6.88867H23.4418C23.6471 6.88867 23.8238 6.90579 23.9712 6.93946C24.1186 6.97314 24.2472 7.02945 24.3565 7.10894C24.4653 7.18844 24.547 7.29554 24.6011 7.43134C24.6552 7.56715 24.6822 7.73332 24.6822 7.9293C24.6822 8.17441 24.6458 8.37426 24.5729 8.52938C24.5 8.68506 24.3791 8.79824 24.2113 8.8689L24.7529 10.7923H24.0396L23.5737 9.01022H23.2579V10.7923H22.5469ZM23.2579 8.55533H23.4296C23.6399 8.55533 23.7895 8.51061 23.8784 8.42118C23.9668 8.3323 24.0115 8.17883 24.0115 7.96242C24.0115 7.75374 23.9728 7.60414 23.8961 7.51415C23.8188 7.42361 23.678 7.37835 23.4721 7.37835H23.2579V8.55533Z"
        fill="currentColor"
      />
      <path
        d="M17.0228 41.4519V42.2087C17.0222 42.2137 17.02 42.2198 17.02 42.2253C17.02 42.2319 17.0222 42.238 17.0228 42.2435C17.0305 42.4307 17.1834 42.5797 17.3717 42.5836C17.3744 42.5836 17.3761 42.5847 17.3788 42.5847C17.3816 42.5847 17.3849 42.5836 17.3877 42.5836H26.2647C26.2669 42.5836 26.2697 42.5847 26.2725 42.5847C26.2747 42.5847 26.2774 42.5836 26.2802 42.5836C26.474 42.5797 26.6285 42.4224 26.6302 42.2281L26.6313 42.2253C26.6313 42.2253 26.6302 42.2242 26.6302 42.2231V41.3668C31.6274 39.7283 35.3709 35.6785 36.1946 30.7425C36.1962 30.732 36.1979 30.7221 36.199 30.7127C36.2023 30.6956 36.2039 30.6801 36.2039 30.663C36.2039 30.4648 36.0444 30.3042 35.8451 30.3042H7.55281C7.35297 30.3042 7.19287 30.4648 7.19287 30.663L7.21109 30.8248C8.07616 35.8076 11.9223 39.8802 17.0228 41.4519Z"
        fill="currentColor"
      />
      <path
        d="M26.6223 18.2291C26.5059 18.3952 23.7721 22.3303 26.6836 25.5123C28.0185 26.9731 27.9026 28.2616 27.5216 29.1322H29.3887C29.7668 27.847 29.6112 26.2355 28.0947 24.5788C25.9499 22.2342 28.1223 19.0643 28.1548 19.0157C28.411 18.6541 28.2741 18.1882 27.8518 17.9707C27.4283 17.7543 26.8796 17.8697 26.6223 18.2291Z"
        fill="currentColor"
      />
      <path
        d="M21.8919 29.1326H23.7601C24.1377 27.8475 23.9809 26.236 22.465 24.5793C20.3191 22.2342 22.4898 19.0665 22.524 19.0146C22.7791 18.6546 22.6438 18.1892 22.2204 17.9712C21.7986 17.7553 21.2488 17.8702 20.9926 18.2295C20.875 18.3952 18.1396 22.3308 21.0534 25.5128C22.3882 26.9741 22.2723 28.2631 21.8919 29.1326Z"
        fill="currentColor"
      />
      <path
        d="M15.3635 18.2292C15.2454 18.3954 12.5116 22.3304 15.4237 25.5125C16.7591 26.9737 16.6437 28.2628 16.2634 29.1323H18.131C18.5086 27.8476 18.3523 26.2362 16.8353 24.5789C14.6895 22.2343 16.8618 19.0644 16.896 19.0142C17.15 18.6543 17.0147 18.1883 16.5913 17.9708C16.169 17.7533 15.6197 17.8692 15.3635 18.2292Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ZeroHunger;
