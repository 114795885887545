import VideoEmbed from "components/videoEmbed";
import React, { useState, useCallback } from "react";
import Slider from "components/multiSlider";
import IndividualFarmerStory from "./individualFarmerStory";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 678, min: 330 },
    items: 3,
  },
};

const FarmerStoryElement = ({ data }) => {
  const memorizedCall = useCallback(() => {
    return data.map((item) => {
      return item.url.split("/").pop();
    });
  }, [data]);
  const arrayOfembedId = memorizedCall();
  const [currentStory, setCurrentStory] = useState(arrayOfembedId[0]);
  return (
    <div>
      <div className="mx-auto  max-w-[1305px] w-4/5">
        <div className="flex flex-row mt-[70px] justify-center">
          <VideoEmbed responsive embedId={currentStory} />
        </div>
      </div>
      <div className="mx-4">
        {arrayOfembedId && (
          <Slider
            swipeable={true}
            draggable={true}
            responsive={responsive}
            ssr={true}
            infinite={false}
            renderButtonGroupOutside={false}
            containerClass="md:mx-16 py-8 overflow-hidden"
          >
            {arrayOfembedId.map((embedId, index) => {
              return (
                <IndividualFarmerStory
                  onClick={setCurrentStory}
                  embedId={embedId}
                  active={currentStory === embedId}
                  key={`farmer-story-thumb-${index}`}
                />
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
};
export default FarmerStoryElement;
