import { fetchAPI } from ".";

export const getStateData = async () => {
  try {
    const states = await fetchAPI(`/states`, {
      populate: {
        active: "*",
        state: "*",
        stateName: "*",
        sdg: "*",
        metricCard: "*",
      },
      fields: "*",
      pagination: {
        page: 1,
        pageSize: 37,
      },
    });
    //console.log("blogRes",blogsRes)
    // printObj(blogs, "blogs obj from getHectareBlogs()");
    return states;
  } catch (err) {
    console.log(err);
  }
};
