import React from "react";
import Image from "next/image";
import ImageText from "components/elements/theoryOfChange/imageText";

export const PointDown = ({ data }) => {
  return (
    <>
      <div>
        <h4 className="text-h4 text-thrive-india-green font-demibold">
          {data.title}
        </h4>
        <div className="pt-5">
          <Image
            src={"/images/svg/green-down-arrow-icon.svg"}
            width={26}
            height={27}
            layout="fixed"
            alt=""
            className="animate-bounce"
          />
          {(data.descriptionBold || data.descriptionMain) && (
            <div className="text-lg pt-5">
              <p>
                <span className="font-bold">{data.descriptionBold}</span>{" "}
                {data.descriptionMain}
              </p>
            </div>
          )}
          <div className="flex flex-col gap-5 pt-5">
            {data.iconText &&
              data.iconText.map((item, index) => {
                return <ImageText data={item} key={index} />;
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export const PointRight = (props) => {
  const { data } = props;

  return (
    <>
      <span className="mt-10">
        <Image
          src={"/images/svg/green-right-arrow-icon.svg"}
          width={27.43}
          height={27.43}
          layout="fixed"
          alt=""
          className="animate-bounce-vertical"
        />
      </span>
      <div>
        <h4 className="text-h4 font-demibold text-thrive-india-green">
          {data?.title}
        </h4>
        <div className="pt-5">
          {(data?.descriptionBold || data?.descriptionMain) && (
            <p className="text-lg">
              <span className="font-semibold">{data?.descriptionBold} </span>
              {data?.descriptionMain}
            </p>
          )}
        </div>

        <div className="flex flex-col gap-5 mt-5">
          {data?.iconText &&
            data?.iconText?.map((item, index) => {
              return <ImageText data={item} key={index} />;
            })}
        </div>
      </div>
    </>
  );
};
