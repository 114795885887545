import React from "react";

const IndustryAndInnovation = (props) => {
  const { fill, width = 47, height = 44 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 47 44"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.775637 10.21C0.376501 9.77722 0.172241 9.12359 0.16396 8.24913V8.09787H2.00506C2.00506 8.55994 2.05364 8.88786 2.15025 9.08108C2.24686 9.27485 2.44671 9.37146 2.74923 9.37146C2.95901 9.37146 3.1169 9.30853 3.22234 9.18211C3.32723 9.05624 3.40066 8.84811 3.44261 8.55828C3.48457 8.2679 3.50555 7.84999 3.50555 7.30291V6.26946C3.39624 6.4544 3.224 6.59904 2.98882 6.70448C2.75365 6.80937 2.47983 6.86181 2.16902 6.86181C1.41271 6.86181 0.861758 6.58413 0.517275 6.02931C0.172241 5.4745 0 4.73916 0 3.82275C0 1.52785 0.916411 0.380127 2.74923 0.380127C3.69049 0.380127 4.38442 0.669956 4.82993 1.25017C5.27544 1.83038 5.49847 2.6292 5.49847 3.64609V7.36639C5.49847 8.46774 5.29421 9.32509 4.88679 9.93897C4.47882 10.5523 3.76225 10.8593 2.73654 10.8593C1.82841 10.8593 1.17477 10.6428 0.775637 10.21ZM3.50555 4.90698V3.28063C3.50555 2.83512 3.45089 2.4818 3.34214 2.22123C3.23228 1.96066 3.0352 1.83038 2.74923 1.83038C2.44671 1.83038 2.24465 1.97115 2.14418 2.2527C2.04315 2.53425 1.99292 2.98197 1.99292 3.59585C1.99292 4.03308 2.00506 4.36707 2.03046 4.59839C2.05585 4.8297 2.12486 5.01629 2.23858 5.15927C2.35175 5.30281 2.5262 5.37402 2.76193 5.37402C3.07274 5.37402 3.32061 5.21834 3.50555 4.90698Z"
        fill="currentColor"
      />
      <path
        d="M8.47071 0.431152H7.76904V4.33475H8.47071V0.431152Z"
        fill="currentColor"
      />
      <path
        d="M8.82129 4.34231V0.438721H9.31096L10.2671 2.6497V0.438721H10.8556V4.34231H10.3891L9.42413 2.0187V4.34231H8.82129Z"
        fill="currentColor"
      />
      <path
        d="M11.188 4.34231V0.438721H12.0967C12.3324 0.438721 12.5295 0.462459 12.6879 0.509384C12.8464 0.556309 12.9733 0.631388 13.0694 0.732966C13.1649 0.835097 13.2328 0.960413 13.2726 1.10892C13.3129 1.25687 13.3327 1.43905 13.3327 1.656V3.06374C13.3327 3.28678 13.3123 3.47613 13.2715 3.63126C13.2306 3.78694 13.1638 3.91833 13.0705 4.02653C12.9767 4.13528 12.8524 4.21478 12.6973 4.26612C12.5416 4.31691 12.3512 4.34231 12.1248 4.34231H11.188ZM11.899 3.84325H12.1016C12.1723 3.84325 12.2341 3.83717 12.2877 3.82448C12.3407 3.81178 12.3854 3.79687 12.4218 3.77976C12.4577 3.76209 12.4881 3.73394 12.5124 3.69474C12.5367 3.65555 12.5549 3.61966 12.5676 3.58654C12.5803 3.55342 12.5897 3.50373 12.5957 3.43693C12.6024 3.37014 12.6062 3.31327 12.6079 3.26635C12.609 3.21942 12.6101 3.14931 12.6101 3.05657V1.63006C12.6101 1.53786 12.6084 1.46278 12.6051 1.40647C12.6024 1.35016 12.5946 1.29275 12.583 1.23589C12.5714 1.17848 12.5538 1.13431 12.5311 1.10284C12.5085 1.07138 12.4782 1.04267 12.4406 1.01672C12.4031 0.990776 12.3556 0.972558 12.2993 0.962621C12.243 0.952132 12.1745 0.947164 12.0945 0.947164H11.899V3.84325Z"
        fill="currentColor"
      />
      <path
        d="M14.6734 4.38166C14.4995 4.38166 14.3482 4.36123 14.2201 4.32038C14.092 4.27953 13.9872 4.22267 13.9049 4.14869C13.8221 4.07472 13.7564 3.98032 13.7073 3.86494C13.6576 3.74956 13.6228 3.62479 13.6035 3.49064C13.5836 3.35649 13.5742 3.20081 13.5742 3.02305V0.431152H14.2637V3.09648C14.2637 3.18757 14.2659 3.26485 14.2709 3.32944C14.2753 3.39348 14.2858 3.46138 14.3013 3.53205C14.3173 3.60271 14.3394 3.65902 14.3686 3.70153C14.3973 3.74348 14.4376 3.77882 14.4884 3.80752C14.5398 3.83568 14.601 3.84948 14.6734 3.84948C14.7457 3.84948 14.807 3.83568 14.8583 3.80752C14.9091 3.77882 14.9483 3.74348 14.9759 3.70153C15.0035 3.65902 15.0256 3.60271 15.0421 3.53205C15.0581 3.46138 15.0686 3.39403 15.0725 3.33055C15.0764 3.26706 15.0786 3.18867 15.0786 3.09648V0.431152H15.7681V3.02305C15.7681 3.20081 15.7581 3.35649 15.7388 3.49064C15.719 3.62479 15.6847 3.74956 15.635 3.86494C15.5859 3.98032 15.5202 4.07472 15.4385 4.14869C15.3568 4.22267 15.2519 4.27953 15.1244 4.32038C14.9963 4.36123 14.8462 4.38166 14.6734 4.38166Z"
        fill="currentColor"
      />
      <path
        d="M17.0553 4.38132C16.368 4.38132 16.0069 3.98439 15.9722 3.18998L16.5916 3.0608C16.607 3.57697 16.755 3.83533 17.0338 3.83533C17.1425 3.83533 17.2231 3.80221 17.2756 3.73652C17.328 3.67027 17.3545 3.58194 17.3545 3.47043C17.3545 3.36167 17.3346 3.26285 17.2954 3.17232C17.2562 3.08233 17.2104 3.00835 17.1591 2.95094C17.1072 2.89408 17.0332 2.82286 16.9377 2.73785L16.4243 2.29289C16.2736 2.1615 16.1615 2.02404 16.0875 1.88216C16.0136 1.74028 15.9771 1.56252 15.9771 1.34888C15.9771 1.04635 16.066 0.810623 16.2443 0.642798C16.4226 0.474974 16.6667 0.389405 16.9775 0.386093C17.1204 0.384437 17.2469 0.402102 17.3567 0.43909C17.4666 0.476078 17.5571 0.525211 17.6283 0.587593C17.7001 0.649423 17.7597 0.726711 17.8072 0.819456C17.8552 0.912201 17.8911 1.00826 17.9148 1.10763C17.938 1.20755 17.9546 1.31851 17.964 1.44107L17.3567 1.56087C17.3534 1.48247 17.3484 1.41402 17.3413 1.35605C17.3341 1.29809 17.3219 1.23902 17.3048 1.1794C17.2877 1.11977 17.2651 1.07175 17.238 1.03476C17.2104 0.99777 17.1734 0.968511 17.1271 0.946429C17.0807 0.924347 17.0271 0.916066 16.9659 0.920482C16.8604 0.927107 16.7804 0.961886 16.7246 1.02537C16.6689 1.08886 16.6407 1.17388 16.6407 1.28097C16.6407 1.40629 16.6655 1.50953 16.7152 1.59013C16.7644 1.67128 16.8417 1.75795 16.9471 1.8507L17.4555 2.29289C17.5405 2.37018 17.614 2.44471 17.6769 2.51703C17.7398 2.58879 17.7995 2.6716 17.8569 2.76379C17.9143 2.85654 17.9579 2.95867 17.9877 3.07018C18.0175 3.1817 18.0324 3.29929 18.0324 3.42295C18.0324 3.70726 17.9424 3.93802 17.763 4.11523C17.5831 4.29299 17.3473 4.38132 17.0553 4.38132Z"
        fill="currentColor"
      />
      <path
        d="M18.5795 4.33475V0.956159H17.9248V0.431152H19.9238V0.956159H19.2906V4.33475H18.5795Z"
        fill="currentColor"
      />
      <path
        d="M19.9839 4.34231V0.438721H20.8788C21.0841 0.438721 21.2608 0.455834 21.4082 0.48951C21.5556 0.523185 21.6842 0.579495 21.7935 0.658991C21.9023 0.738487 21.984 0.845586 22.0381 0.981391C22.0922 1.1172 22.1192 1.28337 22.1192 1.47935C22.1192 1.72446 22.0828 1.9243 22.0099 2.07943C21.9371 2.23511 21.8162 2.34828 21.6483 2.41894L22.1899 4.34231H21.4767L21.0107 2.56027H20.6949V4.34231H19.9839ZM20.6949 2.10538H20.8666C21.077 2.10538 21.2266 2.06066 21.3154 1.97123C21.4038 1.88235 21.4485 1.72887 21.4485 1.51247C21.4485 1.30379 21.4099 1.15418 21.3331 1.0642C21.2558 0.973663 21.1145 0.928394 20.9091 0.928394H20.6949V2.10538Z"
        fill="currentColor"
      />
      <path
        d="M22.9493 4.33475V2.84916L22.1538 0.431152H22.8174L23.2999 1.98519L23.7614 0.431152H24.4183L23.6272 2.84916V4.33475H22.9493Z"
        fill="currentColor"
      />
      <path
        d="M24.4428 4.99534L24.3722 4.74802C24.4649 4.72483 24.5405 4.6768 24.5996 4.60559C24.6581 4.53437 24.6879 4.44384 24.6879 4.33398H24.3418V3.6958H24.9894V4.28871C24.9894 4.68785 24.8072 4.92302 24.4428 4.99534Z"
        fill="currentColor"
      />
      <path
        d="M26.854 0.431152H26.1523V4.33475H26.854V0.431152Z"
        fill="currentColor"
      />
      <path
        d="M27.2036 4.34231V0.438721H27.6933L28.6489 2.6497V0.438721H29.2379V4.34231H28.7714L27.8065 2.0187V4.34231H27.2036Z"
        fill="currentColor"
      />
      <path
        d="M29.5703 4.34231V0.438721H30.06L31.0156 2.6497V0.438721H31.6046V4.34231H31.1382L30.1732 2.0187V4.34231H29.5703Z"
        fill="currentColor"
      />
      <path
        d="M32.9997 4.38146C32.5978 4.38146 32.3096 4.2727 32.1357 4.05409C31.9612 3.83603 31.874 3.51694 31.874 3.09627V1.65541C31.874 1.44783 31.8939 1.26842 31.9331 1.11605C31.9723 0.964233 32.0358 0.83174 32.1224 0.720776C32.2097 0.609261 32.3262 0.525349 32.4725 0.470143C32.6182 0.413833 32.7943 0.38623 32.9997 0.38623C33.3999 0.38623 33.6875 0.493881 33.8625 0.708631C34.0375 0.923933 34.1253 1.23916 34.1253 1.65541V3.09627C34.1253 3.29998 34.1049 3.4794 34.0651 3.63398C34.0248 3.78855 33.9613 3.9227 33.8741 4.03642C33.7869 4.15015 33.671 4.23627 33.5247 4.29423C33.3789 4.3522 33.2039 4.38146 32.9997 4.38146ZM32.8219 3.82388C32.8716 3.84376 32.9307 3.85425 32.9997 3.85425C33.0687 3.85425 33.1277 3.84376 33.1763 3.82388C33.2249 3.80346 33.2635 3.77641 33.2928 3.74328C33.3215 3.71071 33.3447 3.66765 33.3613 3.6141C33.3778 3.56055 33.3883 3.50756 33.3938 3.45401C33.3994 3.40046 33.4021 3.33697 33.4021 3.26355V1.49034C33.4021 1.39649 33.3977 1.3181 33.3883 1.25517C33.3789 1.19223 33.3602 1.13316 33.3331 1.0774C33.3055 1.02165 33.2641 0.979691 33.2094 0.95264C33.1542 0.925037 33.0847 0.911236 32.9997 0.911236C32.9152 0.911236 32.8445 0.925037 32.7888 0.95264C32.733 0.979691 32.6922 1.02165 32.6651 1.0774C32.6386 1.13316 32.6204 1.19223 32.611 1.25517C32.6016 1.3181 32.5972 1.39649 32.5972 1.49034V3.26355C32.5972 3.33697 32.6 3.40046 32.6055 3.45401C32.611 3.50756 32.6221 3.56055 32.6381 3.6141C32.6546 3.66765 32.6773 3.71071 32.7054 3.74328C32.7336 3.77641 32.7728 3.80346 32.8219 3.82388Z"
        fill="currentColor"
      />
      <path
        d="M35.0045 4.33475L34.2134 0.431152H34.8444L35.3578 3.14838L35.8425 0.431152H36.4878L35.6918 4.33475H35.0045Z"
        fill="currentColor"
      />
      <path
        d="M36.3079 4.34231L37.1012 0.438721H37.8426L38.6337 4.34231H37.9723L37.8144 3.43583H37.1387L36.9786 4.34231H36.3079ZM37.2143 2.9721H37.7371L37.4755 1.36893L37.2143 2.9721Z"
        fill="currentColor"
      />
      <path
        d="M39.0117 4.33475V0.956159H38.3569V0.431152H40.3559V0.956159H39.7227V4.33475H39.0117Z"
        fill="currentColor"
      />
      <path
        d="M41.1387 0.431152H40.437V4.33475H41.1387V0.431152Z"
        fill="currentColor"
      />
      <path
        d="M42.5499 4.38146C42.148 4.38146 41.8598 4.2727 41.6859 4.05409C41.5115 3.83603 41.4248 3.51694 41.4248 3.09627V1.65541C41.4248 1.44783 41.4441 1.26842 41.4833 1.11605C41.5225 0.964233 41.586 0.83174 41.6727 0.720776C41.7599 0.609261 41.8764 0.525349 42.0227 0.470143C42.1684 0.413833 42.3445 0.38623 42.5499 0.38623C42.9501 0.38623 43.2378 0.493881 43.4128 0.708631C43.5878 0.923933 43.6755 1.23916 43.6755 1.65541V3.09627C43.6755 3.29998 43.6551 3.4794 43.6154 3.63398C43.5751 3.78855 43.5116 3.9227 43.4243 4.03642C43.3377 4.15015 43.2212 4.23627 43.0749 4.29423C42.9292 4.3522 42.7542 4.38146 42.5499 4.38146ZM42.3721 3.82388C42.4218 3.84376 42.4809 3.85425 42.5499 3.85425C42.6189 3.85425 42.678 3.84376 42.7266 3.82388C42.7751 3.80346 42.8138 3.77641 42.843 3.74328C42.8723 3.71071 42.8949 3.66765 42.9115 3.6141C42.9281 3.56055 42.9385 3.50756 42.9441 3.45401C42.9496 3.40046 42.9523 3.33697 42.9523 3.26355V1.49034C42.9523 1.39649 42.9479 1.3181 42.9385 1.25517C42.9292 1.19223 42.9104 1.13316 42.8833 1.0774C42.8557 1.02165 42.8143 0.979691 42.7597 0.95264C42.7045 0.925037 42.6349 0.911236 42.5499 0.911236C42.4654 0.911236 42.3948 0.925037 42.339 0.95264C42.2833 0.979691 42.2424 1.02165 42.2153 1.0774C42.1888 1.13316 42.1706 1.19223 42.1612 1.25517C42.1519 1.3181 42.1474 1.39649 42.1474 1.49034V3.26355C42.1474 3.33697 42.1502 3.40046 42.1557 3.45401C42.1612 3.50756 42.1723 3.56055 42.1883 3.6141C42.2049 3.66765 42.2275 3.71071 42.2556 3.74328C42.2838 3.77641 42.323 3.80346 42.3721 3.82388Z"
        fill="currentColor"
      />
      <path
        d="M43.9451 4.34231V0.438721H44.4347L45.3903 2.6497V0.438721H45.9794V4.34231H45.5129L44.5479 2.0187V4.34231H43.9451Z"
        fill="currentColor"
      />
      <path
        d="M7.54541 10.7222L8.33871 6.8186H9.08012L9.87122 10.7222H9.20986L9.05197 9.81572H8.37625L8.21616 10.7222H7.54541ZM8.45189 9.35199H8.97468L8.71301 7.74882L8.45189 9.35199Z"
        fill="currentColor"
      />
      <path
        d="M10.0674 10.7222V6.8186H10.5571L11.5132 9.02959V6.8186H12.1017V10.7222H11.6352L10.6702 8.39859V10.7222H10.0674Z"
        fill="currentColor"
      />
      <path
        d="M12.4976 10.7222V6.8186H13.4062C13.642 6.8186 13.8391 6.84234 13.9975 6.88927C14.1559 6.93674 14.2829 7.01127 14.379 7.11285C14.4745 7.21498 14.5424 7.3403 14.5821 7.4888C14.6224 7.63675 14.6423 7.81948 14.6423 8.03589V9.44364C14.6423 9.66667 14.6219 9.85602 14.581 10.0112C14.5402 10.1668 14.4734 10.2982 14.3801 10.407C14.2868 10.5152 14.162 10.5947 14.0069 10.646C13.8512 10.6968 13.6607 10.7222 13.4344 10.7222H12.4976ZM13.2086 10.2231H13.4112C13.4819 10.2231 13.5437 10.2171 13.5973 10.2044C13.6503 10.1917 13.695 10.1768 13.7314 10.1597C13.7673 10.1425 13.7977 10.1144 13.8219 10.0746C13.8462 10.0354 13.8644 9.99956 13.8771 9.96643C13.8898 9.93331 13.8992 9.88363 13.9053 9.81683C13.9119 9.75003 13.9158 9.69317 13.9174 9.64624C13.9186 9.59932 13.9197 9.52921 13.9197 9.43701V8.00994C13.9197 7.91775 13.918 7.84322 13.9147 7.78636C13.9119 7.73005 13.9042 7.67319 13.8926 7.61577C13.881 7.55836 13.8633 7.5142 13.8407 7.48273C13.8181 7.45126 13.7877 7.42255 13.7502 7.39661C13.7126 7.37066 13.6652 7.35299 13.6088 7.34251C13.5525 7.33257 13.4841 7.32705 13.404 7.32705H13.2086V10.2231Z"
        fill="currentColor"
      />
      <path
        d="M16.6885 6.8103H15.9868V10.7139H16.6885V6.8103Z"
        fill="currentColor"
      />
      <path
        d="M17.1011 10.7139V6.8103H17.5907L18.5464 9.02129V6.8103H19.1354V10.7139H18.6689L17.7039 8.39029V10.7139H17.1011Z"
        fill="currentColor"
      />
      <path
        d="M19.5312 10.7222V6.8186H21.1344V7.31104H20.2423V8.44552H20.9555V8.94678H20.2423V10.7222H19.5312Z"
        fill="currentColor"
      />
      <path
        d="M21.3208 10.7222V6.8186H22.2157C22.4211 6.8186 22.5977 6.83572 22.7451 6.86939C22.8925 6.90307 23.0211 6.95938 23.1304 7.03887C23.2392 7.11837 23.3209 7.22547 23.375 7.36128C23.4291 7.49708 23.4562 7.66325 23.4562 7.85923C23.4562 8.10435 23.4197 8.30419 23.3469 8.45987C23.274 8.615 23.1531 8.72817 22.9853 8.79883L23.5268 10.7222H22.8136L22.3476 8.94016H22.0319V10.7222H21.3208ZM22.0319 8.48582H22.2035C22.4139 8.48582 22.5635 8.4411 22.6524 8.35111C22.7407 8.26223 22.7854 8.10876 22.7854 7.89236C22.7854 7.68368 22.7468 7.53407 22.67 7.44408C22.5927 7.35355 22.452 7.30828 22.246 7.30828H22.0319V8.48582Z"
        fill="currentColor"
      />
      <path
        d="M23.605 10.7222L24.3983 6.8186H25.1397L25.9308 10.7222H25.2694L25.1115 9.81572H24.4358L24.2757 10.7222H23.605ZM24.5115 9.35199H25.0343L24.7726 7.74882L24.5115 9.35199Z"
        fill="currentColor"
      />
      <path
        d="M27.1105 10.761C26.4232 10.761 26.0621 10.364 26.0273 9.56962L26.6467 9.44044C26.6622 9.95661 26.8102 10.215 27.0895 10.215C27.1977 10.215 27.2783 10.1818 27.3307 10.1156C27.3832 10.0499 27.4097 9.96158 27.4097 9.85006C27.4097 9.74131 27.3898 9.64249 27.3506 9.55195C27.3114 9.46197 27.2656 9.38799 27.2143 9.33058C27.1624 9.27372 27.0884 9.2025 26.9929 9.11749L26.4795 8.67253C26.3288 8.54114 26.2167 8.40368 26.1427 8.2618C26.0687 8.11992 26.0323 7.94216 26.0323 7.72852C26.0323 7.42599 26.1212 7.19026 26.2995 7.02244C26.4778 6.85461 26.7218 6.76904 27.0326 6.76573C27.1756 6.76408 27.302 6.78174 27.4119 6.81873C27.5218 6.85572 27.6123 6.90485 27.6835 6.96723C27.7553 7.02906 27.8149 7.10635 27.8624 7.19909C27.9104 7.29129 27.9463 7.38735 27.97 7.48727C27.9932 7.58719 28.0098 7.69815 28.0191 7.82071L27.4119 7.9405C27.4086 7.86211 27.4036 7.79366 27.3964 7.73569C27.3893 7.67773 27.3771 7.61866 27.36 7.55903C27.3429 7.49941 27.3203 7.45138 27.2932 7.4144C27.2656 7.37741 27.2286 7.34815 27.1822 7.32607C27.1359 7.30399 27.0823 7.2957 27.021 7.30012C26.9156 7.30675 26.8355 7.34153 26.7798 7.40501C26.724 7.4685 26.6959 7.55351 26.6959 7.66061C26.6959 7.78593 26.7207 7.88916 26.7704 7.96976C26.8195 8.05092 26.8968 8.13759 27.0023 8.23033L27.5107 8.67253C27.5957 8.74982 27.6691 8.82435 27.7321 8.89666C27.795 8.96843 27.8546 9.05124 27.9121 9.14343C27.9695 9.23618 28.0131 9.33831 28.0429 9.44982C28.0727 9.56134 28.0876 9.67893 28.0876 9.80259C28.0876 10.0869 27.9976 10.3177 27.8182 10.4949C27.6382 10.6726 27.4025 10.761 27.1105 10.761Z"
        fill="currentColor"
      />
      <path
        d="M28.6977 10.7139V7.33531H28.043V6.8103H30.042V7.33531H29.4088V10.7139H28.6977Z"
        fill="currentColor"
      />
      <path
        d="M30.1646 10.7222V6.8186H31.0594C31.2648 6.8186 31.4415 6.83572 31.5889 6.86939C31.7363 6.90307 31.8649 6.95938 31.9736 7.03887C32.0829 7.11837 32.1646 7.22547 32.2187 7.36128C32.2728 7.49708 32.2999 7.66325 32.2999 7.85923C32.2999 8.10435 32.2635 8.30419 32.1906 8.45987C32.1177 8.615 31.9968 8.72817 31.829 8.79883L32.3706 10.7222H31.6573L31.1908 8.94016H30.8756V10.7222H30.1646ZM30.8756 8.48582H31.0473C31.2576 8.48582 31.4072 8.4411 31.4961 8.35111C31.5844 8.26223 31.6292 8.10876 31.6292 7.89236C31.6292 7.68368 31.5905 7.53407 31.5138 7.44408C31.4365 7.35355 31.2952 7.30828 31.0898 7.30828H30.8756V8.48582Z"
        fill="currentColor"
      />
      <path
        d="M33.7202 10.7608C33.5463 10.7608 33.3951 10.7404 33.267 10.6995C33.1389 10.6587 33.034 10.6018 32.9518 10.5278C32.869 10.4539 32.8033 10.3595 32.7541 10.2441C32.7045 10.1287 32.6697 10.0039 32.6504 9.86979C32.6305 9.73564 32.6211 9.57996 32.6211 9.4022V6.8103H33.3106V9.47563C33.3106 9.56672 33.3128 9.644 33.3178 9.70859C33.3222 9.77263 33.3327 9.84053 33.3482 9.9112C33.3642 9.98186 33.3862 10.0382 33.4155 10.0807C33.4442 10.1226 33.4845 10.158 33.5353 10.1861C33.5866 10.2148 33.6479 10.2286 33.7202 10.2286C33.7926 10.2286 33.8538 10.2148 33.9052 10.1861C33.956 10.158 33.9952 10.1226 34.0228 10.0807C34.0504 10.0382 34.0724 9.98186 34.089 9.9112C34.105 9.84053 34.1155 9.77318 34.1194 9.7097C34.1232 9.64621 34.1254 9.56782 34.1254 9.47563V6.8103H34.815V9.4022C34.815 9.57996 34.805 9.73564 34.7857 9.86979C34.7658 10.0039 34.7316 10.1287 34.6819 10.2441C34.6328 10.3595 34.5671 10.4539 34.4854 10.5278C34.4037 10.6018 34.2988 10.6587 34.1713 10.6995C34.0432 10.7404 33.893 10.7608 33.7202 10.7608Z"
        fill="currentColor"
      />
      <path
        d="M36.228 10.7608C36.0602 10.7608 35.9117 10.7399 35.783 10.6974C35.6544 10.6548 35.5479 10.5963 35.4639 10.5207C35.38 10.4451 35.3116 10.3523 35.2591 10.2408C35.2067 10.1293 35.1697 10.0095 35.1487 9.88142C35.1272 9.75389 35.1167 9.60981 35.1167 9.44971V8.0889C35.1167 7.91942 35.1277 7.76871 35.1498 7.63787C35.1719 7.50703 35.2083 7.38503 35.2602 7.27296C35.3121 7.1609 35.38 7.0687 35.4639 6.99638C35.5479 6.92406 35.6538 6.86776 35.7819 6.8269C35.91 6.78605 36.0585 6.76562 36.228 6.76562C36.4229 6.76562 36.589 6.79157 36.727 6.84346C36.8651 6.89481 36.9744 6.97099 37.0544 7.07202C37.1345 7.17194 37.1924 7.28952 37.2283 7.42367C37.2647 7.55782 37.283 7.71461 37.283 7.89347V8.22746H36.5979V7.89568C36.5979 7.70136 36.5874 7.56997 36.5669 7.50262C36.5261 7.37068 36.4267 7.30001 36.2683 7.29063H36.2302C36.1706 7.29063 36.1187 7.29781 36.074 7.31216C36.0292 7.32596 35.9928 7.34804 35.9641 7.37896C35.9359 7.40932 35.9133 7.44189 35.8962 7.47667C35.8785 7.5109 35.8658 7.55617 35.857 7.61192C35.8487 7.66768 35.8432 7.71902 35.8404 7.76595C35.8382 7.81342 35.8371 7.87305 35.8371 7.94537V9.59822C35.8371 9.82677 35.8658 9.99017 35.9233 10.0873C35.9801 10.185 36.0828 10.2336 36.2302 10.2336C36.2804 10.2336 36.3246 10.2276 36.3632 10.216C36.4019 10.2038 36.4339 10.1895 36.4599 10.1712C36.4858 10.153 36.5079 10.1271 36.5255 10.0934C36.5438 10.0597 36.5576 10.0283 36.5669 9.99901C36.5763 9.9703 36.5835 9.93 36.5885 9.87811C36.5929 9.82621 36.5957 9.78205 36.5962 9.74617C36.5973 9.71028 36.5979 9.6606 36.5979 9.59822V9.24932H37.283V9.56951C37.283 9.7158 37.2725 9.84664 37.252 9.96257C37.2316 10.0791 37.1968 10.1884 37.1472 10.2899C37.098 10.3921 37.034 10.4765 36.9556 10.5444C36.8772 10.6118 36.7767 10.6648 36.6542 10.7034C36.5316 10.7415 36.3897 10.7608 36.228 10.7608Z"
        fill="currentColor"
      />
      <path
        d="M37.968 10.7139V7.33531H37.3132V6.8103H39.3122V7.33531H38.679V10.7139H37.968Z"
        fill="currentColor"
      />
      <path
        d="M40.504 10.7608C40.3295 10.7608 40.1788 10.7404 40.0508 10.6995C39.9227 10.6587 39.8178 10.6018 39.7355 10.5278C39.6527 10.4539 39.587 10.3595 39.5373 10.2441C39.4882 10.1287 39.4534 10.0039 39.4341 9.86979C39.4142 9.73564 39.4043 9.57996 39.4043 9.4022V6.8103H40.0944V9.47563C40.0944 9.56672 40.0966 9.644 40.1015 9.70859C40.106 9.77263 40.1164 9.84053 40.1319 9.9112C40.1479 9.98186 40.17 10.0382 40.1993 10.0807C40.228 10.1226 40.2683 10.158 40.3191 10.1861C40.3704 10.2148 40.4317 10.2286 40.504 10.2286C40.5763 10.2286 40.6376 10.2148 40.6889 10.1861C40.7397 10.158 40.7789 10.1226 40.8065 10.0807C40.8341 10.0382 40.8562 9.98186 40.8728 9.9112C40.8888 9.84053 40.8993 9.77318 40.9031 9.7097C40.907 9.64621 40.9092 9.56782 40.9092 9.47563V6.8103H41.5987V9.4022C41.5987 9.57996 41.5888 9.73564 41.5695 9.86979C41.5496 10.0039 41.5154 10.1287 41.4657 10.2441C41.4165 10.3595 41.3508 10.4539 41.2691 10.5278C41.1874 10.6018 41.0825 10.6587 40.955 10.6995C40.8269 10.7404 40.6768 10.7608 40.504 10.7608Z"
        fill="currentColor"
      />
      <path
        d="M41.9636 10.7222V6.8186H42.8585C43.0639 6.8186 43.2405 6.83572 43.3879 6.86939C43.5353 6.90307 43.664 6.95938 43.7733 7.03887C43.882 7.11837 43.9637 7.22547 44.0178 7.36128C44.0719 7.49708 44.099 7.66325 44.099 7.85923C44.099 8.10435 44.0625 8.30419 43.9897 8.45987C43.9168 8.615 43.7959 8.72817 43.6281 8.79883L44.1696 10.7222H43.4564L42.9904 8.94016H42.6747V10.7222H41.9636ZM42.6747 8.48582H42.8464C43.0567 8.48582 43.2063 8.4411 43.2952 8.35111C43.3835 8.26223 43.4282 8.10876 43.4282 7.89236C43.4282 7.68368 43.3896 7.53407 43.3128 7.44408C43.2356 7.35355 43.0948 7.30828 42.8889 7.30828H42.6747V8.48582Z"
        fill="currentColor"
      />
      <path
        d="M44.45 10.7222V6.8186H46.084V7.32042H45.161V8.4411H45.8908V8.94237H45.161V10.2303H46.0956V10.7222H44.45Z"
        fill="currentColor"
      />
      <path
        d="M36.3372 31.0952L29.94 27.403V20.0171C29.94 19.8614 29.8566 19.7173 29.7219 19.6389L23.1078 15.8203C22.9725 15.7425 22.8069 15.7425 22.6716 15.8203L16.0569 19.6389C15.9217 19.7173 15.8388 19.8614 15.8388 20.0171V27.403L9.44218 31.0952C9.30693 31.173 9.22412 31.3171 9.22412 31.4733V39.1121C9.22412 39.2678 9.30693 39.4119 9.44218 39.4903L16.0569 43.3083C16.1248 43.3469 16.1993 43.3668 16.275 43.3668C16.3506 43.3668 16.4257 43.3469 16.493 43.3083L22.8897 39.6161L29.2853 43.3083C29.3532 43.3469 29.4282 43.3668 29.5033 43.3668C29.579 43.3668 29.654 43.3469 29.7219 43.3083L36.3372 39.4903C36.4725 39.4124 36.5553 39.2678 36.5553 39.1121V31.4733C36.5553 31.3171 36.4725 31.173 36.3372 31.0952ZM22.4536 38.3564L17.1478 35.293L22.4536 32.2291V38.3564ZM28.6305 27.6553L23.3258 30.7176V24.5914L28.6305 27.6553ZM22.4536 30.7176L17.1478 27.6553L22.4536 24.5914V30.7176ZM23.7625 31.4733L29.5033 28.1593L35.2464 31.4733L29.5033 34.789L23.7625 31.4733ZM29.0672 26.8995L23.7625 23.8356L29.0672 20.7728V26.8995ZM22.0169 23.8356L16.7111 26.8995V20.7728L22.0169 23.8356ZM15.8388 42.1744L10.0969 38.8604V32.2291L15.8388 35.5447V42.1744ZM16.7111 42.1744V36.0488L22.0169 39.1121L16.7111 42.1744ZM35.6825 38.8604L29.94 42.1744V35.5447L35.6825 32.2291V38.8604Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IndustryAndInnovation;
