import React from "react";

const NoPoverty = (props) => {
  const { fill, width = 44, height = 38 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 38"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.47509 2.4477C1.38235 2.49793 1.1135 2.60338 0.667987 2.76292C0.315224 2.88106 0.0921933 2.96056 0 3.00251V1.55226C0.285413 1.44295 0.632104 1.27513 1.04007 1.04823C1.44749 0.820787 1.73566 0.61487 1.90404 0.429932H3.42992V10.6446H1.47509V2.4477Z"
        fill="currentColor"
      />
      <path
        d="M7.71143 4.33645V0.432861H8.2011L9.15671 2.64385V0.432861H9.74575V4.33645H9.27926L8.31427 2.01285V4.33645H7.71143Z"
        fill="currentColor"
      />
      <path
        d="M11.2946 4.37536C10.8927 4.37536 10.6045 4.2666 10.4306 4.04799C10.2562 3.82992 10.1689 3.51084 10.1689 3.09017V1.6493C10.1689 1.44173 10.1888 1.26231 10.228 1.10994C10.2672 0.95813 10.3307 0.825636 10.4174 0.714673C10.5046 0.603158 10.6211 0.519245 10.7674 0.464039C10.9131 0.408282 11.0892 0.380127 11.2946 0.380127C11.6948 0.380127 11.9824 0.487778 12.1575 0.702528C12.3325 0.917829 12.4197 1.23305 12.4197 1.6493V3.09017C12.4197 3.29388 12.3998 3.4733 12.3601 3.62787C12.3198 3.78245 12.2563 3.9166 12.169 4.03032C12.0818 4.14404 11.9659 4.23017 11.8196 4.28813C11.6738 4.34665 11.4988 4.37536 11.2946 4.37536ZM11.1168 3.81778C11.1665 3.83821 11.2256 3.84814 11.2946 3.84814C11.3636 3.84814 11.4227 3.83821 11.4712 3.81778C11.5198 3.79735 11.5585 3.7703 11.5877 3.73718C11.6164 3.70461 11.6396 3.66155 11.6562 3.608C11.6727 3.55445 11.6832 3.50145 11.6888 3.4479C11.6943 3.39435 11.697 3.33087 11.697 3.25744V1.48424C11.697 1.39039 11.6926 1.312 11.6832 1.24906C11.6738 1.18613 11.6551 1.12706 11.6275 1.0713C11.6004 1.01554 11.559 0.974139 11.5044 0.946536C11.4492 0.918934 11.3796 0.905132 11.2946 0.905132C11.2101 0.905132 11.1395 0.918934 11.0837 0.946536C11.0279 0.974139 10.9871 1.01554 10.96 1.0713C10.9335 1.12706 10.9153 1.18613 10.9059 1.24906C10.8966 1.312 10.8921 1.39039 10.8921 1.48424V3.25744C10.8921 3.33087 10.8949 3.39435 10.9004 3.4479C10.9059 3.50145 10.917 3.55445 10.933 3.608C10.9496 3.66155 10.9722 3.70461 11.0003 3.73718C11.0285 3.7703 11.0677 3.79735 11.1168 3.81778Z"
        fill="currentColor"
      />
      <path
        d="M7.71143 10.7163V6.81274H8.83431C9.50009 6.81274 9.83298 7.18262 9.83298 7.92183C9.83298 8.28453 9.74189 8.55007 9.55971 8.719C9.37753 8.88737 9.12082 8.97184 8.78959 8.97184H8.42247V10.7163H7.71143ZM8.42247 8.47278H8.65102C8.85142 8.47278 8.98723 8.43303 9.05844 8.35353C9.12855 8.27459 9.16388 8.12885 9.16388 7.91686C9.16388 7.79596 9.15947 7.70266 9.15008 7.63696C9.1407 7.57072 9.11862 7.5111 9.08384 7.45699C9.04961 7.40289 8.99827 7.36535 8.92981 7.34493C8.86136 7.3245 8.76916 7.31456 8.65323 7.31456H8.42247V8.47278Z"
        fill="currentColor"
      />
      <path
        d="M11.2497 10.7538C10.8478 10.7538 10.5596 10.645 10.3857 10.4264C10.2112 10.2083 10.124 9.88925 10.124 9.46859V8.02772C10.124 7.82015 10.1439 7.64073 10.1831 7.48836C10.2223 7.33655 10.2858 7.20405 10.3724 7.09309C10.4597 6.98158 10.5762 6.89766 10.7225 6.84246C10.8682 6.7867 11.0443 6.75854 11.2497 6.75854C11.6499 6.75854 11.9375 6.8662 12.1125 7.08095C12.2875 7.29625 12.3753 7.61147 12.3753 8.02772V9.46859C12.3753 9.6723 12.3549 9.85171 12.3151 10.0063C12.2748 10.1609 12.2113 10.295 12.1241 10.4087C12.0374 10.5225 11.921 10.6086 11.7747 10.6665C11.6289 10.7251 11.4539 10.7538 11.2497 10.7538ZM11.0719 10.1962C11.1216 10.2166 11.1807 10.2266 11.2497 10.2266C11.3187 10.2266 11.3777 10.2166 11.4263 10.1962C11.4749 10.1758 11.5135 10.1487 11.5428 10.1156C11.5721 10.083 11.5947 10.04 11.6113 9.98642C11.6278 9.93287 11.6383 9.87987 11.6438 9.82632C11.6494 9.77277 11.6521 9.70928 11.6521 9.63586V7.86266C11.6521 7.76881 11.6477 7.69042 11.6383 7.62748C11.6289 7.56455 11.6102 7.50548 11.5831 7.44972C11.5555 7.39396 11.5141 7.35201 11.4594 7.32495C11.4042 7.29735 11.3347 7.28355 11.2497 7.28355C11.1652 7.28355 11.0945 7.29735 11.0388 7.32495C10.983 7.35201 10.9422 7.39396 10.9151 7.44972C10.8886 7.50548 10.8704 7.56455 10.861 7.62748C10.8516 7.69042 10.8472 7.76881 10.8472 7.86266V9.63586C10.8472 9.70928 10.85 9.77277 10.8555 9.82632C10.861 9.87987 10.8721 9.93287 10.8881 9.98642C10.9046 10.04 10.9273 10.083 10.9554 10.1156C10.9836 10.1487 11.0228 10.1758 11.0719 10.1962Z"
        fill="currentColor"
      />
      <path
        d="M13.481 10.7073L12.6899 6.80371H13.3209L13.8344 9.52093L14.3191 6.80371H14.9644L14.1683 10.7073H13.481Z"
        fill="currentColor"
      />
      <path
        d="M15.3403 10.7163V6.81274H16.9744V7.31456H16.0514V8.43469H16.7812V8.93651H16.0514V10.2245H16.986V10.7163H15.3403Z"
        fill="currentColor"
      />
      <path
        d="M17.3711 10.7163V6.81274H18.266C18.4713 6.81274 18.648 6.82986 18.7954 6.86353C18.9428 6.89721 19.0714 6.95352 19.1807 7.03301C19.2895 7.11251 19.3712 7.21961 19.4253 7.35542C19.4794 7.49122 19.5064 7.65739 19.5064 7.85337C19.5064 8.09848 19.47 8.29833 19.3971 8.45346C19.3243 8.60914 19.2034 8.72231 19.0355 8.79297L19.5771 10.7163H18.8639L18.3979 8.9343H18.0821V10.7163H17.3711ZM18.0821 8.4794H18.2538C18.4642 8.4794 18.6138 8.43469 18.7027 8.34525C18.791 8.25637 18.8357 8.1029 18.8357 7.88649C18.8357 7.67782 18.7971 7.52821 18.7203 7.43822C18.643 7.34769 18.5017 7.30242 18.2963 7.30242H18.0821V8.4794Z"
        fill="currentColor"
      />
      <path
        d="M20.4028 10.7073V7.32872H19.748V6.80371H21.747V7.32872H21.1138V10.7073H20.4028Z"
        fill="currentColor"
      />
      <path
        d="M22.5826 10.7073V9.22172L21.7871 6.80371H22.4507L22.9332 8.35775L23.3947 6.80371H24.0516L23.2605 9.22172V10.7073H22.5826Z"
        fill="currentColor"
      />
      <path
        d="M38.0659 21.3223C38.0659 20.4987 38.7339 19.8318 39.557 19.8318C40.3785 19.8318 41.0443 20.4987 41.0443 21.3223C41.0443 22.1455 40.3785 22.8123 39.557 22.8123C38.7339 22.8123 38.0659 22.1455 38.0659 21.3223Z"
        fill="currentColor"
      />
      <path
        d="M41.2954 23.3865C42.3063 23.4782 43.0985 24.3283 43.0985 25.3651V29.495C43.0985 29.813 42.8401 30.0725 42.5216 30.0725C42.2014 30.0725 41.9436 29.813 41.9436 29.495V25.9481C41.9436 25.815 41.9049 25.635 41.662 25.635C41.4936 25.635 41.4589 25.8134 41.4522 25.9265V36.558C41.4522 37.0151 41.0824 37.3878 40.623 37.3878C40.1637 37.3878 39.7916 37.0151 39.7916 36.558V30.4313C39.7894 30.364 39.7668 30.1884 39.5565 30.1884H39.5554C39.3439 30.1884 39.318 30.364 39.3158 30.4313V36.558C39.3158 37.0151 38.9459 37.3878 38.4866 37.3878C38.0267 37.3878 37.6568 37.0151 37.6568 36.558V25.9265C37.6513 25.8134 37.6149 25.635 37.4482 25.635C37.2036 25.635 37.1666 25.815 37.1666 25.9481V29.495C37.1666 29.813 36.9077 30.0725 36.5886 30.0725C36.269 30.0725 36.0117 29.813 36.0117 29.495V25.3651C36.0117 24.3283 36.8045 23.4782 37.8169 23.3865C37.8501 23.3821 37.8865 23.3777 37.9262 23.3777H41.1823C41.2248 23.3777 41.2596 23.3821 41.2954 23.3865Z"
        fill="currentColor"
      />
      <path
        d="M29.2065 22.1048C29.2065 21.3259 28.5766 20.6927 27.7971 20.6927C27.0171 20.6927 26.3855 21.3259 26.3855 22.1048C26.3855 22.8843 27.0171 23.5159 27.7971 23.5159C28.5766 23.5159 29.2065 22.8843 29.2065 22.1048Z"
        fill="currentColor"
      />
      <path
        d="M24.9544 25.3006L23.7818 29.1186C23.7818 29.1186 23.5726 29.8181 24.1666 29.9931C24.7573 30.1681 24.9367 29.4846 24.9367 29.4846L25.8824 26.2981C25.8824 26.2981 25.9337 26.053 26.1176 26.106C26.302 26.159 26.233 26.4213 26.233 26.4213L24.5873 32.3928H26.1176V36.6696C26.1176 37.0743 26.4472 37.4033 26.8535 37.4033C27.2592 37.4033 27.5872 37.0743 27.5872 36.6696V32.3928H28.0051V36.6696C28.0051 37.0743 28.3324 37.4033 28.7393 37.4033C29.1467 37.4033 29.4757 37.0743 29.4757 36.6696V32.3928H31.0055L29.3609 26.4213C29.3609 26.4213 29.2919 26.159 29.4757 26.106C29.6601 26.053 29.7115 26.2981 29.7115 26.2981L30.6571 29.4846C30.6571 29.4846 30.8366 30.1681 31.4267 29.9931C32.0218 29.8181 31.8115 29.1186 31.8115 29.1186L30.6389 25.3006C30.6389 25.3006 30.2199 24.197 29.2919 24.197H26.302C25.3745 24.197 24.9544 25.3006 24.9544 25.3006Z"
        fill="currentColor"
      />
      <path
        d="M20.7568 22.1048C20.7568 21.3259 20.1258 20.6927 19.3469 20.6927C18.5668 20.6927 17.9358 21.3259 17.9358 22.1048C17.9358 22.8843 18.5668 23.5159 19.3469 23.5159C20.1258 23.5159 20.7568 22.8843 20.7568 22.1048Z"
        fill="currentColor"
      />
      <path
        d="M16.5039 25.3006L15.3318 29.1186C15.3318 29.1186 15.1221 29.8181 15.7161 29.9931C16.3068 30.1681 16.4856 29.4846 16.4856 29.4846L17.4319 26.2981C17.4319 26.2981 17.4832 26.053 17.667 26.106C17.852 26.159 17.7819 26.4213 17.7819 26.4213L16.1367 32.3928H17.667V36.6696C17.667 37.0743 17.9977 37.4033 18.4029 37.4033C18.8092 37.4033 19.1372 37.0743 19.1372 36.6696V32.3928H19.5551V36.6696C19.5551 37.0743 19.8819 37.4033 20.2899 37.4033C20.6973 37.4033 21.0252 37.0743 21.0252 36.6696V32.3928H22.5555L20.9115 26.4213C20.9115 26.4213 20.8414 26.159 21.0252 26.106C21.209 26.053 21.2609 26.2981 21.2609 26.2981L22.206 29.4846C22.206 29.4846 22.386 30.1681 22.9762 29.9931C23.5707 29.8181 23.3609 29.1186 23.3609 29.1186L22.1878 25.3006C22.1878 25.3006 21.7699 24.197 20.8414 24.197H17.852C16.9245 24.197 16.5039 25.3006 16.5039 25.3006Z"
        fill="currentColor"
      />
      <path
        d="M33.9447 30.0013C34.5945 30.0013 35.1184 29.4758 35.1184 28.826C35.1184 28.1768 34.5945 27.6523 33.9447 27.6523C33.295 27.6523 32.7705 28.1768 32.7705 28.826C32.7705 29.4758 33.295 30.0013 33.9447 30.0013Z"
        fill="currentColor"
      />
      <path
        d="M31.1289 33.6492C31.0715 33.7866 31.0245 34.0776 31.3 34.2046C31.6235 34.3531 31.8035 34.0406 31.8035 34.0406L32.4417 32.0052C32.4417 32.0052 32.5526 31.8688 32.5526 32.0096V36.802H32.5554C32.5554 37.1354 32.8237 37.4043 33.1577 37.4043C33.4911 37.4043 33.76 37.1354 33.76 36.802V34.9106C33.76 34.9106 33.7252 34.6434 33.941 34.6434C34.1563 34.6434 34.1232 34.9106 34.1232 34.9106V36.802C34.1232 37.1354 34.3932 37.4043 34.7266 37.4043C35.0589 37.4043 35.3306 37.1354 35.3306 36.802V32.0096C35.3306 31.8688 35.4045 31.9693 35.4045 31.9693L35.9052 33.3632C35.9052 33.3632 36.1222 33.9865 36.189 34.0135C36.4148 34.1085 36.6759 34.0008 36.7714 33.7728C36.8333 33.6243 36.8095 33.4648 36.7234 33.3445L36.7278 33.34C36.7135 33.2953 35.9798 31.4962 35.8937 31.2439C35.6287 30.471 35.1467 30.4776 34.6824 30.4644C34.2182 30.4539 33.941 30.4644 33.941 30.4644C33.941 30.4644 33.644 30.4528 33.0312 30.471C32.4196 30.4898 32.2264 30.8039 31.5512 32.5401C31.4546 32.7907 31.1289 33.5995 31.1244 33.6481L31.1289 33.6492Z"
        fill="currentColor"
      />
      <path
        d="M8.15723 21.323C8.15723 20.4993 7.48924 19.8319 6.66612 19.8319C5.84466 19.8319 5.17833 20.4993 5.17833 21.323C5.17833 22.1461 5.84466 22.813 6.66612 22.813C7.48924 22.813 8.15723 22.1461 8.15723 21.323Z"
        fill="currentColor"
      />
      <path
        d="M4.9271 23.3865C3.91684 23.4782 3.12354 24.3283 3.12354 25.3651V29.495C3.12354 29.813 3.38245 30.0725 3.70154 30.0725C4.02063 30.0725 4.27899 29.813 4.27899 29.495V25.9481C4.27899 25.815 4.31763 25.635 4.56109 25.635C4.72891 25.635 4.76369 25.8134 4.77087 25.9265V36.558C4.77087 37.0151 5.14019 37.3878 5.60006 37.3878C6.05881 37.3878 6.4298 37.0151 6.4298 36.558V30.4313C6.43311 30.364 6.45519 30.1884 6.66663 30.1884H6.66718C6.87862 30.1884 6.90456 30.364 6.90622 30.4313V36.558C6.90622 37.0151 7.27665 37.3878 7.73541 37.3878C8.19527 37.3878 8.5657 37.0151 8.5657 36.558V25.9265C8.57012 25.8134 8.60821 25.635 8.77493 25.635C9.01949 25.635 9.05592 25.815 9.05592 25.9481V29.495C9.05592 29.813 9.31484 30.0725 9.63393 30.0725C9.95301 30.0725 10.2114 29.813 10.2114 29.495V25.3651C10.2114 24.3283 9.41863 23.4782 8.40616 23.3865C8.37248 23.3821 8.3366 23.3777 8.29685 23.3777H5.04082C4.99776 23.3777 4.96243 23.3821 4.9271 23.3865Z"
        fill="currentColor"
      />
      <path
        d="M3.62744 30.0957L2.30482 37.1079L3.62744 30.0957Z"
        fill="currentColor"
      />
      <path
        d="M3.62744 30.0957L2.30482 37.1079"
        stroke="white"
        strokeWidth="0.000370399"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.918 29.3853C10.8407 29.2649 10.7082 29.1843 10.5553 29.1843C10.3196 29.1843 10.1274 29.3764 10.1274 29.6116C10.1274 29.6795 10.1694 29.7993 10.1694 29.7993L11.3536 31.9418C11.3536 31.9418 11.4259 32.0892 11.4259 32.2239V36.8242H11.4281C11.4281 37.1455 11.687 37.4033 12.0072 37.4033C12.3263 37.4033 12.5841 37.1455 12.5841 36.8242V35.0091C12.5841 35.0091 12.5515 34.7524 12.7591 34.7524C12.9656 34.7524 12.9335 35.0091 12.9335 35.0091V36.8242C12.9335 37.1455 13.193 37.4033 13.5126 37.4033C13.8323 37.4033 14.0923 37.1455 14.0923 36.8242V32.2239C14.0923 32.0892 14.1641 31.9418 14.1641 31.9418L15.2582 29.9638C15.2582 29.9638 15.3918 29.6795 15.3918 29.6116C15.3918 29.3764 15.2008 29.1843 14.964 29.1843C14.8094 29.1843 14.6769 29.2649 14.6019 29.3853L14.5969 29.3831C14.5627 29.4123 14.0145 30.3884 13.8339 30.5689C13.6424 30.7621 13.4712 30.7406 13.4712 30.7406H12.0486C12.0486 30.7406 11.8758 30.7621 11.6853 30.5689C11.5021 30.3884 10.9566 29.4123 10.9213 29.3831L10.918 29.3853Z"
        fill="currentColor"
      />
      <path
        d="M12.7591 30.0013C13.4089 30.0013 13.9328 29.4758 13.9328 28.826C13.9328 28.1768 13.4089 27.6523 12.7591 27.6523C12.1099 27.6523 11.5854 28.1768 11.5854 28.826C11.5854 29.4758 12.1099 30.0013 12.7591 30.0013Z"
        fill="currentColor"
      />
      <path
        d="M12.761 31.6489L14.0881 32.6167L14.7186 34.9392H10.8018L11.4339 32.6167L12.761 31.6489Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NoPoverty;
